import { api } from "api";
import { handleActions } from "redux-actions";
import { createReducer } from "../baseReducer/baseReducer";
import { initialize as initializeForm } from "redux-form";
import { NotificationManager } from "react-notifications";
const baseReducer = createReducer("users_open", "user_open", "userFormOpen");

// ------------------------------------
// Constants
// ------------------------------------
// ------------------------------------
// Pure Actions
// ------------------------------------

const custom_leer = (id) => (dispatch) => {
    dispatch(baseReducer.actions.setLoader(true));

    api.get(`user_open/${id}`)
        .then((response) => {
            dispatch(baseReducer.actions.setItem(response));
            console.log(response);
            if (!!"userFormOpen")
                dispatch(initializeForm("userFormOpen", response));
        })
        .catch((err) => {
            console.log(err);
            err && err.body && err.body.detail == "Not found."
                ? NotificationManager.error("Usuario no registrado", "ERROR")
                : NotificationManager.error(
                      "Error al leer el usuario",
                      "ERROR"
                  );
        })
        .finally(() => {
            dispatch(baseReducer.actions.setLoader(false));
        });
};

export const actions = {
    custom_leer,
    ...baseReducer.actions,
};

export const reducers = {
    ...baseReducer.reducers,
};

export const initialState = {
    ...baseReducer.initialState,
};

export default handleActions(reducers, initialState);
