import React from 'react';

const Unavailable = () => (
    <div className="py-4">
        <div className="row">
            <div className="mb-4 col-lg-12">
                <div className="mb-4 card card-small">
                    <div className="p-2 p-md-5">
                        <h1> Seccion en Proceso ...</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default Unavailable;
