import React, { useEffect } from "react";
import Swal from "sweetalert2";
import { Field } from "redux-form";
import { renderField } from "../../Utils/renderField";
import { renderNumber, SelectField } from "../../Utils/renderField/renderField";
import { GENDER } from "../../../../utility/constants";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { requiredField } from "../../../../utility/validation";

const renderContactPerson = ({
    fields,
    meta: { touched, error, submitFailed },
    vehicles,
    isView,
    temporal_vehicle,
    setTemporalVehicle,
    selected_vehicles,
    setSelectedVehicles,
}) => {
    useEffect(() => {
        fields.push({});
    }, []);

    return (
        <React.Fragment>
            <div className="col-12 col-md-6 form-group d-flex align-items-end">
                <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => fields.push({})}
                >
                    Agregar Persona de Contacto
                </button>
            </div>
            <div
                className="col-12 form-group"
                style={{ minHeight: `${fields.length * 50 + 150}px` }}
            >
                {fields.length > 0 ? (
                    <Table className="table">
                        <Thead>
                            <Tr className="table-primary">
                                <Th scope="col">#</Th>
                                <Th scope="col">
                                    Nombre <span className="text-danger">*</span>
                                </Th>
                                <Th scope="col" style={{width: '200px'}}>
                                    Teléfono <span className="text-danger">*</span>
                                </Th>
                                <Th scope="col">Acciones</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {fields.map((member, index) => (
                                <Tr key={index}>
                                    <Th scope="row">{index + 1}</Th>
                                    <Td>
                                        <Field
                                            name={`${member}.name`}
                                            placeholder="Nombre"
                                            type="text"
                                            disabled={isView}
                                            component={renderField}
                                            label={`${member}.name`}
                                            validate={requiredField}
                                        />
                                    </Td>
                                    <Td>
                                        <Field
                                            name={`${member}.phone_number`}
                                            numberFormat={"+(502) ####-####"}
                                            placeholder="Teléfono"
                                            type="text"
                                            disabled={isView}
                                            component={renderNumber}
                                            label={`${member}.age`}
                                            validate={requiredField}
                                        />
                                    </Td>
                                    <Td>
                                        <button
                                            type="button"
                                            title="Eliminar"
                                            className="btn btn-danger"
                                            onClick={() => fields.remove(index)}
                                        >
                                            <span
                                                style={{ fontSize: "20px" }}
                                                className="material-icons"
                                            >
                                                close
                                            </span>
                                        </button>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                ) : (
                    <p>Presione el botón 'Agregar Persona de Contacto'.</p>
                )}
            </div>
        </React.Fragment>
    );
};

export default renderContactPerson;
