import React, { useEffect, useState } from "react";
import user_img from "../../../../../assets/img/user.png";
import { Field, reduxForm } from "redux-form";
import {
    renderField,
    renderFieldCheck,
    renderNumber,
    SelectField,
    renderFilePicker,
} from "../../Utils/renderField/renderField";
import {
    licenceType,
    USER_RANGES,
    FIREFIGHTER_TYPE,
    USER_STATUS,
    INACTIVE_STATUS,
} from "../../../../utility/constants";
import LoadMask from "../../Utils/LoadMask/LoadMask";

const Form = (props) => {
    const { handleSubmit, setAvatar, item, loading, valueIsPilot, showInactive, setShowInactive } = props;

    const status_firefighter =
        item && item.status && item.status === "Activo"
            ? { label: "Activo", value: 5 }
            : item.status === "Inactivo"
            ? { label: "Inactivo", value: 1 }
            : {};

    const [status, setStatus] = useState(status_firefighter);
    useEffect(() => {
        setShowInactive(
            item && item.status && item.status === "Activo"
                ? false
                : item.status === "Inactivo"
                ? true
                : false
        );
    }, []);

    const validateChange = (e) => {
        const value =
            e && e === 5
                ? { label: "Activo", value: 5 }
                : e === 1
                ? { label: "Inactivo", value: 1 }
                : {};

        e && e === 5
            ? setShowInactive(false)
            : e === 1
            ? setShowInactive(true)
            : setShowInactive(false);
        setStatus(value);
    };

    return (
        <form onSubmit={handleSubmit}>
            <LoadMask loading={loading} dark blur>
                <h4 className="pl-3">Datos Generales</h4>
                <hr className="line-detail m-3" />
                <div className="d-flex flex-wrap">
                    <div className="d-flex flex-column flex-1 mx-3">
                        <div className="form-group has-feedback">
                            <label> No. de DPI </label>
                            <Field
                                name="no_dpi"
                                disabled={true}
                                component={renderNumber}
                                className="m-2"
                                numberFormat={"#### ##### ####"}
                            />
                        </div>
                        <div className="form-group has-feedback">
                            <label> Nombres </label>
                            <Field
                                name="first_name"
                                component={renderField}
                                disabled={true}
                                className="m-2"
                                type="text"
                            />
                        </div>
                        <div className="form-group has-feedback">
                            <label> Apellidos </label>
                            <Field
                                name="last_name"
                                component={renderField}
                                disabled={true}
                                className="m-2"
                                type="text"
                            />
                        </div>

                        <div className="form-group has-feedback">
                            <label>Correo Electronico</label>
                            <Field
                                name="email"
                                component={renderField}
                                disabled={true}
                                className="m-2"
                                type="email"
                            />
                        </div>

                        <div className="form-group has-feedback">
                            <label>Fecha de Nacimiento</label>
                            <Field
                                name="date_of_birth"
                                label="date_of_birth"
                                component={renderField}
                                disabled={true}
                                type={"date"}
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-6 form-group">
                        <label>Fotografia</label>
                        <Field
                            photo={item.photo ? item.photo : user_img}
                            name="photo"
                            label={item.photo ? item.username : "Sin imagen"}
                            disabled={true}
                            setFile={setAvatar}
                            component={renderFilePicker}
                        />
                    </div>
                </div>

                <h4 className="pl-3">Información de contacto</h4>
                <hr className="line-detail m-3" />
                <div className="d-flex flex-wrap">
                    <div className="col-12 col-md-6 form-group">
                        <label>Número de Teléfono</label>
                        <Field
                            name="phone_number"
                            disabled={true}
                            component={renderNumber}
                            numberFormat={"+(502) ####-####"}
                            className="m-2"
                        />
                    </div>
                    <div className="col-12 col-md-6 form-group">
                        <label>Dirección</label>
                        <Field
                            name="address"
                            disabled={true}
                            component={renderField}
                            className="m-2"
                            type="text"
                        />
                    </div>
                    <div className="col-12 col-md-6 form-group">
                        <label> No. de Registro </label>
                        <Field
                            type="number"
                            name="no_registry"
                            disabled={true}
                            component={renderField}
                            className="m-2"
                        />
                    </div>
                    <div className="col-12 col-md-6 form-group">
                        <label>Estación</label>
                        <Field
                            name="station_text"
                            disabled={true}
                            component={renderField}
                            className="m-2"
                            type="text"
                        />
                    </div>
                </div>

                <h4 className="pl-3">Información de la cuenta</h4>
                <hr className="line-detail m-3" />
                <div className="d-flex flex-wrap">
                    <div className="col-12 col-md-6 form-group">
                        <label> Rango </label>
                        <Field
                            className="m-2"
                            name="user_range"
                            disabled={true}
                            options={USER_RANGES}
                            component={SelectField}
                        />
                    </div>
                    <div className="col-12 col-md-6 form-group">
                        <label> Tipo Bombero </label>
                        <Field
                            className="m-2"
                            name="firefighter_type"
                            disabled={true}
                            options={FIREFIGHTER_TYPE}
                            component={SelectField}
                        />
                    </div>
                    <div className="col-12 col-md-6 form-group mt-4">
                        <Field
                            name="is_pilot"
                            disabled={true}
                            component={renderFieldCheck}
                            label="Es piloto"
                            className="m-2"
                        />
                    </div>
                    {valueIsPilot === true && (
                        <React.Fragment>
                            <div className="col-12 col-md-6 form-group">
                                <label> No. de licencia </label>
                                <Field
                                    name="no_license"
                                    disabled={true}
                                    component={renderField}
                                    className="m-2"
                                    type="number"
                                />
                            </div>
                            <div className="col-12 col-md-6 form-group">
                                <label> Tipo de licencia </label>
                                <Field
                                    name="type_license"
                                    disabled={true}
                                    component={SelectField}
                                    options={licenceType}
                                    className="m-2"
                                />
                            </div>
                        </React.Fragment>
                    )}
                    <div className="col-12 col-md-6 form-group">
                        <label>Puesto</label>
                        <Field
                            name="job_title"
                            disabled={true}
                            component={renderField}
                            className="m-2"
                            type="text"
                        />
                    </div>
                    <div className="col-12 col-md-6 form-group">
                        <label> Estado del Bombero </label>
                        <Field
                            className="m-2"
                            name="status"
                            disabled={true}
                            options={USER_STATUS}
                            component={SelectField}
                            valueSelect={status}
                            parse={(e) => validateChange(e)}
                        />
                    </div>
                    {showInactive && (
                        <div className="col-12 col-md-6 form-group">
                            <label> Razón Inactivo </label>
                            <Field
                                className="m-2"
                                name="inactive_reason"
                                disabled={true}
                                options={INACTIVE_STATUS}
                                component={SelectField}
                            />
                        </div>
                    )}
                </div>
            </LoadMask>
        </form>
    );
};

export default reduxForm({
    form: "userFormOpen",
})(Form);
