import { SOCKET_URL } from "./settingsws";

class WebSocketService{
    /**
     * Class Singleton WebSocketService
     * contendra las funcionalidades para poder intercambiar informacion
     * con el Consumer del backend. 
     */

    static instance = null  // Instancia unica para poder manejar la comunicacion
    callbacks = {};         // Llamadas como parametros

    static getInstance() {
        /**
         * Funcion para poder retornar la unica instancia
         * en caso de que no exista poder crear la instancia unica
         */
        if (!WebSocketService.instance)
          WebSocketService.instance = new WebSocketService();
        return WebSocketService.instance;
    }

    constructor(){
      this.socketRef = null; // Almacenamos la referencia a la instancia del websocket
    }

    connect(channelurl){
        // Url del websocket proporcionando por el backend
        const path = `${SOCKET_URL}/ws/tracking/${channelurl}/`; 
        // Al iniciar una conexion creamos una nueva instancia a ese websocket
        this.socketRef = new WebSocket(path);
        /**
         * Funciones para customizar las funciones del web socket
         */
        // INICIAR CONEXION
        this.socketRef.onopen = () => {
            console.log(`WebSocket ${channelurl} open`);
        };
        
        // CREAR UN NUEVO MENSAJE
        this.socketRef.onmessage = e => {
            this.socketNewMessage(e.data);
        };

        // CONTROL DEL ERROR
        this.socketRef.onerror = e => {
            console.log(e);
        };

        // RESTABLECER CONEXION
        // this.socketRef.onclose = () => {
        //     console.log(`WebSocket ${channelurl} closed let's reopen`);
        //     this.connect(channelurl);
        // };
    }

    disconnect(){
      // Finalizamos la conexion
      console.log("Process close")
      this.socketRef.close();
    }

    socketNewMessage(data) {
      /**
       * Funcion para poder establecer un nuevo mensaje de comunicacion
       * en la cual atraves del parametro especificado en command se realizara 
       * determinada accion.
       */
        const parsedData = JSON.parse(data);    // Data 
        const command = parsedData.command;     // Accion a ejecutar

        // No data
        if (Object.keys(this.callbacks).length === 0) return;

        // Get to ubications
        if (command === "ubications") this.callbacks[command](parsedData.messages);
        
        // Post to new ubication
        if (command === "new_ubication") this.callbacks[command](parsedData.message);  
    }

    fetchMessages(username, vehicleId) {
      /**
       * Funcion para solicitar informacion al emisor
       */
      this.sendMessage({
        command: "fetch_messages",
        username: username,
        vehicleId: vehicleId
      });
    }

    newUbication(new_ubication) {
      /**
       * Funcion para poder actualizar el estado del vehiculo actual
       * sobre el cual vamos a trackear la posicion actual.
       */
      this.sendMessage({
        command: "new_ubication",
        from: new_ubication.from,
        message: new_ubication.content,
        vehicleId: new_ubication.vehicleId
      });
    }

    addCallbacks(ubicationsCallback, newUbicationCallback) {
        /**
         * Funcion para poder conectar el scope del componente donde
         * se reflejara la comunicacion
         * 
         */
        this.callbacks["ubications"] = ubicationsCallback;
        this.callbacks["new_ubication"] = newUbicationCallback;
    }

    sendMessage(data) {
        /**
         * Funcion para responder con data 
         */
        try {
          this.socketRef.send(JSON.stringify({ ...data }));
        } catch (err) {
          console.log(err);
        }
    }

    state() {
      /**
       * Funcion que verifica el estado de la conexion 
       * del websocket
       */
        return this.socketRef.readyState;
    }
}

// Instancia unica de la clase del websocket
const WebSocketInstance = WebSocketService.getInstance();
export default WebSocketInstance;