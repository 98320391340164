import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/reports/index';
import DashboardFree from './DashboardFree';


const mstp = state => {
    
    return {
        ...state.reports,
        me: state.login.me,
    };
}

const mdtp = {
    ...actions
}

export default connect(mstp, mdtp)(DashboardFree);