import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import login from './modules/cuenta/login';
import register from './modules/cuenta/register';
import profile from './modules/cuenta/profile';
import users from './modules/users';
import usuarios from './modules/usuarios/usuarios';
import notificaciones from './modules/notificaciones/notificaciones';
import vehicleTypes from './modules/vehicleTypes/vehicleTypes';
import station from './modules/station/station';
import vehicles from './modules/vehicles/vehicles';
import emergencies from './modules/emergencies/emergencies';
import emergencyTypes from './modules/emergencyTypes/emergencyTypes';
import categoryEmergencyTypes from './modules/emergencyTypes/category_emergency_type';
import tracking from './modules/tracking';
import reports from './modules/reports';
import emergency_report from './modules/reports/emergency_report';
import station_report from './modules/reports/station_report';
import report_human_resources from './modules/reports/human_resources';
import binnacle from './modules/binnacle/binnacle';
import jobManagment from './modules/humanResources/job_managment'
import vehiclesEmergency from'./modules/vehiclesEmergency/vehiclesEmergency';
import user_open from './modules/users/user_open';
import unsubscribe_discharge from './modules/humanResources/unsubscribe_discharge';
import qr_users from './modules/qr';
import control_hours from './modules/humanResources/control_hours';
import emergency_report_detail from './modules/reports/emergency_report_detail';

export default combineReducers({
    form: formReducer,
    login,
    register,
    profile,
    station,
    users,
    usuarios,
    tracking,
    routing,
    notificaciones,
    vehicleTypes,
    vehicles,
    vehiclesEmergency,
    reports,
    emergencies,
    binnacle,
    emergencyTypes,
    categoryEmergencyTypes,
    user_open,
    jobManagment,
    emergency_report,
    station_report,
    unsubscribe_discharge,
    qr_users,
    control_hours,
    report_human_resources,
    emergency_report_detail,
});
