import { api } from "api";
import { vehicletracking } from "../../../utility/vehicletracking";
import { change } from "redux-form";
import { handleActions } from "redux-actions";
import { createReducer } from "../baseReducer/baseReducer";
import { NotificationManager } from "react-notifications";

// ------------------------------------
// Constants
// ------------------------------------

const baseReducer = createReducer(
    "vehicles_onEmergency"
);

const SET_FILTER_STATION = "SET_FILTER_STATION";
const SET_FILTER_VEHICLE_TYPE = "SET_FILTER_VEHICLE_TYPE";
const SET_FILTER_DISPONIBILITY = "SET_FILTER_DISPONIBILITY";
const SET_CUSTOM_LOADER = "SET_CUSTOM_LOADER";
const SET_CUSTOM_PAGE = "SET_CUSTOM_PAGE";
const SET_CUSTOM_SEARCH = "SET_CUSTOM_SEARCH";
const SET_VEHICLES_STATIONS = "SET_VEHICLES_STATIONS";
const SET_VEHICLE_TRACKING = "SET_VEHICLE_TRACKING";


const setVehiclesStations = (vehicles_stations) => ({
    type: SET_VEHICLES_STATIONS,
    vehicles_stations,
});

const setCustomLoader = (loader) => ({
    type: SET_CUSTOM_LOADER,
    loader,
});

const setCustomPage = (custom_page) => ({
    type: SET_CUSTOM_PAGE,
    custom_page,
});

const setCustomSearch = (custom_search) => ({
    type: SET_CUSTOM_SEARCH,
    custom_search,
});

const setFilterStation = (filter_station) => ({
    type: SET_FILTER_STATION,
    filter_station,
});

const setFilterVehicleType = (filter_vehicle_type) => ({
    type: SET_FILTER_VEHICLE_TYPE,
    filter_vehicle_type,
});

const setFilterDisponibility = (filter_disponibility) => ({
    type: SET_FILTER_DISPONIBILITY,
    filter_disponibility,
});

const setVehicleTracking = (vehicle_tracking) => ({
    type: SET_VEHICLE_TRACKING,
    vehicle_tracking,
});



const listarVehiculosOnEmergency =
    (page = 1) =>
    (dispatch, getStore) => {
        const resource = getStore()["login"]["me"];
        const params = { page };

        // Filtrar por el numero de estacion del usuario
        params.station = resource.station ? resource.station.id : '';

        if (!resource.station && resource.role === 5){
            return NotificationManager.error('No se pudo obtener los vehículos para trackear', 'Error', 3000);
        }
        dispatch(setCustomLoader(true));
        api.get("vehicle/vehicle_list", params)
            .then((response) => {
                dispatch(baseReducer.actions.setData(response));
                dispatch(setCustomPage(page));
            })
            .catch(() => {})
            .finally(() => {
                dispatch(setCustomLoader(false));
            });
    };


const listarVehiculosEstacion = (stations) => (dispatch, getStore) => {
    const params = { stations };
    dispatch(baseReducer.actions.setLoader(true));
    api.get("vehicle/get_vehicles", params)
        .then((response) => {
            dispatch(setVehiclesStations(response));
        })
        .catch(() => {})
        .finally(() => {
            dispatch(baseReducer.actions.setLoader(false));
        });
};

const changeStatusVehicle = (id, newStatus, localChange) => (dispatch) => {
    api.post("vehicle/change_status", {id, status:newStatus})
        .then((response) => {
            console.log(response)
            localChange && localChange()
            NotificationManager.success('Registro creado', 'Éxito', 3000);
        })
        .catch((erro) => {
            console.log(erro)
            NotificationManager.error('No se pudo actualizar el estado', 'Error', 3000);
        })
        .finally(() => {
            console.log('Final')
        });
};


const changeStatusTracking = (id,vehicle,newStatus) => (dispatch) =>{
    api.post("vehicle/status_tracking", {id, status:newStatus})
        .then((response) => {
            
            if(newStatus){
                vehicletracking.setVehicleOnTracking(vehicle)
                NotificationManager.success('Proceso de trackeo iniciado.', 'Éxito', 3000);
            }
            else{
                vehicletracking.removeVehicleOnTracking() 
                NotificationManager.success('Proceso de trackeo detenido.', 'Éxito', 3000);
            }
                
        })
        .catch((erro) => {
            console.log(erro.error)
            NotificationManager.error(erro.error ? erro.error : `Error al iniciar el trackear del vehiculo ${vehicle.plate}`, 'Error', 3000);
            if (erro.error && erro.error == 'Ya se ha cambiado el estado del vehiculo'){
                vehicletracking.removeVehicleOnTracking() 
            }
        })
        .finally(() => {
            console.log('Final') 
            dispatch(listarVehiculosOnEmergency()); 
    });
}

const changeStatusTrackingStop = (id, vehicle, newStatus) => (dispatch) => {
    api.post("vehicle/status_tracking", { id, status: newStatus })
        .then((response) => {
            vehicletracking.removeVehicleOnTracking();
        })
        .catch((erro) => {
            console.log(erro);
            NotificationManager.error(
                `Error al trackear el vehiculo ${vehicle.plate}`,
                "Error",
                3000
            );
        })
        .finally(() => {
            console.log("Final");
            dispatch(listarVehiculosOnEmergency());
        });
};

const status_vehicle = (id) => (dispatch) => {
    api.get("vehicle/status_vehicle", {id})
    .then((response) => {
        if (!response.on_tracking){
            vehicletracking.removeVehicleOnTracking();  
            NotificationManager.warning('Se detuvo el trackeo del vehiculo actual porque el administrador cambio el estado del trackeo', 'Advertencia', 15000);
        }
    })
    .catch((erro) => {
        console.log(erro);
        NotificationManager.error(
            `${erro.statusText ? erro.statusText : 'Error'}`,
            "Error",
            3000
        );
    })
    .finally(() => {
        dispatch(listarVehiculosOnEmergency());
    });
};

const limpiarVehiculosEstacion = () => (dispatch, getStore) => {
    dispatch(setVehiclesStations([]));
}

const searchCustomChange = (custom_search) => (dispatch) => {
    dispatch(setCustomSearch(custom_search));
    dispatch(listarPersonalizado());
};

const filterStation = (filter_station) => (dispatch) => {
    dispatch(setFilterStation(filter_station ? filter_station : ""));
    dispatch(listarPersonalizado());
};

const filterVehicleType = (filter_vehicle_type) => (dispatch) => {
    dispatch(
        setFilterVehicleType(filter_vehicle_type ? filter_vehicle_type : "")
    );
    dispatch(listarPersonalizado());
};

const filterDisponibility = (filter_disponibility) => (dispatch) => {
    dispatch(
        setFilterDisponibility(filter_disponibility ? filter_disponibility : "")
    );
    dispatch(listarPersonalizado());
};

const vehicleTracking = (vehicle_tracking) => (dispatch,getStore) => {
    const resource = getStore()["vehiclesEmergency"]["data"]["results"].find(v=>v.id==vehicle_tracking);
    /*dispatch(
        setVehicleTracking(resource ? resource : undefined)
    );*/
    return resource ? resource : undefined
};




const changeValueSelectVehicles = () => (dispatch, getStore) => {
    const resource = getStore()["vehicles"];
    dispatch(
        change("ListVehicles", "disponibility", resource.filter_disponibility)
    );
    dispatch(
        change("ListVehicles", "vehicle_type", resource.filter_vehicle_type)
    );
    dispatch(change("ListVehicles", "station", resource.filter_station));
};

export const actions = {
    changeStatusTracking,
    changeStatusTrackingStop,
    status_vehicle,
    vehicleTracking,
    listarVehiculosOnEmergency,
    searchCustomChange,
    filterStation,
    filterVehicleType,
    changeStatusVehicle,
    filterDisponibility,
    changeValueSelectVehicles,
    listarVehiculosEstacion,
    limpiarVehiculosEstacion,
    ...baseReducer.actions,
};

export const reducers = {
    ...baseReducer.reducers,
    [SET_VEHICLE_TRACKING]: (state, { vehicle_tracking }) => {
        return {
            ...state,
            vehicle_tracking
        }
    },
    [SET_VEHICLES_STATIONS]: (state, { vehicles_stations }) => {
        return {
            ...state,
            vehicles_stations
        }
    },
    [SET_CUSTOM_LOADER]: (state, { custom_loader }) => {
        return {
            ...state,
            custom_loader,
        };
    },
    [SET_CUSTOM_PAGE]: (state, { custom_page }) => {
        return {
            ...state,
            custom_page,
        };
    },
    [SET_CUSTOM_SEARCH]: (state, { custom_search }) => {
        return {
            ...state,
            custom_search,
        };
    },
    [SET_FILTER_STATION]: (state, { filter_station }) => {
        return {
            ...state,
            filter_station,
        };
    },
    [SET_FILTER_VEHICLE_TYPE]: (state, { filter_vehicle_type }) => {
        return {
            ...state,
            filter_vehicle_type,
        };
    },
    [SET_FILTER_DISPONIBILITY]: (state, { filter_disponibility }) => {
        return {
            ...state,
            filter_disponibility,
        };
    },
};

export const initialState = {
    custom_loader: false,
    custom_page: 1,
    custom_search: "",
    filter_station: "",
    filter_vehicle_type: "",
    filter_disponibility: "",
    vehicles_stations: [],
    vehicle_tracking:undefined,
    ...baseReducer.initialState,
};

export default handleActions(reducers, initialState);
