import moment from "moment";
import React, { useEffect } from "react";
// import Filters from "../Utils/Filters/Filters";
import { Field, reduxForm } from "redux-form";
import Grid from "../../Utils/Grid";
// import { standardActions } from "../Utils/Grid/StandardActions";
// import Title from "../Utils/Headers/Title";
import Title from "../../Utils/Headers/Title";
import { ROLE_ADMIN, HUMAN_RESOURCES } from "../../../../utility/constants";
import { standardActions } from "../../Utils/Grid/StandardActions";

const JobManagment = ({
    me,
    listar,
    data,
    loader,
    eliminar,
}) => {
    useEffect(() => {
        listar();
    }, []);

    const columns = [
        {
            dataField: "job_title",
            text: "Puesto",
        },
        {
            dataField: "id",
            text: "Acciones",
            formatter: (cell, row) =>
                standardActions({
                    ver: "/human_resources/job_management",
                    editar: "/human_resources/job_management",
                    eliminar,
                })(cell, row),
        },
    ];

    return (
        <div className="py-4">
            <div className="row">
                <div className="mb-4 col-lg-12">
                    <div className="mb-4 card card-small">
                        <div className="px-3 pt-4">
                            <Title
                                showCreate={
                                    me.role === ROLE_ADMIN ||
                                    me.role === HUMAN_RESOURCES
                                        ? true
                                        : false
                                }
                                linkToRedirect={
                                    "/#/human_resources/job_management/create"
                                }
                                titleCreate={"+ Crear Puesto"}
                                title={"Gestión de puestos"}
                                subtitle={"Listado general de puestos"}
                            />
                        </div>
                        <div className="p-0 px-3 pt-3">
                            <Grid
                                data={data}
                                loading={loader}
                                columns={columns}
                                onPageChange={listar}
                                noDataMessage={"Sin Puesto Registrado"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JobManagment;

// export default reduxForm({
//     form: "JobManagment", // a unique identifier for this form
// })(Binnacle);
