import { actions } from '../../../redux/modules/binnacle/binnacle';
import { connect } from 'react-redux';
import Binnacle from './Binnacle'

const mstp = state => ({
    ...state.binnacle
})

const mdtp = {
    ...actions
}

export default connect(mstp, mdtp)(Binnacle);