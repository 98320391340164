import React, { Component } from "react";
import Grid from "../../Utils/Grid";
import { reduxForm } from "redux-form";
import OrderSearchComponent from "../../Utils/OrderSearch/orderSearch";
import Title from "../../Utils/Headers/Title";
import { standardActions } from "../../Utils/Grid/StandardActions";
import {ROLE_ADMIN} from "../../../../utility/constants";

class List extends Component {
    componentDidMount() {
        this.props.listar();
    }

    render() {
        const { listar, data, loader, onSortChange, searchChange, eliminar } = this.props;

        const columns = [
            {
                dataField: "name",
                text: "Nombre",
            },
            {
                dataField: "nomenclature",
                text: "Nomenclatura",
            },
            {
                dataField: 'id',
                text: 'Acciones',
                formatter: (cell, row) => !!(this.props.me && this.props.me.role === ROLE_ADMIN)
                    ?standardActions({ ver: '/vehicle-type', editar: '/vehicle-type', eliminar })(cell, row)
                    :standardActions({ ver: '/vehicle-type' })(cell, row)
            }
        ];

        return (
            <div className="py-4">
                <div className="row">
                    <div className="mb-4 col-lg-12">
                        <Title
                            showCreate={!!(this.props.me && this.props.me.role === ROLE_ADMIN)}
                            title={"Tipos de vehículo"}
                            linkToRedirect={"#/vehicle-types/create"}
                            titleCreate={"Crear tipo de vehículo"}
                            subtitle={"Listado general de tipos de vehículo"}
                        />
                        <div className="mb-4 card card-small">
                            <OrderSearchComponent searchChange={searchChange} customClass='row mx-auto w-100 mt-2 justify-content-end'/>
                            <div className="p-0 px-3 pt-3">
                                <Grid
                                    data={data}
                                    loading={loader}
                                    columns={columns}
                                    onPageChange={listar}
                                    onSortChange={onSortChange}
                                    noDataMessage={"Sin tipos de vehículo"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default reduxForm({
    form: "ListVehicleTypes", // a unique identifier for this form
})(List);
