import React, {useEffect, useState} from 'react';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";


const CustomDropdown = (props) => {
    const {title, options, direction = 'down', color, rowId, disabled=false} = props;
    const [isOpen, setIsOpen] = useState(false);
    const [titleLocal, setTitleLocal] = useState(title);
    const [colorLocal, setColorLocal] = useState(color);

    useEffect(() => {

    }, []);

    const changeLocalValue = (label, newColor) => {
        setTitleLocal(label);
        setColorLocal(newColor);
    }
    return (<div>
        <Dropdown
            isOpen={isOpen}
            toggle={() => {
                setIsOpen(!isOpen)
            }}
            direction={direction}
        >
            <DropdownToggle
                color={"transparent"}
                className='d-flex text-center'
                disabled={disabled}
            >
                <div className="d-flex align-content-center align-items-center">
                    <span className={"material-icons " + colorLocal}>fiber_manual_record</span>
                    <span className="text-center" style={{fontSize: '14px'}}>{" "}{titleLocal}{" "}</span>
                    <span className="material-icons" style={{color: 'black'}}>arrow_drop_down</span>
                </div>
            </DropdownToggle>
            <DropdownMenu className='transform-normal-none'>
                {(options && options.length > 0)
                    ? options.map((op) =>
                        <DropdownItem
                            key={rowId+op.label}
                            onClick={() => {
                                op.action && op.action(rowId, op.value, ()=>changeLocalValue(op.label, op.color))
                            }}
                            className='item-status'>
                            {op.label}
                        </DropdownItem>
                    ) : null
                }
            </DropdownMenu>
        </Dropdown>

    </div>)
}

export default CustomDropdown