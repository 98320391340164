import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/emergencyTypes/category_emergency_type';
import List from './List';


const ms2p = (state) => {
    return {
        ...state.categoryEmergencyTypes,
        me: state.login.me,
    }
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(List);
