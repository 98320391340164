import React, { useEffect, useState } from "react";
import "../emergency.css";
import ContainerDetail from "../ContainerDetail/ContainerDetail";

const EmergenceDetail = (props) => {
    useEffect(() => {
        props.customRetrieveOpen(props.match.params.id);
    }, []);

    const { item } = props;

    const columns = [
        {
            dataField: "name",
            sort: true,
            text: "Nombre",
        },
        {
            dataField: "age",
            sort: true,
            text: "Edad",
        },
        {
            dataField: "address",
            sort: true,
            text: "Dirección",
        },
        {
            dataField: "genre",
            sort: true,
            text: "Genero",
            formatter: (cell, row) => {
                const genre_text =
                    row.genre === 1
                        ? "Masculino"
                        : row.genre === 10
                        ? "Femenino"
                        : "Sin Género";
                return <div>{genre_text}</div>;
            },
        },
        {
            dataField: "moved_to",
            sort: true,
            text: "Se trasladó a",
        },
    ];
    return (
        <React.Fragment>
            <ContainerDetail props={props} item={item} columns={columns} open={true}/>
        </React.Fragment>
    );
};

export default EmergenceDetail;
