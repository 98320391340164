import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { NotificationManager } from "react-notifications";
import { api } from "api";
import Swal from 'sweetalert2';
import { Router } from 'react-router';

const SUBMIT = 'LOGIN_SUBMIT';
const LOADER = 'LOGIN_LOADER';
const ME = 'LOGIN_ME';
const EMAIL_SENT = "EMAIL_SENT";

export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

export const setMe = me => ({
    type: ME,
    me,
});


const handleError = (error, defaultMsg = "Error") => {
    console.error(error);
    Swal.fire({
        icon: 'error',
        title: "ERROR",
        text: error ? error instanceof(Object) ? error.detail : error : ''
    })
}

// ------------------------------------
// Actions
// ------------------------------------

export const onSubmit = (data = {}) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.post('user/token', data).then((response) => {
        localStorage.setItem('token', response.token);
        dispatch(initializeForm('profile', response.user));
        dispatch(setMe(response.user));
        dispatch(push("/"));
    }).catch((e) => {
        NotificationManager.error(e.detail, 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const getMe = () => (dispatch) => {
    api.get('/user/me').then(me => {
        dispatch(initializeForm('profile', me));
        dispatch(setMe(me));
    })
        .catch((e) => {
            localStorage.removeItem('token');
            dispatch(push('/user/logout'))
            NotificationManager.error(e.body.detail, 'ERROR', 0);
    }).finally(() => {});
};

export const logOut = () => (dispatch) => {
    api.post('/user/logout').then(() => {
    }).catch(() => {
    }).finally(() => {});
    localStorage.removeItem('token');
};

export const onSubmitForgot = (data, again = false) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.post('/user/reset_password', data).then(response=>{
        dispatch({ type: EMAIL_SENT, emailSent: true });
        if(again){
            Swal.fire({
                icon: "success",
                text: "Se volvio a enviar el correo"
            })
        }else{
            Swal.fire({
                icon: "success",
                text: "Se envio el correo"
            })
        }
    }).catch(error=>{
        handleError(error, 'No se pudo enviar el correo');
    }).finally(()=>{
        dispatch(setLoader(false))
    })
}

export const onSubmitRecover = (data) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.post('/user/recover_password', data).then(response=> {
        Swal.fire({
            icon: 'success',
            text: "La contraseña fue cambiada"
        }).then(()=>{
            dispatch(push('/login'));
        })
    }).catch((error)=>{
        handleError(error, 'No se pudo cambiar la contraseña');
    }).finally(()=>{
        dispatch(setLoader(false));
    })
}


export const actions = {
    onSubmitForgot,
    onSubmitRecover,
    onSubmit,
    logOut,
    getMe,
};

export const reducers = {
    [LOADER]: (state, { loader }) => ({ ...state, loader }),
    [ME]: (state, { me }) => ({ ...state, me }),
    [EMAIL_SENT]: (state, { emailSent }) => ({ ...state, emailSent }),
};

export const initialState = {
    loader: false,
    emailSent: false,
    me: {},
};

export default handleActions(reducers, initialState);
