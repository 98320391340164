import React from 'react';
import Swal from 'sweetalert2';


const propTypes = {};

const defaultProps = {};

const Actions = (props) => {

    const selectedTracking = (id) =>{
        return () => {
            Swal.fire({
                title: '¿Trackear ubicación?',
                text: '¡No podrá revertir esta acción!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, trackear ubicación!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true,
                confirmButtonColor: '#0F45C7'
            }).then((result) => {
                if (result.value) {
                    const vehicle = props.getVehicle(id)
                    if(vehicle){
                        props.changeTracking(id,vehicle,true);
                    }
                }
                props.closeModal();
            });
        }
    }

    // const clearTracking = (id)=>{
    //     return () => {
    //         Swal.fire({
    //             title: '¿Detener Tracking?',
    //             text: '¡No podrá revertir esta acción!',
    //             icon: 'warning',
    //             showCancelButton: true,
    //             confirmButtonText: '¡Sí, detener trackeo!',
    //             cancelButtonText: 'No, cancelar',
    //             reverseButtons: true,
    //             confirmButtonColor: '#0F45C7'
    //         }).then((result) => {
    //             if (result.value) {
    //                 console.log("Delete")
    //             }
    //         });
    //     }
    // }

    const { id } = props;
    
    return (
    <div className="d-flex justify-content-end">
        <a 
            className="btn btn-primary px-1 py-0 m-1" 
            style={{cursor: "pointer", color: "#0F45C7"}} 
            onClick={selectedTracking(id)}>
            <i className="material-icons text-white">check</i>
        </a>
        {/* <a 
            className="btn btn-warning px-1 py-0 m-1" 
            style={{cursor: "pointer", color: "#0F45C7"}} 
            onClick={clearTracking(id)}>
            <i className="material-icons text-white">close</i>
        </a> */}
    </div>
    );
}

Actions.propTypes = propTypes;

Actions.defaultProps = defaultProps;

export function VehiclesEmergencyActions(acciones) {
    return (cell, row) => {
        return ( <Actions id={cell} {...acciones}/> )
    };
}
