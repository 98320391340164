import { handleActions } from "redux-actions";
import { createReducer } from "../baseReducer/baseReducer";
import { api } from "api";
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
import { initialize as initializeForm, change } from 'redux-form';
import moment from "moment";
import Swal from "sweetalert2";

// ------------------------------------
// Constants
// ------------------------------------
const STATUS_EMERGENCY_FILTER = 'STATUS_EMERGENCY_FILTER';
const FILTER_BY_STATION = 'FILTER_BY_STATION';
const FILTER_BY_TYPE_EMERGENCY = 'FILTER_BY_TYPE_EMERGENCY';
const FILTER_BY_VEHICLE = 'FILTER_BY_TYPE_VEHICLE';
const FILTER_BY_START_DATE = 'FILTER_BY_START_DATE';
const FILTER_BY_END_DATE = 'FILTER_BY_END_DATE';
const SET_OPTION_STATUS_REGION_EMERGENCY = 'SET_OPTION_STATUS_REGION_EMERGENCY';

const setStateFileter = statusEmergency => ({
    type: STATUS_EMERGENCY_FILTER,
    statusEmergency,
});

const setFilterByStation = station => ({
    type: FILTER_BY_STATION,
    station,
});

const setOptionsRegion = region => ({
    type: SET_OPTION_STATUS_REGION_EMERGENCY,
    region,
});

const setFilterByTypeEmergency = emergency_type_id => ({
    type: FILTER_BY_TYPE_EMERGENCY,
    emergency_type_id,
});

const setFilterByVehicle = vehicle => ({
    type: FILTER_BY_VEHICLE,
    vehicle,
});

const setFilterByStartDate = start_date => ({
    type: FILTER_BY_START_DATE,
    start_date,
});

const setFilterByEndDate = end_date => ({
    type: FILTER_BY_END_DATE,
    end_date,
});

export const baseReducer = createReducer(
    "emergencies",
    "emergency",
    "EmergencyForm",
    "/emergencies"
);

const createAttachment =
    (data = {}, attachments = []) =>
    (dispatch, getStore) => {
        dispatch(baseReducer.actions.setLoader(true));
        api.postAttachments(`emergency`, data, attachments)
            .then(() => {
                NotificationManager.success("Emergencia creada", "Exito", 1000);
                dispatch(push("/emergencies"));
            })
            .catch((err) => {
                err && err.detail ? NotificationManager.error(err.detail, "ERROR", 0) : typeof err === 'object' ? NotificationManager.error('Error en la creación de la emergencia.', "ERROR", 0) : NotificationManager.error(err, "ERROR", 0)
            })
            .finally(() => {
                dispatch(baseReducer.actions.setLoader(false));
            });
    };

const descargarExcel = () => (dispatch, getStore) => {
    const resource = getStore()['emergencies'];
    const token = `auth_token=${localStorage.getItem("token")}`
    const start_date = resource.start_date ? moment(resource.start_date).format('YYYY-MM-DD') : "";
    const end_date = resource.end_date ? moment(resource.end_date).format('YYYY-MM-DD') : "";
    const station_id = resource.station
        ? resource.station.map((station) => station.id)
        : "";
    const emergency_type_id = resource.emergency_type_id ? resource.emergency_type_id.id : "" ;
    const vehicle = resource.vehicle ? resource.vehicle.id : "" ;

    const params = `status=${resource.statusEmergency}&station=${station_id}&emergency_type=${emergency_type_id}&vehicle=${vehicle}&start_date=${start_date}&end_date=${end_date}&region_type=${resource.region}`

    const endpoint = `/api/emergency/descargar_excel?${token}&${params}`;

    window.open(endpoint, '_blank');
};

const updateAttachment =
    (data = {}, attachments = []) =>
    (dispatch, getStore) => {
        dispatch(baseReducer.actions.setLoader(true));
        api.postAttachments(`emergency/customUpdate`, data, attachments)
            .then(() => {
                NotificationManager.success("Emergencia actualizada", "Exito", 1000);
                dispatch(push("/emergencies"));
            })
            .catch((err) => {
                console.log(err);
                err && err.detail ? NotificationManager.error(err.detail, "ERROR", 0) : typeof err === 'object' ? NotificationManager.error('Error en la creación de la emergencia.', "ERROR", 0) : NotificationManager.error(err, "ERROR", 0)
            })
            .finally(() => {
                dispatch(baseReducer.actions.setLoader(false));
            });
    };

const updatePatch = (id, data = {}, attachments = []) => (dispatch, getStore) => {
    dispatch(baseReducer.actions.setLoader(true));

    api.post(`emergency/${id}/update_status`, {status: data}).then(() => {
        NotificationManager.success('Emergencia actualizada', 'Éxito', 3000);
        dispatch(customListar());
        // dispatch(push(`/emergency/${id}/editar`));
    }).catch((e) => {
        let vehicles = ''
        e && e.data && e.data.map((vh, i) => {
            vehicles = vehicles + vh + ", "
        })
        

        e && e.detail
            ? e.detail === "Estado Invalido" &&
                (
                    Swal.fire(
                        "¡Error!",
                        `No se pudo actualizar el estado de la emergencia porque los vehículos <strong> ${vehicles} </strong> tienen una emergencia en progreso.`,
                        "error"
                    )
                )
            : NotificationManager.error(
                    "Error en la edición",
                    "ERROR",
                    0
                );

    }).finally(() => {
        dispatch(baseReducer.actions.setLoader(false));
    });
    };

const customListar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore()['emergencies'];
    const params = {page};
    params.ordering = resource.ordering;
    params.search = resource.search;
    params.status = resource.statusEmergency;
    
    // filter station
    const station_id = resource.station
        ? resource.station.map((station) => station.id)
        : "";
    params.station_emergency__station = station_id;

    const emergency_type_id = resource.emergency_type_id ? resource.emergency_type_id.id : "" ;
    params.emergency_type_id = emergency_type_id ;
    // filter vehicle
    const vehicle = resource.vehicle ? resource.vehicle.id : "" ;
    params.vehicle_emergency__vehicle = vehicle ;
    // filter start date
    const start_date = resource.start_date ? moment(resource.start_date).format('YYYY-MM-DD') : "";
    params.start_date = start_date ;
    // filter end date
    const end_date = resource.end_date ? moment(resource.end_date).format('YYYY-MM-DD') : "";
    params.end_date = end_date ;
    
    // Filter by region
    const region_ = resource.region
    params.region_type = region_

    if (start_date && end_date)
        if (end_date < start_date)
            return NotificationManager.error("La fecha inicial no puede ser mayor a la fecha final", "ERROR", 0);

    dispatch(baseReducer.actions.setLoader(true));
    api.get('emergency', params).then((response) => {
        dispatch(baseReducer.actions.setData(response));
        dispatch(baseReducer.actions.setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(baseReducer.actions.setLoader(false));
    });
};


const customRetrieve = (id) => (dispatch) => {
    dispatch(baseReducer.actions.setLoader(true));
    api.get(`emergency/${id}`, {custom_view: true})
        .then((response) => {
            dispatch(baseReducer.actions.setItem(response));
        })
        .catch((err) => {
            console.log(err);
            NotificationManager.error("Error al obtener detalles de la Emergencia", "ERROR", 0);
        })
        .finally(() => {
            dispatch(baseReducer.actions.setLoader(false));
        });
};

const customRetrieveOpen = (id) => (dispatch) => {
    dispatch(baseReducer.actions.setLoader(true));
    api.get(`emergency_open/${id}`, {open: true})
        .then((response) => {
            dispatch(baseReducer.actions.setItem(response));
        })
        .catch((err) => {
            console.log(err);
            NotificationManager.error("Error al obtener detalles de la Emergencia", "ERROR", 0);
        })
        .finally(() => {
            dispatch(baseReducer.actions.setLoader(false));
        });
};

const filter_by_status = (option) => (dispatch, getStore) => {
    const resource = getStore()['emergencies'];
    dispatch(setStateFileter(option?option:'' ));
    dispatch(customListar());
};

const filterByRegion = (value) => (dispatch) => {
    if (value === null) {
        value = ''
    }
    dispatch(setOptionsRegion(value))
    dispatch(customListar())
}

const setStation = (value) => (dispatch, getStore) => {
    dispatch(setFilterByStation(value));
    dispatch(customListar());
};

const setTypeEmergency = (value) => (dispatch, getStore) => {
    dispatch(setFilterByTypeEmergency(value));
    dispatch(customListar());
};

const setTypeVehicle = (value) => (dispatch, getStore) => {
    dispatch(setFilterByVehicle(value));
    dispatch(customListar());
};

const setStartDate = (value) => (dispatch, getStore) => {
    const resource = getStore()['emergencies'];
    dispatch(setFilterByStartDate(value));
    if (resource.end_date)
        dispatch(customListar());
    
};

const setEndDate = (value) => (dispatch, getStore) => {
    const resource = getStore()['emergencies'];
    dispatch(setFilterByEndDate(value));
    if (resource.start_date)
        dispatch(customListar());
};

const initializeFilterStatus = () => (dispatch, getStore) => {
    const resource = getStore()["emergencies"];
    dispatch(
        change(
            "ListEmergencies",
            "statusEmergency",
            resource.statusEmergency ? resource.statusEmergency : null,
            resource.station ? resource.station : null,
            resource.start_date ? resource.start_date : null,
            resource.end_date ? resource.end_date : null,
            resource.region ? resource.region : null,
            resource.emergency_type_id ? resource.emergency_type_id : null,
            resource.vehicle ? resource.vehicle : null,
        )
    );
};
export const actions = {
    customRetrieve,
    customRetrieveOpen,
    createAttachment,
    updateAttachment,
    updatePatch,
    customListar,
    filter_by_status,
    filterByRegion,
    initializeFilterStatus,
    setStation,
    setTypeEmergency,
    setTypeVehicle,
    setStartDate,
    setEndDate,
    descargarExcel,
    ...baseReducer.actions,
};

export const reducers = {
    ...baseReducer.reducers,
    [STATUS_EMERGENCY_FILTER]: (state, {statusEmergency}) => {
        return {
            ...state,
            statusEmergency,
        };
    },
    [FILTER_BY_STATION]: (state, {station}) => {
        return {
            ...state,
            station,
        };
    },
    [FILTER_BY_TYPE_EMERGENCY]: (state, {emergency_type_id}) => {
        return {
            ...state,
            emergency_type_id,
        };
    },
    [SET_OPTION_STATUS_REGION_EMERGENCY]: (state, {region}) => {
        return {
            ...state,
            region,
        };
    },
    [FILTER_BY_VEHICLE]: (state, {vehicle}) => {
        return {
            ...state,
            vehicle,
        };
    },
    [FILTER_BY_START_DATE]: (state, {start_date}) => {
        return {
            ...state,
            start_date,
        };
    },
    [FILTER_BY_END_DATE]: (state, {end_date}) => {
        return {
            ...state,
            end_date,
        };
    },
};

export const initialState = {
    ...baseReducer.initialState,
    statusEmergency: '',
    region: '',
    station: '',
    emergency_type_id: '',
    vehicle: '',
    start_date: '',
    end_date: '',
};

export default handleActions(reducers, initialState);
