import moment from "moment";
import React from "react";
import Grid from "../../../Utils/Grid";

const Container = ({ data, list, log_history_hours, filter }) => {
    let status = "Sin estado";

    // Estado
    if (data.status === "Activo") {
        status = "De alta";
    } else if (data.status === "Inactivo")
        status = `De baja (${data.inactive_reason})`;

    // Estado Actual
    let color = "point-orange";
    let text = "Sin registro";

    if (data && data.is_entry === false) {
        color = "point-red";
        text = "Salida";
    } else if (data && data.is_entry == true) {
        color = "point-green";
        text = "Ingreso";
    }

    const columns = [
        {
            dataField: "date",
            text: "Fecha",
            formatter: (cell, row) =>
                row.date && row.date
                    ? moment(row.date).tz("UTC").format("DD-MM-YYYY - HH:mm A")
                    : "- - - - -",
        },

        {
            dataField: "action",
            text: "Acción",
        },
    ];

    // Minutes to hors (1hrs 0min)
    const converter = (minutes) => {
        const hours = Math.floor(minutes / 60);
        const minutes_ = minutes % 60;
        return `${hours}hrs ${minutes_}min`;
    };

    return (
        <div className="d-flex flex-md-25 m-4">
            <div className="flex-2 flex-wrap mr-4 w-100 w-md-25">
                <h4 className="font-weight-bold">Resumen</h4>
                <hr className="line-detail-profile" />
                <div className="d-flex flex-column flex-1 mb-4">
                    <div className="form-group has-feedback">
                        <label> Último ingreso </label>
                        <input
                            style={{ color: "black" }}
                            disabled={true}
                            type={"text"}
                            className={"form-control"}
                            value={
                                data && data.last_entry_exit.date_entry
                                    ? moment(data.last_entry_exit.date_entry)
                                          .tz("UTC")
                                          .format("DD-MM-YYYY - HH:mm A")
                                    : "No ha ingresado"
                            }
                        />
                    </div>

                    <div className="form-group has-feedback">
                        <label> Última salida </label>
                        <input
                            style={{ color: "black" }}
                            disabled={true}
                            type={"text"}
                            className={"form-control"}
                            value={
                                data && data.last_entry_exit.date_exit
                                    ? moment(data.last_entry_exit.date_exit)
                                          .tz("UTC")
                                          .format("DD-MM-YYYY - HH:mm A")
                                    : "No ha salido"
                            }
                        />
                    </div>

                    {data && data.status === "Inactivo" && (
                        <React.Fragment>
                            <div className="form-group has-feedback">
                                <label>
                                    {" "}
                                    {`Fecha inicial de ${data.inactive_reason}`}{" "}
                                </label>
                                <input
                                    style={{ color: "black" }}
                                    disabled={true}
                                    type={"text"}
                                    className={"form-control"}
                                    value={
                                        data && data.start_date_unsubscribe
                                            ? moment(
                                                  data.start_date_unsubscribe
                                              )
                                                  .tz("UTC")
                                                  .format("DD-MM-YYYY")
                                            : "- - - - -"
                                    }
                                />
                            </div>

                            <div className="form-group has-feedback">
                                <label>
                                    {" "}
                                    {`Fecha final de ${data.inactive_reason}`}{" "}
                                </label>
                                <input
                                    style={{ color: "black" }}
                                    disabled={true}
                                    type={"text"}
                                    className={"form-control"}
                                    value={
                                        data && data.end_date_unsubscribe
                                            ? moment(data.end_date_unsubscribe)
                                                  .tz("UTC")
                                                  .format("DD-MM-YYYY")
                                            : "- - - - -"
                                    }
                                />
                            </div>
                        </React.Fragment>
                    )}
                </div>
                <h4 className="font-weight-bold">Historial</h4>
                <hr className="line-detail-profile" />

                {list && (
                    <Grid
                        data={list}
                        // loading={loader}
                        columns={columns}
                        onPageChange={log_history_hours}
                        noDataMessage={"Sin Registro"}
                    />
                )}
            </div>

            {/* USER INFO */}
            <div className="flex-2 bk-grey section_info w-100 w-md-25">
                <div className="mt-4">
                    <div className="form-group has-feedback">
                        <label> Total Horas Diarias </label>
                        <h4 className="title-blue font-weight-bold">
                            {converter(data.total_hours_day)}
                        </h4>
                    </div>
                    <div className="form-group has-feedback">
                        <label> Total de Horas </label>
                        <h4 className="title-blue font-weight-bold">
                            {list.results && list.results.length > 0
                                ? converter(list.results[0].total_times)
                                : converter(0)}
                            {/* {converter(list.total)} */}
                        </h4>
                    </div>
                    <hr className="line-detail-profile" />
                    <div className="form-group has-feedback">
                        <label> Usuario </label>
                        <h4>{data.first_name + " " + data.last_name}</h4>
                    </div>
                    <div className="form-group has-feedback">
                        <label> DPI </label>
                        <h4>{data.no_dpi}</h4>
                    </div>
                    <div className="form-group has-feedback">
                        <label> Estado </label>
                        <h4>{status}</h4>
                    </div>
                    {data && data.status === "Activo" && (
                        <div className="form-group has-feedback">
                            <label> Estado Actual</label>
                            <h4>
                                <span className={"material-icons " + color}>
                                    fiber_manual_record
                                </span>
                                <span className="">{text}</span>
                            </h4>
                        </div>
                    )}
                    <div className="form-group has-feedback">
                        <label> Tipo </label>
                        <h4>
                            {data.firefighter_type
                                ? data.firefighter_type
                                : "- - - - -"}
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Container;

// export default reduxForm({
//     form: "ControlHoursForm",
// })(Container);
