import React from 'react';
import {Link} from "react-router-dom";
import { connect } from 'react-redux';
import {Field, reduxForm, formValueSelector} from 'redux-form';
import {renderField} from '../../Utils/renderField';
import {validate, validators} from 'validate-redux-form';
import {SelectField} from "../../Utils/renderField/renderField";
import {ON_TRACKING, VEHICLE_STATUS, VEHICLE_STATUS_CREATE} from "../../../../utility/constants";
import { validateWhiteSpace } from '../../../../utility/validation';

let Form = props => {
    const {handleSubmit, isUpdate, isView, stations, vehicle_types, disponibility, on_tracking} = props;
    console.log(on_tracking, 'on_tracking');
    return (
        <form name="loginForm" onSubmit={handleSubmit}>
            <div className="d-flex flex-wrap">
            <div className="col-12 col-md-6 form-group">
                    <label htmlFor="plate">Placa</label>
                    <Field
                        type="text"
                        disabled={isView}
                        name="plate"
                        label="plate"
                        component={renderField}
                        validate={validateWhiteSpace}
                        className="form-control"/>
                </div>
                <div className="col-12 col-md-6 form-group">
                    <label htmlFor="model">Modelo</label>
                    <Field
                        type="text"
                        name="model"
                        label="model"
                        disabled={isView}
                        component={renderField}
                        validate={validateWhiteSpace}
                        className="form-control"/>
                </div>
                <div className="col-12 col-md-6 form-group">
                    <label htmlFor="name">Nota</label>
                    <Field
                        type="text"
                        name="note"
                        label="note"
                        disabled={isView}
                        component={renderField}
                        validate={validateWhiteSpace}
                        className="form-control"/>
                </div>
                <div className="col-12 col-md-6 form-group">
                    <label htmlFor="unit">Unidad</label>
                    <Field
                        type="text"
                        disabled={isView}
                        name="unit"
                        label="unit"
                        component={renderField}
                        validate={validateWhiteSpace}
                        className="form-control"/>
                </div>
                <div className="col-12 col-md-6 form-group">
                    <label htmlFor="disponibility">Disponibilidad</label>
                    <Field
                        name="disponibility"
                        label="disponibility"
                        placeholder="Disponibilidad"
                        disabled={disponibility && disponibility === 40 ? true : false}
                        options={isView ? VEHICLE_STATUS : disponibility && disponibility === 40 ? VEHICLE_STATUS : VEHICLE_STATUS_CREATE}
                        component={SelectField}
                        className="form-control"/>
                </div>
                <div className="col-12 col-md-6 form-group">
                    <label htmlFor="year">Año</label>
                    <Field
                        type="number"
                        disabled={isView}
                        name="year"
                        label="year"
                        component={renderField}
                        className="form-control"/>
                </div>
                <div className="col-12 col-md-6 form-group">
                    <label htmlFor="station">Estación</label>
                    <Field
                        name="station"
                        label="station"
                        placeholder="Estación"
                        disabled={isView}
                        options={stations}
                        component={SelectField}
                        className="form-control"/>
                </div>
                <div className="col-12 col-md-6 form-group">
                    <label htmlFor="vehicle_type">Tipo de vehículo</label>
                    <Field
                        name="vehicle_type"
                        label="vehicle_type"
                        placeholder="Tipo de vehículo"
                        disabled={isView}
                        options={vehicle_types}
                        component={SelectField}
                        className="form-control"/>
                </div>
                {isUpdate && (
                    <div className="col-12 col-md-6 form-group">
                        <label htmlFor="vehicle_type">Trackeo</label>
                            <Field
                                name="on_tracking"
                                label="on_tracking"
                                placeholder="Estado de trackeo"
                                disabled={!on_tracking}
                                options={ON_TRACKING}
                                component={SelectField}
                                className="form-control"
                            />
                    </div>
                )}

                <div className="col-12 d-flex flex-column flex-md-row justify-content-center">
                    <div className="flex-3"></div>
                    <div className='flex-1 d-flex flex-column flex-md-row justify-content-center'>
                        <Link
                            to={'/vehicles'}
                            className={isView ? 'btn btn-secondary px-3 py-3 mt-3 mb-3 w-100' : 'btn btn-danger py-3 mt-3 mb-3 w-100'}
                        >
                            {isView ? 'Atras' : 'Cancelar'}
                        </Link>
                        {
                            !isView &&
                            <button
                                type="submit"
                                className="btn btn-success align-self-center py-3 m-3 w-100">
                                {isUpdate ? "Actualizar" : "Crear"}
                            </button>
                        }
                    </div>
                </div>
            </div>
        </form>
    );
};

Form = reduxForm({
    form: 'VehicleForm',
    validate: (data) => {
        return validate(data, {
            note: validators.exists()('Este campo es requerido'),
            model: validators.exists()('Este campo es requerido'),
            plate: validators.exists()('Este campo es requerido'),
            unit: validators.exists()('Este campo es requerido'),
            disponibility: validators.exists()('Este campo es requerido'),
            year: validators.exists()('Este campo es requerido'),
            station: validators.exists()('Este campo es requerido'),
            vehicle_type: validators.exists()('Este campo es requerido'),
        });
    },
})(Form);

const selector = formValueSelector('VehicleForm');
Form = connect(state => {
    const disponibility = selector(state, 'disponibility');
    const on_tracking = selector(state, 'on_tracking');
    return {
        disponibility,
        on_tracking,
    }
})(Form);
export default Form