import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import Grid from "../../Utils/Grid";
import Map from "../../Utils/Map";

const ContainerDetail = ({ props, item, columns, open = false }) => {
    return (
        <React.Fragment>
            <div
                className={`main-container-emergency ${open && 'p-2'}`}
                style={{ backgroundColor: "rgb(243, 245, 252)" }}
            >
                {/* DETALLES DE LA EMERGENCIA*/}
                <div className="card-1-emergency">
                    <div className="py-2 pl-3 pr-2 w-100 d-flex py-auto my-auto">
                        <div
                            className={`container-title-create${
                                props.item.status === 20
                                    ? "-active"
                                    : props.item.status === 50
                                    ? "-critical"
                                    : props.item.status === 40
                                    ? "-requesting_support"
                                    : props.item.status === 30
                                    ? "-resolved"
                                    : ""
                            }`}
                            style={{ width: "15px", borderRadius: "5px" }}
                        ></div>
                        <div className="col-lg-6 title-blue">
                            <h2 className="font-weight-bold title-blue">
                                Emergencia
                            </h2>
                            <h2 className="font-weight-bold title-blue">
                                #{item.id}
                            </h2>
                        </div>
                    </div>
                </div>

                <div className="card-3-emergency w-100">
                    <div className="border-bottom mb-3 pb-3">
                        <h5 className="title-emergencies-details">
                            Detalle General
                        </h5>
                        <p className="p-details">
                            Registrado por:
                            <span className="value-details">
                                {" "}
                                {item && item.created_user
                                    ? item.created_user.first_name +
                                      " " +
                                      item.created_user.last_name
                                    : "Sin dato"}{" "}
                            </span>
                        </p>
                        <p className="p-details">
                            Fecha de emergencia resuelta:{" "}
                            <span className="value-details">
                                {item.attention_date
                                    ? moment(item.attention_date).format(
                                          "DD/MM/YY h:mm a"
                                      )
                                    : "Sin dato"}
                            </span>
                        </p>
                        <p className="p-details">
                            Fecha Emergencia Reportada:{" "}
                            <span className="value-details">
                                {" "}
                                {item.resolved_date
                                    ? moment(item.resolved_date).format(
                                        "DD/MM/YY h:mm a"
                                      )
                                    : " - - - - -"}{" "}
                            </span>
                        </p>
                        <p className="p-details">
                            Tipo de Emergencia:{" "}
                            <span className="value-details">
                                {" "}
                                {item && item.emergency_type
                                    ? item.emergency_type
                                    : "Sin Tipo"}{" "}
                            </span>
                        </p>
                    </div>
                </div>

                <div className="card-4-emergency">
                    <h5 className="title-emergencies-details">
                        Ubicación de la Emergencia
                    </h5>
                    <p className="p-details">
                        Dirección:{" "}
                        <span className="value-details"> {item.address} </span>
                    </p>
                    <p className="p-details">
                        Latitud:{" "}
                        <span className="value-details"> {item.latitude} </span>
                    </p>
                    <p className="p-details">
                        Longitud:{" "}
                        <span className="value-details">
                            {" "}
                            {item.longitude}{" "}
                        </span>
                    </p>
                </div>

                <div className="card-5-emergency">
                    <h5 className="title-emergencies-details">
                        Equipo desplegado:
                    </h5>
                    <p className="p-details">
                        Elementos:{" "}
                        <span className="value-details">
                            {" "}
                            {item.total_firefighters}{" "}
                        </span>
                    </p>
                    <p className="p-details">
                        Estaciones:{" "}
                        <span className="value-details">
                            {" "}
                            {item.total_stations
                                ? item.total_stations.names.substring(
                                      0,
                                      item.total_stations.names.length
                                          ? item.total_stations.names.length - 2
                                          : 0
                                  )
                                : ""}{" "}
                        </span>
                    </p>
                    <p className="p-details">
                        Nombre del piloto:{" "}
                        <span className="value-details">
                            {" "}
                            {item.telephonist
                                ? item.telephonist.first_name +
                                  " " +
                                  item.telephonist.last_name
                                : ""}{" "}
                        </span>
                    </p>
                    <p className="p-details">
                        Unidades:{" "}
                        <span className="value-details">
                            {" "}
                            {item.vehicle
                                ? item.vehicle
                                : ""
                            }{" "}
                        </span>
                    </p>
                </div>

                <div className="card-6-emergency">
                    <h5 className="title-emergencies-details">
                        Observaciones:
                    </h5>
                    <div 
                        className="border p-2 bg-white"
                        style={{ minHeight: "140px", borderRadius: "5px"}}
                        dangerouslySetInnerHTML={{ __html: item.observations }}
                    />
                </div>

                <div className="card-8-emergency p-4 overflow-auto">
                    <h5 className="title-emergencies-details">Anexos:</h5>
                    <div className="d-flex align-items-end">
                        {Object.entries(item).length !== 0 &&
                            item.image &&
                            item.image.map((i) => {
                                return (
                                    <a href={i.path} target="_blank">
                                        <img
                                            className="rounded mr-4"
                                            key={i.id}
                                            src={i.path}
                                            alt="emeregency-image"
                                            width="400px"
                                        />
                                    </a>
                                );
                            })}
                    </div>
                </div>

                <div className="card-7-emergency">
                    <h5 className="title-emergencies-details">Pacientes:</h5>
                    <div className="overflow-auto" style={{ maxHeight: 250 }}>
                        {Object.entries(item).length !== 0 &&
                            item.pacients &&
                            item.pacients.results && (
                                <Grid
                                    showPagination={false}
                                    data={item.pacients}
                                    columns={columns}
                                    noDataMessage={"Sin pacientes"}
                                    loading={props.loader}
                                />
                            )}
                    </div>
                </div>

                {!open && (
                    <div className="card-9-emergency p-4">
                        <h5 className="title-emergencies-details">Codigo QR</h5>
                        {item.qr_emergency ? (
                            <a
                                href={item.qr_emergency && item.qr_emergency}
                                target="_blank"
                            >
                                <img
                                    className="qr-mobile"
                                    src={
                                        item.qr_emergency
                                            ? item.qr_emergency
                                            : null
                                    }
                                    alt={
                                        item.qr_emergency
                                            ? item.id
                                            : "code_qr.png"
                                    }
                                    // disabled={isView}
                                />
                            </a>
                        ) : (
                            <h5>Sin codigo QR</h5>
                        )}
                    </div>
                )}

                {/*  SECCION PARA EL MAPA  */}
                <div className="card-2-emergency">
                    <div
                        className="w-100 pl-4 pr-2 py-2 border-danger"
                        style={{ height: "95%" }}
                    >
                        <div className="justify-content-between d-flex flex-wrap">
                            <h3 className="title-emergencies-details">
                                Ubicación de la Emergencia
                            </h3>
                        </div>
                        {item.id && (
                            <Map
                                mapContainerStyle={{
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "10px",
                                    border: "2px solid #2350C7",
                                }}
                                fromLat={
                                    item.latitude != 0
                                        ? parseFloat(item.latitude)
                                        : 14.845239
                                }
                                fromLng={
                                    item.longitude != 0
                                        ? parseFloat(item.longitude)
                                        : -91.523187
                                }
                                toLat={
                                    item.latitude != 0
                                        ? parseFloat(item.latitude)
                                        : 14.845239
                                }
                                toLng={
                                    item.longitude != 0
                                        ? parseFloat(item.longitude)
                                        : -91.523187
                                }
                                vehicles={[
                                    {
                                        coords: {
                                            lat:
                                                item.latitude != 0
                                                    ? parseFloat(item.latitude)
                                                    : 14.845239,
                                            lng:
                                                item.longitude != 0
                                                    ? parseFloat(item.longitude)
                                                    : -91.523187,
                                        },
                                    },
                                ]}
                            />
                        )}
                    </div>
                </div>
                {!open && (
                    <div className="btn-atras p-4">
                        <Link
                            to={"/emergencies"}
                            className={
                                "btn btn-secondary px-3 py-3 mt-3 mb-3 w-100"
                            }
                        >
                            Atras
                        </Link>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};

export default ContainerDetail;
