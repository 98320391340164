import React, {Component, Fragment,useEffect}  from 'react';
import {
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter } from 'reactstrap';
import Grid from '../../Utils/Grid';
import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/vehiclesEmergency/vehiclesEmergency';
import { actions as station } from '../../../../redux/modules/station/station';
import { actions as vehicles_type } from '../../../../redux/modules/vehicleTypes/vehicleTypes';
import {VEHICLE_STATUS} from "../../../../utility/constants";
import { VehiclesEmergencyActions } from '../../Utils/Grid/VehiclesEmergencyActions';

const SelectorVehicle = (props) => {
    const {
        isOpen,
        handleChange,
        listarPersonalizado,
        data,
        loader,
        onSortChange,
        searchCustomChange,
        vehicleTracking,
        changeStatusTracking,
        setVehicle,
        closeModal
    } = props

    
    const STATUS = [{"label": "Todos", "value": null}].concat(VEHICLE_STATUS);
    const selects = [
        {
            title: "Estación",
            name: "station",
            data: props.station.filter_list,
            funcion: props.filterStation,
        },
        {
            title: "Tipo de vehículo",
            name: "vehicle_type",
            data: props.vehicles_type.filter_list,
            funcion: props.filterVehicleType,
        },
        {
            title: "Disponibilidad",
            name: "disponibility",
            data: STATUS,
            funcion: props.filterDisponibility,
        }
    ];

    const columns = [
        {
            dataField: "plate",
            text: "Placa",
        },
        {
            dataField: "model",
            text: "Modelo",
        },
        {
            dataField: "note",
            text: "Nota"
        },
        {
            dataField: "station.name",
            text: "Estación",
        },
        {
            dataField: "station.address",
            text: "Dirección",
        },
        {
            dataField: "disponibility",
            text: "Disponibilidad",
            formatter: (cell, row) => {
                let color = "point-success";
                let is_open = false;

                if (!row.on_tracking) {
                    color = "point-green";
                } else {
                    color = "point-red";
                }
            return (
                <div className="d-flex">
                    <Fragment>
                        <span className={"material-icons " + color}>fiber_manual_record</span>
                        <span className="">{" "}{row.on_tracking? "No Disponible":"Disponible"}{" "}</span>
                    </Fragment>      
                </div>
                );
                },
            },
            {
                dataField: "id",
                text: "Trackear",
                formatter: (cell, row) => VehiclesEmergencyActions({setTracking:setVehicle,getVehicle:vehicleTracking,changeTracking:changeStatusTracking,closeModal:closeModal})(cell, row)
            },
        ];

    useEffect(() => {
        props.listarVehiculosOnEmergency();
        props.changeValueSelectVehicles();
    }, [])   

    const stopTracking = () => {
        vehicleTracking
    }
    
    return (
        <Modal isOpen={isOpen} size={'lg'} className={"modal__selector--vehicle"} style={{maxWidth: '75rem', width: '100%'}} backdrop="static" centered>
            <div>
                <span 
                    style={{
                        display:"inline-block",
                        fontSize: "1.5rem",
                        cursor: "pointer",
                    }}
                    onClick={handleChange}
                >
                    <span style={{color: 'white'}}> <strong>Cerrar</strong> X</span>
                </span>
            </div>
            <div className={"modal__selector__content"}>
                <ModalHeader>
                    <div className='title_list_vehicles'>
                        <h2
                            className={"title-blue font-weight-bold"}
                        >
                            Vehículos
                        </h2>
                        <h5 className={"subtitle-gray"}>
                            Listado de Vehiculos disponibles
                        </h5>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div className="p-0 px-3 pt-3">
                        <Grid
                            data={data}
                            loading={loader}
                            columns={columns}
                            onPageChange={props.listarVehiculosOnEmergency}
                            noDataMessage={"Sin vehículos"}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>

                </ModalFooter>  
            </div>
        </Modal>
    );
}

const ms2p = (state) => {
    return {
        ...state.vehiclesEmergency,
        station: { ...state.station },
        vehicles_type: { ...state.vehicleTypes },
        me: state.login.me,
    }
};

const md2p = { ...actions,  stationFilterList: station.filterList, vehicleTypesFilterList: vehicles_type.filterList };

export default connect(ms2p, md2p)(SelectorVehicle);