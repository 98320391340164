import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/emergencyTypes/category_emergency_type';
import Container from './Container';


const ms2p = (state) => {
    return {
        ...state.categoryEmergencyTypes,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Container);
