import { api } from "api";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import { handleActions } from "redux-actions";
import { createReducer } from "../baseReducer/baseReducer";

const baseReducer = createReducer(
    "control_hours",
    "job_management/listUserControlHours",
    "ControlHoursForm"
);

// ------------------------------------
// Constants
// ------------------------------------
const SET_STATION_CONTROL_HOURS = "SET_STATION_CONTROL_HOURS";
const SET_USER_CONTROL_HOURS = "SET_USER_CONTROL_HOURS";
const SET_START_DATE_CONTROL_HOURS = "SET_START_DATE_CONTROL_HOURS";
const SET_END_DATE_CONTROL_HOURS = "SET_END_DATE_CONTROL_HOURS";
const SET_USER_INFORMATION_CONTROL_HOURS = "SET_USER_INFORMATION_CONTROL_HOURS";
const SET_LOG_HISTORY_CONTROL_HOURS = "SET_LOG_HISTORY_CONTROL_HOURS";
const SET_ID_USER_CONTROL_HOURS = "SET_ID_USER_CONTROL_HOURS";
const SET_START_DATE_USER_CONTROL_HOURS = "SET_START_DATE_USER_CONTROL_HOURS";
const SET_END_DATE_USER_CONTROL_HOURS = "SET_END_DATE_USER_CONTROL_HOURS";
// ------------------------------------
// Pure Actions
// ------------------------------------
const setOptionsStation = (station) => ({
    type: SET_STATION_CONTROL_HOURS,
    station,
});
const setOptionsUser = (user_) => ({
    type: SET_USER_CONTROL_HOURS,
    user_,
});

const setOptionStartDate = (start_date) => ({
    type: SET_START_DATE_CONTROL_HOURS,
    start_date,
});

const setOptionEndDate = (end_date) => ({
    type: SET_END_DATE_CONTROL_HOURS,
    end_date,
});

const setUserInformation = (user_information) => ({
    type: SET_USER_INFORMATION_CONTROL_HOURS,
    user_information,
});

const setLogHistory = (log_history) => ({
    type: SET_LOG_HISTORY_CONTROL_HOURS,
    log_history,
});

const setIdUser = (id_user_control_hours) => ({
    type: SET_ID_USER_CONTROL_HOURS,
    id_user_control_hours,
});

const setOptionStartDateUser = (start_date_user) => ({
    type: SET_START_DATE_USER_CONTROL_HOURS,
    start_date_user,
});

const setOptionEndDateUser = (end_date_user) => ({
    type: SET_END_DATE_USER_CONTROL_HOURS,
    end_date_user,
});

const customUserList =
    (page = 1) =>
    (dispatch, getStore) => {
        const resource = getStore()["control_hours"];
        const params = { page };

        // Station
        const station = resource.station
            ? resource.station.map((station) => station.id)
            : "";
        params.station = station;

        // User
        const user_ = resource.user_ ? resource.user_.id : "";
        params.user = user_;

        // DATE
        const start_date = resource.start_date
            ? moment(resource.start_date).format("YYYY-MM-DD")
            : "";
        params.start_date = start_date;
        // filter end date
        const end_date = resource.end_date
            ? moment(resource.end_date).format("YYYY-MM-DD")
            : "";
        params.end_date = end_date;

        if (start_date && end_date)
            if (end_date < start_date) {
                dispatch(baseReducer.actions.setLoader(false));
                return NotificationManager.error(
                    "La fecha inicial no puede ser mayor a la fecha final",
                    "ERROR",
                    0
                );
            }

        dispatch(baseReducer.actions.setLoader(true));
        api.get("job_management/listUserControlHours", params)
            .then((response) => {
                dispatch(baseReducer.actions.setData(response));
                dispatch(baseReducer.actions.setPage(page));
            })
            .catch((err) => {
                console.log("Error:", err);
            })
            .finally(() => {
                dispatch(baseReducer.actions.setLoader(false));
            });
    };

const userData = (id) => (dispatch) => {
    dispatch(baseReducer.actions.setLoader(true));
    dispatch(setIdUser(id));
    api.get(`job_management/${id}/user_information`)
        .then((response) => {
            dispatch(setUserInformation(response));
        })
        .catch((err) => {
            console.log("Error:", err);
        })
        .finally(() => {
            dispatch(baseReducer.actions.setLoader(false));
        });
};

const log_history_hours =
    (page = 1) =>
    (dispatch, getStore) => {
        const resource = getStore()["control_hours"];
        const params = { page };
        // DATE
        const start_date_user = resource.start_date_user
            ? moment(resource.start_date_user).format("YYYY-MM-DD")
            : "";
        params.start_date_user = start_date_user;
        // filter end date
        const end_date_user = resource.end_date_user
            ? moment(resource.end_date_user).format("YYYY-MM-DD")
            : "";
        params.end_date_user = end_date_user;

        if (start_date_user && end_date_user)
            if (end_date_user < start_date_user) {
                dispatch(baseReducer.actions.setLoader(false));
                return NotificationManager.error(
                    "La fecha inicial no puede ser mayor a la fecha final",
                    "ERROR",
                    0
                );
            }

        dispatch(baseReducer.actions.setLoader(true));
        api.get(
            `job_management/${resource.id_user_control_hours}/log_history_hours`,
            params
        )
            .then((response) => {
                dispatch(setLogHistory(response));
            })
            .catch((err) => {
                console.log("Error:", err);
            })
            .finally(() => {
                dispatch(baseReducer.actions.setLoader(false));
            });
    };

const setStation = (value) => (dispatch, getStore) => {
    dispatch(setOptionsStation(value));
    dispatch(customUserList());
};

const setUser = (value) => (dispatch, getStore) => {
    dispatch(setOptionsUser(value));
    dispatch(customUserList());
};

const setStartDate = (value) => (dispatch, getStore) => {
    const resource = getStore()["control_hours"];
    dispatch(setOptionStartDate(value));
    if (resource.end_date) dispatch(customUserList());
};

const setEndDate = (value) => (dispatch, getStore) => {
    const resource = getStore()["control_hours"];
    dispatch(setOptionEndDate(value));
    if (resource.start_date) dispatch(customUserList());
};

const setStartDateUser = (value) => (dispatch, getStore) => {
    const resource = getStore()["control_hours"];
    dispatch(setOptionStartDateUser(value));
    if (resource.end_date_user) dispatch(log_history_hours());
};

const setEndDateUser = (value) => (dispatch, getStore) => {
    const resource = getStore()["control_hours"];
    dispatch(setOptionEndDateUser(value));
    if (resource.start_date_user) dispatch(log_history_hours());
};

const setClerFilters = () => (dispatch) => {
    dispatch(setStartDateUser(''));
    dispatch(setEndDateUser(''));
};

export const actions = {
    customUserList,
    setStation,
    setUser,
    setStartDate,
    setEndDate,
    userData,
    log_history_hours,
    setStartDateUser,
    setEndDateUser,
    setClerFilters,
    ...baseReducer.actions,
};

export const reducers = {
    [SET_STATION_CONTROL_HOURS]: (state, { station }) => ({
        ...state,
        station,
    }),
    [SET_USER_CONTROL_HOURS]: (state, { user_ }) => ({
        ...state,
        user_,
    }),
    [SET_START_DATE_CONTROL_HOURS]: (state, { start_date }) => ({
        ...state,
        start_date,
    }),
    [SET_END_DATE_CONTROL_HOURS]: (state, { end_date }) => ({
        ...state,
        end_date,
    }),
    [SET_USER_INFORMATION_CONTROL_HOURS]: (state, { user_information }) => ({
        ...state,
        user_information,
    }),
    [SET_LOG_HISTORY_CONTROL_HOURS]: (state, { log_history }) => ({
        ...state,
        log_history,
    }),
    [SET_ID_USER_CONTROL_HOURS]: (state, { id_user_control_hours }) => ({
        ...state,
        id_user_control_hours,
    }),
    [SET_START_DATE_USER_CONTROL_HOURS]: (state, { start_date_user }) => ({
        ...state,
        start_date_user,
    }),
    [SET_END_DATE_USER_CONTROL_HOURS]: (state, { end_date_user }) => ({
        ...state,
        end_date_user,
    }),
    ...baseReducer.reducers,
};

export const initialState = {
    station: "",
    user_: "",
    start_date: "",
    end_date: "",
    user_information: "",
    log_history: "",
    id_user_control_hours: "",
    start_date_user: "",
    end_date_user: "",
    ...baseReducer.initialState,
};

export default handleActions(reducers, initialState);
