import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Dropdown,
} from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
const defaultAvatar = require("../../../../../assets/img/avatar-placeholder.png");
import Avatar from "react-avatar";
import "./sidebar.css";
import {
    HUMAN_RESOURCES,
    ROLE_ADMIN,
    ROLE_ADMIN_STATION,
    ROLE_CABIN_ATTENDANT,
    ROLE_STATION_BOSS,
} from "../../../../utility/constants";

import qr_code from "../../../../../assets/img/qr.svg";
import qr_code_white from "../../../../../assets/img/qrblanco.svg";

const SideBar = (props) => {
    const {
        toggleOpen,
        navToggle,
        logOut,
        user,
        url,
        showButtons,
        navMenu,
        burgerMenu,
    } = props;
    const [dropdownUserOpen, setDropdownUserOpen] = useState(false);

    let urlValidate = url.includes("/human_resources/");
    let urlValidateReport = url.includes("/report/");

    const styleActive = {
        backgroundColor: "#0b43c8",
        color: "white",
        marginRight: "10px",
        marginBottom: "10px",
    };

    const styleInactive = {
        backgroundColor: "#ffffff",
        color: "#1b2024",
        marginRight: "10px",
        marginBottom: "10px",
    };

    const [validate, setValidate] = useState(false);
    const [styleDrop, setStyleDrop] = useState(styleInactive);
    const [validateReport, setValidateReport] = useState(false);
    const [styleDropReport, setStyleDropReport] = useState(styleInactive);
    let dropdown = document.getElementsByClassName("dropdown-btn");
    let dropdown_report = document.getElementsByClassName(
        "dropdown-btn-reporting"
    );
    useEffect(() => {
        let i;

        for (i = 0; i < dropdown.length; i++) {
            dropdown[i].addEventListener("click", function () {
                this.classList.toggle("active");
                let dropdownContent = this.nextElementSibling;
                if (dropdownContent.style.display === "block") {
                    dropdownContent.style.display = "none";
                    setValidate(false);
                    setStyleDrop(styleInactive);
                } else {
                    dropdownContent.style.display = "block";
                    dropdownContent.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                    });
                    setValidate(true);
                    setStyleDrop(styleActive);
                }
            });
        }

        for (i = 0; i < dropdown_report.length; i++) {
            dropdown_report[i].addEventListener("click", function () {
                this.classList.toggle("active");
                let dropdownContent = this.nextElementSibling;
                if (dropdownContent.style.display === "block") {
                    dropdownContent.style.display = "none";
                    setValidateReport(false);
                    setStyleDropReport(styleInactive);
                } else {
                    dropdownContent.style.display = "block";
                    dropdownContent.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                    });
                    setValidateReport(true);
                    setStyleDropReport(styleActive);
                }
            });
        }
    }, []);

    useEffect(() => {
        validate
            ? setStyleDrop(styleActive)
            : urlValidate
            ? setStyleDrop(styleActive)
            : setStyleDrop(styleInactive);

        validateReport
            ? setStyleDropReport(styleActive)
            : urlValidateReport
            ? setStyleDropReport(styleActive)
            : setStyleDropReport(styleInactive);
    }, [url, validate, validateReport]);

    return (
        <React.Fragment>
            <aside
                className={` main-sidebar px-0 col-12 col-md-3 col-lg-2 ${
                    toggleOpen ? "" : "open"
                }`}
                style={{ display: !burgerMenu && "none" }}
            >
                <div className="" style={{ backgroundColor: "#F3F5FC" }}>
                    <nav
                        className="align-items-stretch flex-md-nowrap mb-2 p-0"
                        style={{ backgroundColor: "#F3F5FC" }}
                    >
                        <div
                            className="navbar d-flex justify-content-end"
                            style={{ marginRight: "20px" }}
                        >
                            <a
                                className="navbar-toggler"
                                type="button"
                                onClick={showButtons ? navToggle : navMenu}
                            >
                                <i className="material-icons"></i>
                            </a>
                        </div>
                        <br />
                        <a href="#" className="w-100 mt-3">
                            <div className="d-table m-auto">
                                <img
                                    id="main-logo"
                                    className="d-inline-block align-top mr-1"
                                    src={require("assets/img/logo_bomberos.png")}
                                    alt="Logo"
                                />
                            </div>
                        </a>
                    </nav>
                </div>
                <div className="h-100">
                    <div className="nav-wrapper">
                        <ul className="nav--no-borders flex-column nav">
                            <li className="nav-item d-flex justify-content-center align-content-center">
                                <NavLink
                                    onClick={navToggle}
                                    to="/dashboard"
                                    activeClassName={
                                        "btn btn-primary btn-block text-white"
                                    }
                                    className={`btn btn-white btn-block ${
                                        `${url}`.includes("/dashboard")
                                            ? "text-white"
                                            : "text-dark"
                                    }`}
                                    style={{ margin: "10px 15px" }}
                                >
                                    <div className="d-flex justify-content-center align-content-end">
                                        <span className="material-icons">
                                            dashboard
                                        </span>
                                        <span className="content-sidebar-buttons">
                                            Dashboard
                                        </span>
                                    </div>
                                </NavLink>
                            </li>

                            <li className="nav-item d-flex justify-content-center align-content-center">
                                <NavLink
                                    to="/stations"
                                    activeClassName={
                                        "btn btn-primary btn-block text-white"
                                    }
                                    className={`btn btn-white btn-block ${
                                        `${url}`.includes("/stations")
                                            ? "text-white"
                                            : "text-dark"
                                    }`}
                                    style={{ margin: "10px 15px" }}
                                    onClick={navToggle}
                                >
                                    <div className="d-flex justify-content-center align-content-end">
                                        <span className="material-icons">
                                            location_city
                                        </span>
                                        <span className="content-sidebar-buttons">
                                            Estaciones
                                        </span>
                                    </div>
                                </NavLink>
                            </li>
                            <li className="nav-item d-flex justify-content-center align-content-center">
                                <NavLink
                                    onClick={navToggle}
                                    to="/users"
                                    activeClassName={
                                        "btn btn-primary btn-block text-white"
                                    }
                                    className={`btn btn-white btn-block ${
                                        `${url}`.includes("/users")
                                            ? "text-white"
                                            : "text-dark"
                                    }`}
                                    style={{ margin: "10px 15px" }}
                                >
                                    <div className="d-flex justify-content-center align-content-end">
                                        <span className="material-icons">
                                            person
                                        </span>
                                        <span className="content-sidebar-buttons">
                                            Usuarios
                                        </span>
                                    </div>
                                </NavLink>
                            </li>
                            <li className="nav-item d-flex justify-content-center align-content-center">
                                <NavLink
                                    onClick={navToggle}
                                    to="/emergency-types"
                                    activeClassName={
                                        "btn btn-primary btn-block text-white"
                                    }
                                    isActive={(match, location) => {
                                        return !!(
                                            location &&
                                            location.pathname &&
                                            (location.pathname.includes(
                                                "/emergency-types"
                                            ) ||
                                                location.pathname.includes(
                                                    "/emergency-type"
                                                ))
                                        );
                                    }}
                                    className={`btn btn-white btn-block ${
                                        `${url}`.includes("/emergency-type")
                                            ? "text-white"
                                            : "text-dark"
                                    }`}
                                    style={{ margin: "10px 15px" }}
                                >
                                    <div className="d-flex justify-content-center align-content-end">
                                        <span className="material-icons">
                                            format_list_numbered_rtl
                                        </span>
                                        <span className="content-sidebar-buttons">
                                            Tipos de Emergencia
                                        </span>
                                    </div>
                                </NavLink>
                            </li>
                            <li className="nav-item d-flex justify-content-center align-content-center">
                                <NavLink
                                    onClick={navToggle}
                                    to="/emergencies"
                                    isActive={(match, location) => {
                                        return !!(
                                            (location &&
                                                location.pathname &&
                                                (location.pathname.includes(
                                                    "/emergency/"
                                                ) ||
                                                    location.pathname ==
                                                        "/emergency/" ||
                                                    location.pathname ==
                                                        "/emergencies")) ||
                                            location.pathname.includes(
                                                "/emergencies"
                                            )
                                        );
                                    }}
                                    activeClassName={
                                        "btn btn-primary btn-block text-white"
                                    }
                                    className={`btn btn-white btn-block ${
                                        `${url}`.includes("/emergencies") ||
                                        `${url}`.includes("/emergency/")
                                            ? "text-white"
                                            : "text-dark"
                                    }`}
                                    style={{ margin: "10px 15px" }}
                                >
                                    <div className="d-flex justify-content-center align-content-end">
                                        <span className="material-icons">
                                            settings_remote
                                        </span>
                                        <span className="content-sidebar-buttons">
                                            Emergencias
                                        </span>
                                    </div>
                                </NavLink>
                            </li>
                            <li className="nav-item d-flex justify-content-center align-content-center">
                                <NavLink
                                    onClick={navToggle}
                                    to="/vehicle-types"
                                    isActive={(match, location) => {
                                        if (
                                            match &&
                                            match.url === "/vehicle-types"
                                        ) {
                                            return true;
                                        }
                                        return !!(
                                            location &&
                                            location.pathname &&
                                            (location.pathname.includes(
                                                "/vehicle-type"
                                            ) ||
                                                location.pathname.includes(
                                                    "/vehicle-types"
                                                ))
                                        );
                                    }}
                                    activeClassName={
                                        "btn btn-primary btn-block text-white"
                                    }
                                    className={`btn btn-white btn-block ${
                                        `${url}`.includes("/vehicle-types") ||
                                        `${url}`.includes("/vehicle-type")
                                            ? "text-white"
                                            : "text-dark"
                                    }`}
                                    style={{ margin: "10px 15px" }}
                                >
                                    <div className="d-flex justify-content-center align-content-end">
                                        <span className="material-icons">
                                            format_list_numbered_rtl
                                        </span>
                                        <span className="content-sidebar-buttons">
                                            Tipos de Vehículos
                                        </span>
                                    </div>
                                </NavLink>
                            </li>
                            <li className="nav-item d-flex justify-content-center align-content-center">
                                <NavLink
                                    onClick={navToggle}
                                    to="/tracking"
                                    activeClassName={
                                        "btn btn-primary btn-block text-white"
                                    }
                                    className={`btn btn-white btn-block ${
                                        `${url}`.includes("/tracking")
                                            ? "text-white"
                                            : "text-dark"
                                    }`}
                                    style={{ margin: "10px 15px" }}
                                >
                                    <div className="d-flex justify-content-center align-content-end">
                                        <span className="material-icons">
                                            navigation
                                        </span>
                                        <span className="content-sidebar-buttons">
                                            Trackeo
                                        </span>
                                    </div>
                                </NavLink>
                            </li>
                            <li className="nav-item d-flex justify-content-center align-content-center">
                                <NavLink
                                    onClick={navToggle}
                                    to="/vehicles"
                                    activeClassName={
                                        "btn btn-primary btn-block text-white"
                                    }
                                    className={`btn btn-white btn-block ${
                                        `${url}`.includes("/vehicles")
                                            ? "text-white"
                                            : "text-dark"
                                    }`}
                                    style={{ margin: "10px 15px" }}
                                >
                                    <div className="d-flex justify-content-center align-content-end">
                                        <span className="material-icons">
                                            bus_alert
                                        </span>
                                        <span className="content-sidebar-buttons">
                                            Vehículos
                                        </span>
                                    </div>
                                </NavLink>
                            </li>
                            {(user.role === ROLE_ADMIN ||
                                user.role === ROLE_STATION_BOSS ||
                                user.role === ROLE_ADMIN_STATION) && (
                                <li className="nav-item d-flex justify-content-center align-content-center">
                                    <NavLink
                                        onClick={navToggle}
                                        to="/qr_scan"
                                        activeClassName={
                                            "btn btn-primary btn-block text-white"
                                        }
                                        className={`btn btn-white btn-block ${
                                            `${url}`.includes("/qr_scan")
                                                ? "text-white"
                                                : "text-dark"
                                        }`}
                                        style={{ margin: "10px 15px" }}
                                    >
                                        <div className="d-flex justify-content-center align-content-end">
                                            <img
                                                className="qr-material-icons"
                                                src={
                                                    url.includes("/qr_scan")
                                                        ? qr_code_white
                                                        : qr_code
                                                }
                                            />
                                            <span className="content-sidebar-buttons">
                                                Escaneo de QR
                                            </span>
                                        </div>
                                    </NavLink>
                                </li>
                            )}

                            {(user.role === ROLE_ADMIN ||
                                user.role === ROLE_CABIN_ATTENDANT ||
                                user.role === ROLE_ADMIN_STATION ||
                                user.role === HUMAN_RESOURCES) && (
                                <React.Fragment>
                                    <li className="dropdown-btn-reporting nav-item d-flex">
                                        <button
                                            // onClick={navToggle}
                                            className={`btn btn-white btn-block`}
                                            style={styleDropReport}
                                        >
                                            <div className="d-flex justify-content-center align-content-end">
                                                <span className="material-icons">
                                                    folder_open
                                                </span>
                                                <span className="content-sidebar-buttons">
                                                    Reporteria
                                                </span>
                                                <span className="material-icons ml-2">
                                                    expand_more
                                                </span>
                                            </div>
                                        </button>
                                    </li>
                                    <div className="dropdown-container">
                                        {(user.role === ROLE_ADMIN ||
                                            user.role === HUMAN_RESOURCES) && (
                                            <Link
                                                onClick={navToggle}
                                                className={`mb-2 dropdown-show ${
                                                    `${url}`.includes(
                                                        "/report/human_resources_report"
                                                    )
                                                        ? "active-a"
                                                        : ""
                                                }`}
                                                to="/report/human_resources_report"
                                            >
                                                Recursos Humanos
                                            </Link>
                                        )}

                                        {(user.role === ROLE_ADMIN ||
                                            user.role ===
                                                ROLE_CABIN_ATTENDANT ||
                                            user.role ===
                                                ROLE_ADMIN_STATION) && (
                                            <React.Fragment>
                                                <Link
                                                    onClick={navToggle}
                                                    className={`mb-2 dropdown-show ${
                                                        `${url}`.includes(
                                                            "/report/emergency_report"
                                                        )
                                                            ? "active-a"
                                                            : ""
                                                    }`}
                                                    to="/report/emergency_report"
                                                >
                                                    Emergencias
                                                </Link>
                                                <Link
                                                    onClick={navToggle}
                                                    className={`mb-2 dropdown-show ${
                                                        `${url}`.includes(
                                                            "/report/emergency_detail_report"
                                                        )
                                                            ? "active-a"
                                                            : ""
                                                    }`}
                                                    to="/report/emergency_detail_report"
                                                >
                                                    Reporte Detallado de
                                                    Emergencias
                                                </Link>
                                                <Link
                                                    onClick={navToggle}
                                                    className={`dropdown-show ${
                                                        `${url}`.includes(
                                                            "/report/station_report"
                                                        )
                                                            ? "active-a"
                                                            : ""
                                                    }`}
                                                    to="/report/station_report"
                                                >
                                                    Estaciones
                                                </Link>
                                            </React.Fragment>
                                        )}
                                    </div>
                                </React.Fragment>
                            )}

                            {user.role === 1 && (
                                <li className="nav-item d-flex justify-content-center align-content-center">
                                    <NavLink
                                        onClick={navToggle}
                                        to="/binnacle"
                                        activeClassName={
                                            "btn btn-primary btn-block text-white"
                                        }
                                        className={`btn btn-white btn-block ${
                                            `${url}`.includes("/binnacle")
                                                ? "text-white"
                                                : "text-dark"
                                        }`}
                                        style={{ margin: "10px 15px" }}
                                    >
                                        <div className="d-flex justify-content-center align-content-end">
                                            <span className="material-icons">
                                                history
                                            </span>
                                            <span className="content-sidebar-buttons">
                                                Bitácora
                                            </span>
                                        </div>
                                    </NavLink>
                                </li>
                            )}
                            {(user.role === 1 || user.role === 11) && (
                                <React.Fragment>
                                    <li className="dropdown-btn nav-item d-flex">
                                        <button
                                            // onClick={navToggle}
                                            className={`btn btn-white btn-block`}
                                            style={styleDrop}
                                        >
                                            <div className="d-flex justify-content-center align-content-end">
                                                <span className="material-icons">
                                                    group_add
                                                </span>
                                                <span className="content-sidebar-buttons">
                                                    Recursos Humanos
                                                </span>
                                                <span className="material-icons ml-2">
                                                    expand_more
                                                </span>
                                            </div>
                                        </button>
                                    </li>
                                    <div className="dropdown-container">
                                        <Link
                                            onClick={navToggle}
                                            className={`mb-2 dropdown-show ${
                                                `${url}`.includes(
                                                    "/human_resources/job_management"
                                                )
                                                    ? "active-a"
                                                    : ""
                                            }`}
                                            to="/human_resources/job_management"
                                        >
                                            Gestion De Puestos
                                        </Link>
                                        <Link
                                            onClick={navToggle}
                                            className={`mb-2 dropdown-show ${
                                                `${url}`.includes(
                                                    "/human_resources/control_hours"
                                                )
                                                    ? "active-a"
                                                    : ""
                                            }`}
                                            to="/human_resources/control_hours"
                                        >
                                            Control De Horas
                                        </Link>
                                        <Link
                                            onClick={navToggle}
                                            className={`dropdown-show ${
                                                `${url}`.includes(
                                                    "/human_resources/unsubscribe_discharge"
                                                )
                                                    ? "active-a"
                                                    : ""
                                            }`}
                                            to="/human_resources/unsubscribe_discharge"
                                        >
                                            Dar De Baja / Alta
                                        </Link>
                                    </div>
                                </React.Fragment>
                            )}
                        </ul>
                    </div>
                    <div
                        className="btn-white border"
                        style={{ margin: "10px 15px" }}
                    >
                        <Dropdown
                            direction="up"
                            isOpen={dropdownUserOpen}
                            toggle={() =>
                                setDropdownUserOpen(!dropdownUserOpen)
                            }
                        >
                            <DropdownToggle
                                color={"transparent"}
                                className="w-100"
                            >
                                <div className="d-flex flex-wrap">
                                    <div className="col-3">
                                        <div className="rounded-circle bg-white mr-1 align-middle d-inline-flex">
                                            <Avatar
                                                round
                                                size="35"
                                                name="Foo Bar"
                                                src={
                                                    user.photo
                                                        ? user.photo
                                                        : defaultAvatar
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-9 d-flex flex-column align-items-center justify-content-center">
                                        <span className="font-weight-bold">
                                            {user.first_name} {user.last_name}
                                        </span>
                                        <span className="text-primary font-weight-bold">
                                            {user.name_role}
                                        </span>
                                    </div>
                                </div>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem tag="a" href="#/user-profile">
                                    <div
                                        onClick={navToggle}
                                        className="flex row mx-auto align-items-center py-0"
                                    >
                                        <i className="material-icons"></i>
                                        <p className="mb-0 mt-2">Perfil</p>
                                    </div>
                                </DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem tag="a" href="/" onClick={logOut}>
                                    <div className="flex row align-items-center py-0">
                                        <i className="material-icons text-danger">
                                            
                                        </i>
                                        <p className="mb-0 pt-1">
                                            Cerrar sesión
                                        </p>
                                    </div>
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
            </aside>
        </React.Fragment>
    );
};

export default SideBar;
