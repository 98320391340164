import React from 'react';
import {Link} from "react-router-dom";
import {Field, reduxForm} from 'redux-form';
import {renderField} from '../../Utils/renderField';
import {validate, validators} from 'validate-redux-form';
import { validateWhiteSpace } from '../../../../utility/validation';

const Form = (props) => {
    const {handleSubmit, isUpdate, isView} = props;

    return (
        <form name="loginForm" onSubmit={handleSubmit}>
            <div className="d-flex flex-wrap">
                <div className="col-12 col-md-6 form-group">
                    <label htmlFor="first_name">Nombre</label>
                    <Field
                        type="text"
                        name="name"
                        label="name"
                        disabled={isView}
                        component={renderField}
                        validate={validateWhiteSpace}
                        className="form-control"/>
                </div>
                <div className="col-12 col-md-6 form-group">
                    <label htmlFor="first_name">Nomenclatura</label>
                    <Field
                        type="text"
                        name="nomenclature"
                        label="nomenclature"
                        disabled={isView}
                        component={renderField}
                        validate={validateWhiteSpace}
                        className="form-control"/>
                </div>
                <div className="col-12 d-flex flex-column flex-md-row justify-content-center">
                    <div className="flex-3"></div>
                    <div className='flex-1 d-flex flex-column flex-md-row justify-content-center'>
                        <Link
                            to={'/vehicle-types'}
                            className={isView ? 'btn btn-secondary px-3 py-3 mt-3 mb-3 w-100' : 'btn btn-danger py-3 mt-3 mb-3 w-100'}
                        >
                            {isView ? 'Atras' : 'Cancelar'}
                        </Link>
                        {
                            !isView &&
                            <button
                                type="submit"
                                className="btn btn-success align-self-center py-3 m-3 w-100">
                                {isUpdate ? "Actualizar" : "Crear"}
                            </button>
                        }
                    </div>
                </div>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'VehicleTypeForm', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            name: validators.exists()('Este campo es requerido'),
            nomenclature: validators.exists()('Este campo es requerido'),
        });
    },
})(Form);
