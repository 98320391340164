import {api} from "api";
import {handleActions} from 'redux-actions';
import {createReducer} from "../baseReducer/baseReducer";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import {change} from "redux-form";


// ------------------------------------
// Constants
// ------------------------------------
const SET_EMERGENCIES_LOCATIONS  = 'SET_EMERGENCIES_LOCATIONS';
const SET_STATUS_TRACKING  = 'SET_STATUS_TRACKING';

const setFilterTracking = statusTracking => ({
    type: SET_STATUS_TRACKING,
    statusTracking,
});

const baseReducer = createReducer(
    "tracking_vehicle"
);

// ------------------------------------
// Pure Actions
// ------------------------------------

const getEmergencies = () => (dispatch, getStore) => {
    //TODO: Obtener las localizaciones de las emergencias
}

const getVehiclesonTracking = () => (dispatch, getStore) => {
    const params = { };
    const resource = getStore()["tracking"];

    params.status = resource.statusTracking
    params.stations = getStore()["login"]["me"]["station"] ? getStore()["login"]["me"]["station"]["id"] : '';
    dispatch(baseReducer.actions.setLoader(true));
    api.get("vehicle/get_vehicles_on_tracking", params)
        .then((response) => {
            dispatch(baseReducer.actions.setData({response}));
        })
        .catch(() => {})
        .finally(() => {
            dispatch(baseReducer.actions.setLoader(false));
        });
};

const filter_by_status = (option) => (dispatch) => {
    dispatch(setFilterTracking(option?option:'' ));
    dispatch(getVehiclesonTracking());
};

export const actions = {
    getEmergencies,
    filter_by_status,
    getVehiclesonTracking,
    ...baseReducer.actions,
};


export const reducers = {
    ...baseReducer.reducers,
    [SET_EMERGENCIES_LOCATIONS]: (state, { emergenciesLocations }) => ({ ...state, emergenciesLocations }),
    [SET_STATUS_TRACKING]: (state, { statusTracking }) => ({ ...state, statusTracking }),
};

export const initialState = {
    emergenciesLocations: undefined,
    statusTracking: '',
    ...baseReducer.initialState,
};


export default handleActions(reducers, initialState);
