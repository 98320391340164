import React, { Component } from "react";
import ButtonDropdownList from "../renderField/ButtonDropdownList";

const Title = (props) => {
    const {
        title,
        subtitle,
        showCreate,
        linkToRedirect,
        titleCreate = "Crear",
        textWhite,
        downoaldExcel = {},
        total = {},
    } = props;
    return (
        <div className="row w-100 justify-content-between align-items-center mb-2">
            <div className="col-sm-12 col-md-8 col-lg-9">
                <h2
                    className={`${
                        textWhite ? "text-white" : "title-blue"
                    } font-weight-bold`}
                >
                    {title}
                </h2>
                {!!subtitle && (
                    <h5 className={textWhite ? "text-white" : "subtitle-gray"}>
                        {subtitle}
                    </h5>
                )}
            </div>

            <div>
                {!!showCreate && (
                    <div className="align-self-end">
                        <a
                            className="btn btn-primary py-3 px-4 mr-0 shadow font-weight-bold"
                            href={linkToRedirect}
                        >
                            {titleCreate}
                        </a>
                    </div>
                )}
            </div>
            {(Object.entries(total).length !== 0 ||
                Object.entries(downoaldExcel).length !== 0) && (
                <div>
                    {Object.entries(total).length !== 0 && (
                        <React.Fragment>
                            <h6 className="title-blue font-weight-bold m-0">
                                {total.title}
                            </h6>
                            <h4 className="font-weight-bold m-0 mb-2">
                                {total.total} {total.subtitle}
                            </h4>
                        </React.Fragment>
                    )}
                    {/* {title:'Total de emergencias', subtitle: 'Emergencias', total:2, } */}
                    {Object.entries(downoaldExcel).length !== 0 && (
                        <div className="align-self-end">
                            {downoaldExcel.isMulti ? 
                            <ButtonDropdownList state={downoaldExcel.state} list={downoaldExcel.list} title={downoaldExcel.title}/>
                            :
                            <button
                                onClick={() => downoaldExcel.onClick()}
                                className="btn btn-secondary py-3 px-4 mr-0 shadow font-weight-bold"
                                disabled={
                                    downoaldExcel.disabled
                                        ? downoaldExcel.disabled
                                        : false
                                }
                            >
                                {downoaldExcel.title}
                            </button>
                            }
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Title;
