import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { actions } from "../../../../../redux/modules/users";
import Title from "../../../Utils/Headers/Title";
import Form from "../Form/Form";
import Swal from "sweetalert2";
import LoadMask from "../../../Utils/LoadMask/LoadMask";

const Unsuscribe = (props) => {
    const [acceptanceFile, setAcceptanceFile] = useState(null);
    useEffect(() => {
        props.leer(props.match.params.id);
        return () => {
            props.setItem({});
        };
    }, []);

    const { updateUnsubscribe, loader } = props;

    const update = (data) => {
        if (acceptanceFile === null || acceptanceFile === "") {
            Swal.fire("¡Atención!", "Debe de subir un archivo.", "warning");
            return;
        }
        data.station = data.station.id;
        updateUnsubscribe(
            props.match.params.id,
            { ...data, register_file: null },
            [{ file: acceptanceFile, name: "register_file" }]
        );
    };
    return (
        <div className="py-4">
            <LoadMask loading={loader} blur>
            <div className="row">
                <div className="mb-4 col-lg-12">
                    <div className="mb-4 card card-small">
                        <div className="px-3 pt-4">
                            <Title
                                title={"Dar De Baja - Alta"}
                                subtitle={"Dar de Baja"}
                            />
                        </div>
                        <Form
                            onSubmit={update}
                            item={props.item}
                            unsuscribe={true}
                            setAvatar={setAcceptanceFile}
                        />
                    </div>
                </div>
            </div>
            </LoadMask>
        </div>
    );
};

const mstp = (state) => {
    return {
        me: state.login.me,
        ...state.users,
    };
};

const mdtp = { ...actions };

export default connect(mstp, mdtp)(Unsuscribe);
