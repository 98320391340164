import React, { Component } from "react";
import Grid from "../../Utils/Grid";
import { SelectField } from "../../Utils/renderField/renderField";
import { Field, reduxForm } from "redux-form";
import Title from "../../Utils/Headers/Title";
import { standardActions } from "../../Utils/Grid/StandardActions";
import {ROLE_ADMIN, ROLE_ADMIN_STATION, STATION_STATUS_IN_LIST, REGION_TYPE_IN_LIST} from "../../../../utility/constants";
import OrderSearchComponent from "../../Utils/OrderSearch/orderSearch";
import Filters from "../../Utils/Filters/Filters";

class ListStation extends Component {
    componentDidMount() {
        this.props.customListStation();
        this.props.changeValueSelectStationStatus();
    }

    render() {
        const {
            customListStation,
            data,
            loader,
            onSortChange,
            eliminar_ :eliminar,
            filterByStatus,
            filterByRegion,
            region,
        } = this.props;

        const columns = [
            {
                dataField: "no_station",
                text: "ID",
            },
            {
                dataField: "name",
                text: "Nombre",
            },
            {
                dataField: "address",
                text: "Dirección",
            },
            {
                dataField: "phone_number",
                text: "Teléfono",
            },
            {
                dataField: "state_text",
                text: "Estado",
                formatter: (cell, row) => {
                    let color = "point-success";
                    if (row.state == 10) {
                        color = "point-green";
                    } else if (row.state == 20) {
                        color = "point-orange";
                    } else if (row.state == 30) {
                        color = "point-orange-2";
                    } else if (row.state == 40) {
                        color = "point-red";
                    }
                    return (
                        <div className="d-flex">
                            <span className={"material-icons " + color}>
                                fiber_manual_record
                            </span>
                            <span className=""> {cell} </span>
                        </div>
                    );
                },
            },
            {
                dataField: "id",
                text: "Acciones",
                style: {width: '200px'},
                // headerClasses: "text-center center",
                formatter: (cell, row) => (this.props.me && (this.props.me.role === ROLE_ADMIN))?
                    standardActions({
                        ver: "/stations",
                        editar: "/stations",
                        eliminar,
                    })(cell, row):
                    standardActions({
                        ver: "/stations",
                    })(cell, row),
            },
        ];

        return (
            <div className="py-4">
                <div className="row">
                    <div className="mb-4 col-lg-12">
                        <Title
                            showCreate={(this.props.me && (this.props.me.role === ROLE_ADMIN))}
                            title={"Estaciones"}
                            linkToRedirect={"#/stations/create"}
                            titleCreate={"+ Crear Estación"}
                            subtitle={"Listado general de estaciones"}
                        />
                        <div className="mb-4 card card-small">
                            <div className="row mx-auto w-100 mt-2 justify-content-between">
                                <div className="col-lg-2 col-md-5 col-sm-12 mb-2">
                                    <Field
                                        name="status"
                                        placeholder={"Estado"}
                                        component={SelectField}
                                        customChange={filterByStatus}
                                        options={STATION_STATUS_IN_LIST}
                                    />
                                </div>

                                <div className="col-lg-2 col-md-5 col-sm-12 mb-2">
                                    <Filters
                                        region={{
                                            show: true,
                                            region: region,
                                            filterByRegion: filterByRegion,
                                            classNameFilter: "",
                                        }}
                                    />
                                </div>

                                <OrderSearchComponent
                                    customClassSearch='w-100 justify-content-end'
                                    customClass='justify-content-end w-100 col-lg-5 col-sm-12 col-md-6'
                                    searchChange={this.props.searchCustomChange}
                                />
                            </div>
                            <div className="p-0 px-3 pt-3">
                                <Grid
                                    data={data}
                                    loading={loader}
                                    columns={columns}
                                    hover={true}
                                    onPageChange={customListStation}
                                    onSortChange={onSortChange}
                                    noDataMessage={"Sin Estaciones"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default reduxForm({
    form: "ListStation", // a unique identifier for this form
})(ListStation);
