
import { actions } from '../../../redux/modules/tracking';
import { connect } from 'react-redux';
import Tracking from './tracking'

const mstp = state => ({
    ...state.tracking,
    me: state.login.me,
})

const mdtp = {
    ...actions
}

export default connect(mstp, mdtp)(Tracking);