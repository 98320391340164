import { connect } from "react-redux";
import { actions } from "../../../../redux/modules/reports/emergency_report_detail";
import List from "./List";

const ms2p = (state) => {
    return {
        ...state.emergency_report_detail,
        me: state.login.me,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(List);
