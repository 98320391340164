import { api } from "api";
import { handleActions } from "redux-actions";
import { createReducer } from "../baseReducer/baseReducer";
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
import { change } from "redux-form";

const baseReducer = createReducer("users", "user", "userForm", "/users");

// ------------------------------------
// Constants
// ------------------------------------
const SET_OPTION_STATION_USER = "SET_OPTION_STATION_USER";
const SET_FIREFIGHTERS = "SET_FIREFIGHTERS";
const SET_PILOT = "SET_PILOT";
const SET_OPTION_ROLE_USER = "SET_OPTION_ROLE_USER";

// ------------------------------------
// Pure Actions
// ------------------------------------

const setOptionsStation = (station) => ({
    type: SET_OPTION_STATION_USER,
    station,
});

const setFirefighters = (firefighters) => ({
    type: SET_FIREFIGHTERS,
    firefighters,
});

const setPilot = (pilot) => ({
    type: SET_PILOT,
    pilot,
});

const setOptionsRole = (role) => ({
    type: SET_OPTION_ROLE_USER,
    role,
});

const customUserList =
    (page = 1) =>
    (dispatch, getStore) => {
        const resource = getStore()["users"];
        let station = resource.station;
        let role = resource.role;
        const params = { page };
        params.ordering = resource.ordering;
        params.search = resource.search;
        params.station_id = station;
        params.role = role;
        dispatch(baseReducer.actions.setLoader(true));
        api.get("user", params)
            .then((response) => {
                dispatch(baseReducer.actions.setData(response));
                dispatch(baseReducer.actions.setPage(page));
            })
            .catch((err) => {
                console.log("Error:", err);
            })
            .finally(() => {
                dispatch(baseReducer.actions.setLoader(false));
            });
    };

const updateDischarge = (id, data = {}, attachments = []) => (dispatch, getStore) => {
    dispatch(baseReducer.actions.setLoader(true));
    api.putAttachments(`/job_management/${id}/updateDischarge/`, data, attachments).then((response) => {
        NotificationManager.success('Datos actualizados', 'Exito', 1000);
        dispatch(push('/human_resources/unsubscribe_discharge'));
    }).catch((errr) => {
        console.log('THE ERROR IS: ', errr)
        NotificationManager.error('Error', 'ERROR', 0);
    }).finally(() => {
        dispatch(baseReducer.actions.setLoader(false));
    });
};

const updateUnsubscribe = (id, data = {}, attachments = []) => (dispatch, getStore) => {
    dispatch(baseReducer.actions.setLoader(true));
    api.putAttachments(`/job_management/${id}/updateUnsubscribe/`, data, attachments).then((response) => {
        NotificationManager.success('Datos actualizados', 'Exito', 1000);
        dispatch(push('/human_resources/unsubscribe_discharge'));
    }).catch((errr) => {
        console.log('THE ERROR IS: ', errr)
        NotificationManager.error(errr.detail ? errr.detail : 'Error', 'ERROR', 0);
    }).finally(() => {
        dispatch(baseReducer.actions.setLoader(false));
    });
};

const listarBomberos = (stations) => (dispatch, getStore) => {
    const params = { stations };
    dispatch(baseReducer.actions.setLoader(true));
    api.get("user/get_firefighters", params)
        .then((response) => {
            dispatch(setFirefighters(response));
        })
        .catch(() => {})
        .finally(() => {
            dispatch(baseReducer.actions.setLoader(false));
        });

    api.get("user/get_pilot", params)
        .then((response) => {
            dispatch(setPilot(response));
        })
        .catch(() => {})
        .finally(() => {
            dispatch(baseReducer.actions.setLoader(false));
        });

};

const limpiarBomberos = () => (dispatch) => {
    dispatch(setFirefighters([]));
    dispatch(setPilot([]));
}

const validateUserCreated = (id) => (dispatch) => {
    api.post('user/validate_user_created', {id})
        .then((response) => {
            NotificationManager.success('Usuario validado', 'Exito', 1000);
            dispatch(push('/users'));
        })
        .catch((err) => {console.log('error', err)})
        .finally(() => {
            console.log('final')
        });
};

const filterByStation = (value)=>(dispatch, getStore)=>{
    if (value===null){
        value = ''
    }
    dispatch(setOptionsStation(value))
    dispatch(customUserList())
}

const filterByRole = (value) => (dispatch, getStore) => {
    if (value === null) {
        value = "";
    }
    dispatch(setOptionsRole(value));
    dispatch(customUserList());
};

const updateAttachment = (id, data = {}, attachments=[]) => (dispatch, getStore) => {
    dispatch(baseReducer.actions.setLoader(true));
    api.putAttachments(`${'user'}/${id}`, data, attachments).then((response) => {
        NotificationManager.success('Datos actualizados', 'Exito', 1000);
        dispatch(push('/users'));
    }).catch((errr) => {
        console.log('THE ERROR IS: ', errr)
        NotificationManager.error('Credenciales incorrectas, vuelva a intentar', 'ERROR', 0);
    }).finally(() => {
        dispatch(baseReducer.actions.setLoader(false));
    });
};


const createAttachment = ( data = {}, attachments=[]) => (dispatch, getStore) => {
    dispatch(baseReducer.actions.setLoader(true));
    api.postAttachments(`user`, data, attachments).then((response) => {
        NotificationManager.success('Registro creado', 'Exito', 1000);
        dispatch(push('/users'));
    }).catch(() => {
        NotificationManager.error('Credenciales incorrectas, vuelva a intentar', 'ERROR', 0);
    }).finally(() => {
        dispatch(baseReducer.actions.setLoader(false));
    });
};

const changeValueSelectUserStation = () => (dispatch, getStore) => {
    const resource = getStore()["users"];
    dispatch(change("ListUsers", "station", resource.station));
};

const searchCustomChange = (search) => (dispatch) => {
    dispatch(baseReducer.actions.setSearch(search));
    dispatch(customUserList());
};

export const actions = {
    filterByRole,
    customUserList,
    filterByStation,
    updateAttachment,
    createAttachment,
    searchCustomChange,
    validateUserCreated,
    listarBomberos,
    limpiarBomberos,
    updateDischarge,
    updateUnsubscribe,
    ...baseReducer.actions,
    changeValueSelectUserStation,
};

export const reducers = {
    ...baseReducer.reducers,
    [SET_OPTION_STATION_USER]: (state, { station }) => {
        return {
            ...state,
            station,
        };
    },
    [SET_FIREFIGHTERS]: (state, { firefighters }) => {
        return {
            ...state,
            firefighters,
        };
    },

    [SET_PILOT]: (state, { pilot }) => {
        return {
            ...state,
            pilot,
        };
    },

    [SET_OPTION_ROLE_USER]: (state, { role }) => {
        return {
            ...state,
            role,
        };
    },
};

export const initialState = {
    station: "",
    firefighters: [],
    pilot: [],
    role: "",
    ...baseReducer.initialState,
};

export default handleActions(reducers, initialState);
