import React, { Component } from "react";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import LoadMask from "../LoadMask/LoadMask";

export default class Grid extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Mostrando {to} de {size} resultados
        </span>
    );

    static defaultProps = {
        loading: false,
    };

    render() {
        const {
            loading,
            data = {
                results: [],
                count: 0,
            },
            page,
            hover = false,
            remote = true,
            expandableRow,
            expandComponent,
            cellEditProp,
            afterSave,
            onPageChange,
            onSortChange,
            pagination,
            expanding,
            onExpand,
            columns,
            sizePerPage = 10,
            trClassName,
            showPagination = true,
            rowClass = () => {
                return "";
            },
            ...other
        } = this.props;

        const paginateFactory = showPagination
            ? {
                  showTotal: true,
                  hideSizePerPage: true,
                  hidePageListOnlyOnePage: true,
                  sizePerPage: sizePerPage,
                  totalSize: loading ? 0 : data.count,
                  page: page,
                  paginationTotalRenderer: this.customTotal,
                  sizePerPageRenderer: () => {},
                  onPageChange: (page, sizePerPage) => onPageChange(page),
              }
            : {
                  custom: true,
              };

        return (
            <div>
                <LoadMask loading={loading} dark blur>
                    <div>
                        <BootstrapTable
                            keyField="id"
                            bodyClasses={"w-100"}
                            data={loading ? [] : data.results}
                            columns={columns}
                            hover={hover}
                            rowClasses={rowClass}
                            headerWrapperClasses={"border-rounder"}
                            headerClasses={"header-table-blue"}
                            remote={remote}
                            trClassName={trClassName}
                            onTableChange={() => {}}
                            pagination={paginationFactory(paginateFactory)}
                            wrapperClasses="table-responsive-lg table-responsive-sm table-responsive-md"
                            {...other}
                        />
                    </div>
                </LoadMask>
            </div>
        );
    }
}
