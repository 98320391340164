import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Filters from "../../../Utils/Filters/Filters";
import Title from "../../../Utils/Headers/Title";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import Container from "./Container";

const Detail = (props) => {
    const {
        loader,
        userData,
        log_history_hours,
        start_date_user,
        end_date_user,
        setStartDateUser,
        setEndDateUser,
        setClerFilters,
    } = props;

    useEffect(() => {
        userData(props.match.params.id);
        log_history_hours();
    }, []);

    useEffect(() => {
        return () => {
            setClerFilters();
        }
      }, [])

    return (
        <div className="py-4">
            <LoadMask loading={loader} blur>
                <div className="row">
                    <div className="mb-4 col-lg-12">
                        <div className="mb-4 card card-small">
                            <div className="px-3 pt-4">
                                <Title
                                    title={"Control De Horas"}
                                    subtitle={"Detalle"}
                                />
                                <div className="d-flex flex-column flex-md-row justify-content-end">
                                    <Filters
                                        startDate={{
                                            show: true,
                                            start_date: start_date_user,
                                            setStartDate: setStartDateUser,
                                            classNameFilter:
                                                "w-90 mr-md-3 w-md-25 mb-3",
                                        }}
                                        endDate={{
                                            show: true,
                                            end_date: end_date_user,
                                            setEndDate: setEndDateUser,
                                            classNameFilter: "w-90 w-md-25",
                                        }}
                                    />
                                </div>
                            </div>
                            <Container
                                data={
                                    props.user_information &&
                                    props.user_information
                                }
                                list={props.log_history && props.log_history}
                                log_history_hours={log_history_hours}
                            />
                            <div className="col-12 d-flex flex-column flex-md-row justify-content-center">
                                <div className="flex-3"></div>
                                <div className="flex-1 d-flex flex-column flex-md-row justify-content-center">
                                    <Link
                                        to={"/human_resources/control_hours"}
                                        className="btn btn-secondary px-3 py-3 mt-3 mb-3 w-100"
                                    >
                                        Atras
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LoadMask>
        </div>
    );
};

export default Detail;
