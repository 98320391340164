import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/qr/index';
import QrView from './QrView';

const ms2p = (state) => {
    return {
        ...state.qr_users,
    }
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(QrView);