import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/emergencies/emergencies';
import { actions as emergency_type } from '../../../../redux/modules/emergencyTypes/emergencyTypes';
import { actions as stations } from '../../../../redux/modules/station/station';
import { actions as firefighters } from '../../../../redux/modules/users/index';
import { actions as vehicles } from '../../../../redux/modules/vehicles/vehicles';
import Container from './Container';


const ms2p = (state) => {
    return {
        ...state.emergencies,
        emergencyTypes: { ...state.emergencyTypes },
        stations: { ...state.station },
        firefighters: { ...state.users },
        vehicles: { ...state.vehicles },
        me: state.login.me,
    };
};

const md2p = { ...actions, cleanVehiclesList: vehicles.limpiarVehiculosEstacion, vehiclesList: vehicles.listarVehiculosEstacion, firefightersList: firefighters.listarBomberos, cleanFirefightersList: firefighters.limpiarBomberos, emergencyTypesFilterList: emergency_type.filterList, stationFilterList: stations.filterList };

export default connect(ms2p, md2p)(Container);
