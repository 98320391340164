import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { logOut, getMe } from "./redux/modules/cuenta/login";

// maquetado base
import SiderBar from "./common/components/layout/Sidebar/SideBar";
import Footer from "./common/components/layout/Footer/Footer";

import Navbar from "./common/components/layout/Navbar/Navbar";
import { VerifyLogin } from "./common/components/layout";

class PrivateRouteBase extends Component {
    constructor(props) {
        super(props);

        this.state = {
            burgerMenu: true,
            toggleOpen: true,
            showButtons: false,
        };
    }

    componentDidMount() {
        if (window.screen.width <= 767) {
            this.setState({ showButtons: true });
        }
        window.addEventListener("resize", (e) => {
            if (e.currentTarget.screen.width <= 767) {
                this.setState({ showButtons: true });
            } else {
                this.setState({ showButtons: false });
            }
        });
    }

    navToggle = () => {
        this.setState({ toggleOpen: !this.state.toggleOpen });
    };

    navMenu = () => {
        this.setState({ burgerMenu: !this.state.burgerMenu });
    };

    isAuthenticated = () => {
        const token = localStorage.getItem("token");
        const {
            getMe,
            login: { me },
        } = this.props;
        if (!!token && !!me.username) {
            return true;
        } else if (token) {
            getMe();
            return "Verifying";
        }
        return false;
    };

    render() {
        const {
            component: Component,
            logOut,
            login: { me },
            sidebar = true,
            ...rest
        } = this.props;
        const isAuthenticated = this.isAuthenticated();
        return (
            <Route
                {...rest}
                render={(props) =>
                    isAuthenticated ? (
                        isAuthenticated === true ? (
                            sidebar ? (
                                <div className="w-100">
                                    <React.Fragment>
                                        <SiderBar
                                            url={this.props.location.pathname}
                                            toggleOpen={this.state.toggleOpen}
                                            burgerMenu={this.state.burgerMenu}
                                            navMenu={this.navMenu}
                                            navToggle={this.navToggle}
                                            showButtons={this.state.showButtons}
                                            logOut={logOut}
                                            user={me}
                                        />

                                        <main
                                            className={
                                                this.state.burgerMenu &&
                                                "main-content p-0 col-sm-12 col-md-9 offset-md-3 col-lg-10 offset-lg-2 bg-white"
                                            }
                                        >
                                            <Navbar
                                                navMenu={this.navMenu}
                                                burgerMenu={
                                                    this.state.burgerMenu
                                                }
                                                navToggle={this.navToggle}
                                                toggleOpen={
                                                    this.state.toggleOpen
                                                }
                                                logOut={logOut}
                                                user={me}
                                                showButtons={
                                                    this.state.showButtons
                                                }
                                            />
                                            <div className="main-content-container px-4 container-fluid">
                                                <Component {...props} />
                                            </div>
                                        </main>
                                    </React.Fragment>
                                </div>
                            ) : (
                                <div className="background-qr">
                                    <Component {...props} />
                                </div>
                            )
                        ) : (
                            <VerifyLogin />
                        )
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: props.location },
                            }}
                        />
                    )
                }
            />
        );
    }
}

const mstp = (state) => ({ ...state });

const mdtp = { logOut, getMe };

const ProtectedRoute = connect(mstp, mdtp)(PrivateRouteBase);

export default ProtectedRoute;
