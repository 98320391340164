import React, { useState, useEffect } from "react";
import Form from "./Form";
import Title from "../../Utils/Headers/Title";
import LoadMask from "../../Utils/LoadMask/LoadMask";

const Container = (props) => {
    const [isUpdate, setIsUpdate] = useState(false);
    const [isView, setIsView] = useState(false);
    const { crear, editar } = props;

    useEffect(() => {
        if (
            props.match.path === "/vehicle-type/:id/editar" &&
            props.match.params.id
        ) {
            setIsUpdate(true);
            props.leer(props.match.params.id);
        } else if (
            props.match.path === "/vehicle-type/:id" &&
            props.match.params.id
        ) {
            setIsView(true);
            props.leer(props.match.params.id);
        }
    }, []);

    return (
        <div className='card pb-3 mb-5'>
            <div className="px-4 pt-4 container-title-create">
                <Title
                    textWhite={true}
                    title={"Tipos de vehículo"}
                    subtitle={
                        isView
                            ? "Detalle"
                            : isUpdate
                            ? "Actualizar"
                            : "Crear tipo de vehículo"
                    }
                    showCreate={false}
                />
            </div>
            <div className="p-0 px-3 pt-3">
                <LoadMask loading={props.loader} blur>
                    <Form
                        isUpdate={isUpdate}
                        isView={isView}
                        onSubmit={
                            isUpdate
                                ? (data) => editar(props.match.params.id, data)
                                : crear
                        }
                    />
                </LoadMask>
            </div>
        </div>
    );
};

export default Container;
