import React, {Component, Fragment} from "react";
import Grid from "../../Utils/Grid";
import { reduxForm } from "redux-form";
import Title from "../../Utils/Headers/Title";
import {HUMAN_RESOURCES, ROLE_ADMIN, ROLE_ADMIN_STATION} from "../../../../utility/constants";
import { standardActions } from "../../Utils/Grid/StandardActions";
import OrderSearchComponent from "../../Utils/OrderSearch/orderSearch";

class List extends Component {
    componentDidMount() {
        this.props.customUserList();
        this.props.stationFilterList(true);
        this.props.changeValueSelectUserStation();
    }

    render() {
        const {
            customUserList,
            me,
            data,
            eliminar,
            loader,
            filterByRole,
            onSortChange,
            filterByStation,
            searchCustomChange,
        } = this.props;

        const selects = [
            {
                title: "Estación",
                name: "station",
                data: this.props.station.filter_list,
                funcion: filterByStation,
            },
            {
                title: "Rol",
                name: "role",
                data:
                    [   {label:'Todos', value: ''},
                        {label: 'Administrador', value:1},
                        {label: 'Comandante', value:5},
                        {label: 'Jefe de Servicio', value:7},
                        {label: 'Cabinista', value:8},
                        {label: 'Bombero', value:10},
                        { label: "Recursos Humanos", value: 11 }
                    ],
                funcion: filterByRole,
            },
        ];

        const columns = [
            {
                dataField: "ids",
                text: "ID",
                headerClasses: "col-1",
                formatter: (cell, row) => {
                    return <span>#{row.id}</span>;
                },
            },
            {
                dataField: "first_name",
                text: "Nombre",
                formatter: (cell, row) => `${cell} ${row.last_name}`,
            },
            {
                dataField: "station",
                text: "Estacion",
                formatter: (cell) => (cell ? `${cell.name}` : ""),
            },
            {
                dataField: "address",
                text: "Dirección",
            },
            {
                dataField: "phone_number",
                headerClasses: "col-1",
                text: "Teléfono",
            },
            {
                dataField: "email",
                text: "Correo",
            },
            {
                dataField: "id",
                text: "Acciones",
                formatter: (cell, row) => (this.props.me && this.props.me.role === ROLE_ADMIN || this.props.me.role === HUMAN_RESOURCES)?
                    standardActions({
                        ver: "/users",
                        editar: "/users",
                        eliminar,
                    })(cell, row):
                    standardActions({
                        ver: "/users",
                    })(cell, row),
            },
        ];

        const rowClasses = (row, rowIndex) => {
            if( row.status === 1){
                return 'row-class-danger parpadea';
            }
            return '';
        };

        return (
            <div className="py-4">
                <div className="row">
                    <div className="mb-4 col-lg-12">
                        <div className="mb-4 card card-small">
                            <div className="px-3 pt-4">
                                <Title
                                    showCreate={(me.role === ROLE_ADMIN || me.role === ROLE_ADMIN_STATION || me.role === HUMAN_RESOURCES)?true:false}
                                    title={"Usuarios"}
                                    linkToRedirect={"/#/users/create"}
                                    titleCreate={"+ Crear Usuario"}
                                    subtitle={"Listado general de usuarios"}
                                />
                            </div>
                            <OrderSearchComponent
                                customClass='row mx-auto w-100 mt-2 justify-content-between'
                                searchChange={searchCustomChange}
                                selects={selects}
                            />
                            <div className="p-0 px-3 pt-3">
                                <Grid
                                    data={data}
                                    loading={loader}
                                    columns={columns}
                                    rowClass={rowClasses}
                                    onSortChange={onSortChange}
                                    onPageChange={customUserList}
                                    noDataMessage={"Sin Usuarios"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default reduxForm({
    form: "ListUsers",
})(List);
