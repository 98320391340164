import React, { useEffect } from "react";
import Select from "react-select";
import Async from "react-select/async";
import Creatable from "react-select/creatable";
import NumberFormat from "react-number-format";
import classNames from "classnames";
import Switch from "react-switch";
import DayPicker from "../DayPicker";
import FileUploader from "../FileUploader/FileUploader";
// import DatePicker from "react-date-picker";
import DatePicker from "react-datepicker";
import _ from "lodash";
import "react-datepicker/dist/react-datepicker.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Map from "../../Utils/Map";

export const renderField = ({
    input,
    placeholder,
    padding,
    disabled,
    type,
    maxLength,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <input
                {...input}
                maxLength={maxLength}
                style={{ color: "black" }}
                disabled={disabled}
                placeholder={placeholder}
                type={type}
                className={classNames(
                    "form-control",
                    { "is-invalid": invalid },
                    { "px-3": padding }
                )}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </React.Fragment>
    );
};

export const renderMap = ({
    input,
    height,
    width,
    disabled,
    mark_lat,
    mark_lng,
    meta: { error, touched },
}) => {
    const invalid = touched && error;

    useEffect(() => {
        if (mark_lat) {
            input.onChange({
                lat: mark_lat,
                lng: mark_lng,
            });
        }
    }, [mark_lat]);

    return (
        <React.Fragment>
            {disabled && (!mark_lat || !mark_lng) ? (
                <div className="">
                    <span> No hay una localizacion registrada </span>
                </div>
            ) : (
                <Map
                    mark_lat={mark_lat}
                    mark_lng={mark_lng}
                    disabled={disabled}
                    fromLat={14.813394}
                    fromLng={-91.4692999}
                    toLat={15.013394}
                    toLng={-91.2692999}
                    heigth={height}
                    width={width}
                    onChange={(e) => {
                        input.onChange(e);
                    }}
                />
            )}
            {invalid && (
                <div style={{ display: "block" }} className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    );
};

export const renderRichTextArea = ({
    input,
    text_value,
    meta: { error, touched },
}) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <CKEditor
                editor={ClassicEditor}
                data={text_value}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    input.onChange(data);
                }}
                className={classNames("ck-editor", {
                    "is-invalid": invalid,
                })}
            ></CKEditor>
            {invalid && (
                <div style={{ display: "block" }} className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    );
};

export const renderTextArea = ({
    input,
    placeholder,
    rows,
    disabled,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <div>
            <textarea
                {...input}
                placeholder={placeholder}
                style={{ resize: "none" }}
                rows={rows || 3}
                disabled={disabled}
                className={classNames("form-control", {
                    "is-invalid": invalid,
                })}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderNumber = ({
    disabled,
    input,
    decimalScale,
    placeholder,
    meta: { touched, error },
    prefix = "",
    suffix = "",
    numberFormat,
}) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                placeholder={placeholder}
                className={classNames("form-control", {
                    "is-invalid": invalid,
                })}
                style={{ color: "black" }}
                decimalScale={decimalScale || 0}
                format={numberFormat}
                disabled={disabled}
                fixedDecimalScale
                value={input.value}
                thousandSeparator
                prefix={prefix}
                suffix={suffix}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderDoc = ({ setDocument }) => {
    return (
        <div>
            <input
                type="file"
                onChange={(e) => setDocument(e.target.files[0])}
            />
            {/* {invalid && <div className="invalid-feedback">{error}</div>} */}
        </div>
    );
};

export const renderCurrency = ({
    input,
    meta: { touched, error },
    prefix = "Q ",
    placeholder,
}) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                className={classNames("form-control", {
                    "is-invalid": invalid,
                })}
                decimalScale={2}
                fixedDecimalScale
                placeholder={placeholder}
                value={input.value}
                thousandSeparator
                prefix={prefix}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderSwitch = ({
    input,
    meta: { touched, error },
    label,
    disabled,
    checked,
}) => {
    const invalid = touched && error;

    return (
        <div className="d-flex align-items-center">
            <Switch
                onColor="#007bff"
                height={18}
                width={36}
                disabled={disabled}
                onChange={(value) => {
                    input.onChange(value);
                }}
                checked={checked}
                // id="normal-switch"
            />
            &nbsp;{label}
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderFieldCheck = ({
    input,
    label,
    checked,
    disabled,
    type,
    meta: { touched, error },
}) => {
    input.checked = input.value;
    const invalid = touched && error;
    return (
        <React.Fragment>
            <div className="checkbox c-checkbox">
                <label className="needsclick">
                    <input
                        type="checkbox"
                        disabled={disabled}
                        {...input}
                        className={classNames("", { "is-invalid": invalid })}
                    />
                    <span className="fa fa-check" />
                    &nbsp;{label}
                </label>
            </div>
            {invalid && <div className="invalid-feedback">{error}</div>}
        </React.Fragment>
    );
};

export const renderFieldRadio = ({
    input,
    label,
    value,
    disabled,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <div className="radio c-radio c-radio-nofont d-flex">
                <label className="negro font-weight-normal">
                    <input
                        type="radio"
                        disabled={disabled}
                        {...input}
                        className={classNames("", { "is-invalid": invalid })}
                    />
                    <span />
                    &nbsp;{label}
                </label>
            </div>
            {invalid && <div className="invalid-feedback">{error}</div>}
        </React.Fragment>
    );
};

export const SelectField = ({
    input,
    disabled,
    isClearable,
    isMulti,
    isSearchable,
    options,
    placeholder,
    customOnChange,
    customChange,
    labelKey = "label",
    valueKey = "value",
    valueSelect,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    const _options = [];
    options.forEach((option) => {
        _options.push({
            ...option,
            label: option[labelKey],
            value: option[valueKey],
        });
    });
    let value = input.value;
    if (
        value !== null &&
        value !== undefined &&
        isMulti &&
        value.toString().length > 1
    ) {
        const value_split = value.toString().split(",");
        value = [];
        value_split.forEach((e) => {
            const value_duplicate = e;
            e = parseInt(e);
            if (Number.isNaN(e)) e = value_duplicate;
            value.push(_.find(_options, { value: e }));
        });
    } else {
        const value_duplicate = value;
        value = parseInt(value);
        if (Number.isNaN(value)) value = value_duplicate;
        value = _.find(_options, { value });
    }
    return (
        <React.Fragment>
            <Select
                isClearable={isClearable}
                className={classNames("react-select-container", {
                    "is-invalid": invalid,
                })}
                backspaceRemovesValue={false}
                isMulti={isMulti}
                isSearchable={isSearchable}
                options={_options}
                placeholder={placeholder}
                onChange={(e) => {
                    if (isMulti) {
                        let indices = [];
                        e.forEach((a) => indices.push(a[valueKey]));
                        input.onChange(
                            indices.length > 0 ? indices.toString() : null
                        );
                        customChange &&
                            customChange(
                                indices.length > 0 ? indices.toString() : ""
                            );
                        customOnChange &&
                            indices.length > 0 &&
                            customOnChange(indices.toString());
                    } else {
                        input.onChange(e ? e[valueKey] : null);
                        customChange && customChange(e ? e[valueKey] : "");
                        customOnChange &&
                            e[valueKey] &&
                            customOnChange(e[valueKey]);
                    }
                }}
                value={valueSelect ? valueSelect : value}
                isDisabled={disabled}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </React.Fragment>
    );
};

export const AsyncSelectField = ({
    input,
    disabled,
    isClearable,
    isSearchable,
    loadOptions,
    placeholder,
    getOptionValue,
    getOptionLabel,
    meta: { touched, error },
}) => {
    const invalid = touched && error;

    return (
        <React.Fragment>
            <Async
                isClearable={isClearable}
                cacheOptions
                className={classNames("react-select-container", {
                    "is-invalid": invalid,
                })}
                backspaceRemovesValue={false}
                isSearchable={isSearchable}
                defaultOptions
                getOptionValue={getOptionValue}
                getOptionLabel={getOptionLabel}
                loadOptions={loadOptions}
                placeholder={placeholder}
                onChange={(e) => {
                    input.onChange(e ? e : null);
                }}
                value={input.value}
                isDisabled={disabled}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </React.Fragment>
    );
};

export const CreatableSelectField = ({
    input,
    disabled,
    isClearable,
    isSearchable,
    options,
    placeholder,
    labelKey = "label",
    valueKey = "value",
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    const _options = [];
    options.forEach((option) => {
        _options.push({
            ...option,
            label: option[labelKey],
            value: option[valueKey],
        });
    });

    return (
        <React.Fragment>
            <Creatable
                isClearable={isClearable}
                className={classNames("react-select-container", {
                    "is-invalid": invalid,
                })}
                backspaceRemovesValue={false}
                isSearchable={isSearchable}
                options={_options}
                placeholder={placeholder}
                onChange={(e) => {
                    input.onChange(e ? e : null);
                }}
                value={input.value}
                isDisabled={disabled}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </React.Fragment>
    );
};

/**
 * @param photo: este parametro se usa para tener la imagen previa de una imagen en dado caso el formulario es
 * usado para una actualizacion, se espera que sea la ruta donde se encuentra la imagen
 * @param setFile
 * @param className
 * @param disabled
 * @param input
 * @param touched
 * @param error
 * */
export const renderFilePicker = ({
    photo = null,
    setFile,
    className,
    disabled,
    label,
    input,
    customOnChange,
    no_show = false,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { "is-invalid": invalid })}>
            <FileUploader
                disabled={disabled}
                img={photo}
                label={label}
                no_show={no_show}
                onFileChange={(e, file) => {
                    file = file || e.target.files[0];
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        input.onChange(reader.result);
                        customOnChange && customOnChange(reader.result);
                        if (!!setFile) {
                            setFile(file);
                        }
                    };
                    reader.readAsDataURL(file);
                }}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderDayPicker = ({
    className,
    disabled,
    maxDate,
    minDate,
    input,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { "is-invalid": invalid })}>
            <DayPicker
                disabled={disabled}
                maxDate={maxDate}
                minDate={minDate}
                onChange={(e) => input.onChange(e)}
                value={input.value}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderReactDatePicker = ({
    disabled,
    maxDate,
    minDate,
    selected,
    setDate,
    placeholderText,
    dateFormat,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <div>
            <DatePicker
                className={classNames("form-control", {
                    "is-invalid": invalid,
                })}
                dateFormat={dateFormat}
                selected={selected ? selected : null}
                placeholderText={placeholderText}
                onChange={(e) => setDate(e)}
                disabled={disabled}
                maxDate={maxDate}
                minDate={minDate}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderDatePicker = ({
    className,
    disabled,
    maxDate,
    minDate,
    input,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { "is-invalid": invalid })}>
            <DatePicker
                onChange={(e) => input.onChange(e)}
                disabled={disabled}
                maxDate={maxDate}
                minDate={minDate}
                value={input.value}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const RenderField = {
    renderField,
    renderTextArea,
    renderRichTextArea,
    renderNumber,
    renderCurrency,
    renderSwitch,
    renderFieldCheck,
    renderFieldRadio,
};
