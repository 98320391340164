import React, { useState, useEffect } from "react";
import Form from "./Form";
import Title from "../../Utils/Headers/Title";

const Container = (props) => {

    return (
        <div className="card p-3">
            <div className="px-3 pt-4">
                <Title
                    title={"Configuración"}
                    subtitle={""}
                    showCreate={false}
                />
            </div>
            <div className="p-0 px-3 pt-3">
                <Form />
            </div>
        </div>
    );
};

export default Container;
