import React from "react";
import QrReader from "react-web-qr-reader";
import Swal from "sweetalert2";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import "./style.css";

const QrView = ({ qrRead, loader }) => {
    const delay = 500;

    const previewStyle = {
        maxHeight: 250,
        maxWidth: 250,
        width: "100vw",
        border: "None",
    };

    const handleScan = (result) => {
        if (result) {
            if (!result.data.includes("/users_open/")) {
                Swal.fire({
                    icon: "error",
                    title: "ERROR",
                    text: "El codigo QR no es valido para la plataforma",
                });
            } else {
                qrRead(result.data.split("/users_open/")[1].replace("/", ""));
            }
        }
    };

    const handleError = (error) => {
        Swal.fire({
            icon: "error",
            title: "ERROR",
            text: error,
        });
    };

    return (
        <LoadMask loading={loader} blur>
            <div className="qr-container">
                <QrReader
                    delay={delay}
                    style={previewStyle}
                    onError={handleError}
                    onScan={!loader && handleScan}
                />
            </div>
        </LoadMask>
    );
};
export default QrView;
