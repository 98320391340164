import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import './acciones.css';
import Swal from 'sweetalert2';
import user_remove from '../../../../../assets/img/user_remove.svg'


class Acciones extends Component {
    constructor(props) {
        super(props);
    }

    eliminar = (id) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar?',
                text: '¡No podrá revertir esta acción!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true,
                confirmButtonColor: '#0F45C7'
            }).then((result) => {
                if (result.value) {
                    this.props.eliminar(id);
                }
            });
        }
    };

    render() {
        const { id, ver, editar, eliminar, discharge, unsubscribe } = this.props;

        return (
            <div className="d-flex justify-content-start">
                {(ver !== undefined) && (
                    <Link to={`${ver}/${id}/`} className="btn btn-primary px-1 py-0 m-1" ><i className="material-icons text-white">remove_red_eye</i></Link>
                )}
                {(editar !== undefined) && (
                    <Link className="btn btn-warning px-1 py-0 m-1" to={`${editar}/${id}/editar`} ><i className="material-icons text-white">edit</i></Link>
                )}
                {(eliminar !== undefined) && (
                    <a className="btn btn-danger px-1 py-0 m-1" style={{cursor: "pointer", color: "#c4183c"}} onClick={this.eliminar(id)}><i className="material-icons text-white">delete</i></a>
                )}
                {(discharge !== undefined) && (
                    <Link to={`${discharge}/${id}/`} className="btn btn-green px-1 py-0 m-1" ><i className="material-icons text-white">assignment_ind</i></Link>
                )}
                {(unsubscribe !== undefined) && (
                    <Link to={`${unsubscribe}/${id}/`} className="btn btn-red btn-center px-1 py-0 m-1">
                        <i style={{paddingBottom:'4px'}} className="material-icons text-white">assignment_ind</i>
                        {/* <i className="material-icons text-white">manage_accounts</i> */}
                        {/* <img style={{height: '22px', width: '22px'}} src={user_remove} alt='user_remove.svg'></img> */}
                    </Link>
                )}
            </div>
        );
    }
}
Acciones.propTypes = {
};

export function standardActions(acciones) {
    return (cell, row) => {
        return ( <Acciones id={cell} {...acciones}/> )
    };
}
