import React, { Component } from "react";
import Grid from "../../Utils/Grid";
import { reduxForm } from "redux-form";
import OrderSearchComponent from "../../Utils/OrderSearch/orderSearch";
import Title from "../../Utils/Headers/Title";
import { standardActions } from "../../Utils/Grid/StandardActions";
import {ROLE_ADMIN} from "../../../../utility/constants";

class List extends Component {
    componentDidMount() {
        this.props.listar();
    }

    render() {
        const { listar, data, loader, onSortChange, searchChange, eliminar } = this.props;

        const columns = [
            {
                dataField: "code",
                text: "Código",
            },
            {
                dataField: "name",
                text: "Nombre",
            },
            {
                dataField: "description",
                text: "Descripción",
            },
            {
                dataField: 'id',
                text: 'Acciones',
                formatter: (cell, row) => (this.props.me && this.props.me.role===ROLE_ADMIN)
                    ? standardActions({ ver: '/emergency-type', editar: '/emergency-type', eliminar })(cell, row)
                    :standardActions({ ver: '/emergency-type' })(cell, row),
            }
        ];

        return (
            <div className="py-4">
                <div className="row">
                    <div className="mb-4 col-lg-12">
                        <Title
                            showCreate={(this.props.me && this.props.me.role===ROLE_ADMIN)?true:false}
                            title={"Tipos de emergencias"}
                            linkToRedirect={"#/emergency-types/create"}
                            titleCreate={"Crear tipo de emergencia"}
                            subtitle={"Listado general de tipos de emergencia"}
                        />
                        <div className="mb-4 card card-small">
                            <OrderSearchComponent searchChange={searchChange} customClass='row mx-auto w-100 mt-2 justify-content-end'/>
                            <div className="p-0 px-3 pt-3">
                                <Grid
                                    data={data}
                                    loading={loader}
                                    columns={columns}
                                    onPageChange={listar}
                                    onSortChange={onSortChange}
                                    noDataMessage={"Sin tipos de emergencia"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default reduxForm({
    form: "EmergencyTypes", // a unique identifier for this form
})(List);
