import { Image, Text, View } from "@react-pdf/renderer";
import React from "react";
import logoBombero from "../../../../../../../assets/img/logo512.png";

const Header = ({data}) => {
    return (
        <View>
            <View
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "10px",
                    position: "relative",
                }}
            >
                <Image
                    src={logoBombero}
                    alt="logo-bomberos"
                    style={{
                        maxWidth: "50px",
                        maxHeight: "50px",
                    }}
                />
                <Text
                    style={{
                        color: "#2350C7",
                        fontSize: "25",
                        marginLeft: "5",
                    }}
                >
                    Reporte Recursos Humanos
                </Text>
            </View>
            <View style={{ flexDirection: "row", marginBottom: "10px" }}>
                <View style={{ flexGrow: 1 }}>
                    <Text
                        style={{
                            margin: "auto",
                            color: "#2350C7",
                            fontSize: "12",
                        }}
                    >
                        Fecha Inicio
                    </Text>
                    <Text
                        style={{
                            margin: "auto",
                            color: "#212529",
                            fontSize: "10",
                        }}
                    >
                        {data.start_date}
                    </Text>
                </View>
                <View style={{ flexGrow: 1 }}>
                    <Text
                        style={{
                            margin: "auto",
                            color: "#2350C7",
                            fontSize: "12",
                        }}
                    >
                        Fecha Fin
                    </Text>
                    <Text
                        style={{
                            margin: "auto",
                            color: "#212529",
                            fontSize: "10",
                        }}
                    >
                        {data.end_date}
                    </Text>
                </View>
                <View style={{ flexGrow: 1 }}>
                    <Text
                        style={{
                            margin: "auto",
                            color: "#2350C7",
                            fontSize: "12",
                        }}
                    >
                        Estacion
                    </Text>
                    <Text
                        style={{
                            maxWidth: "100px",
                            margin: "auto",
                            color: "#212529",
                            fontSize: "10",
                        }}
                    >
                        {data.station}
                    </Text>
                </View>
                <View style={{ flexGrow: 1 }}>
                    <Text
                        style={{
                            margin: "auto",
                            color: "#2350C7",
                            fontSize: "12",
                        }}
                    >
                        Estado
                    </Text>
                    <Text
                        style={{
                            margin: "auto",
                            color: "#212529",
                            fontSize: "10",
                        }}
                    >
                        {data.user_}
                    </Text>
                </View>
                <View style={{ flexGrow: 1 }}>
                    <Text
                        style={{
                            margin: "auto",
                            color: "#2350C7",
                            fontSize: "12",
                        }}
                    >
                        Total Horas
                    </Text>
                    <Text
                        style={{
                            margin: "auto",
                            color: "#212529",
                            fontSize: "10",
                        }}
                    >
                        {data.grandTotal}
                    </Text>
                </View>
            </View>
        </View>
    );
};

export default Header;
