import React from "react";
import { Page, View, Document } from "@react-pdf/renderer";
import Header from "./Content/Header";
import Table from "./Content/Table";

const MyDocument = ({ data_pdf, key, data }) => {
    return (
        <Document key={key}>
            <Page
                size="A4"
                style={{ padding: "10px", backgroundColor: "#f4f6f9" }}
            >
                <View style={{ position: "relative" }}>
                    <Header data={data} />
                    <Table data_pdf={data_pdf} />
                </View>
            </Page>
        </Document>
    );
};

export default MyDocument;
