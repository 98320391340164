import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {Link, Redirect} from 'react-router-dom';
import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/cuenta/login';
import LoginForm from './LoginForm';
import LoadMask from "Utils/LoadMask/LoadMask";
const Logo = require('../../../../../assets/img/logo_bomberos.png');
import './login.css';

class Login extends Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };

    componentDidMount(props) {
        this.state = { prueba: true };
    }

    render() {
        const { onSubmit, loader } = this.props;
        if (localStorage.getItem('token')) {
            return (<Redirect to="/" />);
        }
        return (
            <div className="custom-background vh-100 p-3 d-flex align-items-center justify-content-center">
                <div className="flex-1 d-flex align-items-center justify-content-center h-100">
                    <div className="card card-login d-flex flex-column flex-md-row">
                        <div className="flex-1 imgLogo-login p-3 p-md-4 p-lg-5 d-flex justify-content-center align-items-center">
                            <img className="h-auto w-100" src={Logo} alt="logo bomberos"/>
                        </div>
                        <div className="flex-1-5 p-2 p-sm-3 p-md-5 d-flex align-items-center">
                            <div className="w-100">
                                <LoadMask loading={loader} light>
                                    <div className="d-flex flex-wrap">
                                        <div className="col-12">
                                            <h1 className="text-primary font-weight-bold mb-0"> Bienvenido </h1>
                                            <h6 className="text-secondary mb-4">Ingresa tus credenciales</h6>
                                            <LoginForm onSubmit={onSubmit} />
                                            <span className="mt-4">¿Olvidaste tu contraseña?&nbsp;<Link to="/forgot_password" className="font-weight-bold text-secondary">Restablecela aqui</Link></span>
                                        </div>
                                    </div>
                                </LoadMask>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const ms2p = (state) => ({
    ...state.login,
});

const md2p = { ...actions };

export default connect(ms2p, md2p)(Login);
