import React, { useEffect } from "react";
import Grid from "../../Utils/Grid";
import { Field, reduxForm } from "redux-form";
import Title from "../../Utils/Headers/Title";
import { standardActions } from "../../Utils/Grid/StandardActions";
import {
    ROLE_ADMIN,
    ROLE_ADMIN_STATION,
    ROLE_CABIN_ATTENDANT,
    ROLE_STATION_BOSS,
    STATUS_EMERGENCY,
} from "../../../../utility/constants";
import CustomSelectFIeld from "../../Utils/FieldFilters/SelectField";
import { SelectField } from "../../Utils/renderField/renderField";
import Filters from "../../Utils/Filters/Filters";
import Swal from "sweetalert2";
import CustomDropdown from "../../Utils/renderField/customDropdown";
import { Fragment } from "react";

const List = (props) => {
    const {
        data,
        loader,
        listar,
        searchChange,
        eliminar,
        me,
        station,
        setStation,
        setTypeEmergency,
        setTypeVehicle,
        vehicle,
        emergency_type_id,
        initializeFilterStatus,
        customListar,
        setStartDate,
        start_date,
        setEndDate,
        end_date,
        filterByRegion,
        region,
        updatePatch,
        descargarExcel,
    } = props;

    useEffect(() => {
        initializeFilterStatus();
        // searchChange("");
        customListar();
    }, []);

    const alert = (id, data) => {
        return () => {
            Swal.fire({
                title: "¿Esta seguro de cambiar el estado de la emergencia?",
                text: "¡No podrá revertir esta acción!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "¡Sí, cambiar!",
                cancelButtonText: "No, cancelar",
                reverseButtons: true,
                confirmButtonColor: "#0F45C7",
            }).then((result) => {
                if (result.value) {
                    updatePatch(id, data);
                }
            });
        };
    };

    const columns = [
        {
            dataField: "code",
            sort: true,
            text: "Código",
            formatter: (cell, row) => <div># {row.id}</div>,
        },
        {
            dataField: "stations",
            text: "Estación",
            style: { maxWidth: "300px" },
            formatter: (cell, row) => {
                let nameStation = "Sin estaciones Asignadas";
                if (cell && cell.length > 0) {
                    let stations = "";
                    cell.map((station, index) => {
                        const delimiter = index + 1 === cell.length ? "" : ", ";
                        stations += station.station__name + delimiter;
                    });
                    nameStation = stations;
                }
                return <p className="line-clamp-1">{nameStation}</p>;
            },
        },
        {
            dataField: "address",
            text: "Dirección de Emergencia",
        },
        {
            dataField: "emergency_type",
            text: "Tipo de Emergencia",
            formatter: (cell, row) => {
                return (
                    <div>
                        {row.emergency_type_text
                            ? row.emergency_type_text
                            : "Sin tipo"}
                    </div>
                );
            },
        },
        {
            dataField: "status_in_text",
            text: "Estado de Emergencia",
            formatter: (cell, row) => {
                let color = "point-success";
                if (row.status == 20) { // activo
                    color = "point-yellow";
                } else if (row.status == 30) { // resuelta
                    color = "point-green";
                } else if (row.status == 40) { // solicita apoyo
                    color = "point-red";
                } else if (row.status == 50) {
                    color = "point-orange-2";
                } else {
                    color = "text-black";
                }
                return (
                    <React.Fragment>
                        {me.role === 10 ? (
                            <div className="d-flex">
                                <span className={"material-icons " + color}>
                                    {" "}
                                    fiber_manual_record{" "}
                                </span>
                                <span className="">{cell}</span>
                            </div>
                        ) : (
                            <div className="d-flex">
                                {me.role === ROLE_CABIN_ATTENDANT || me.role === ROLE_ADMIN || me.role == ROLE_STATION_BOSS || me.role == ROLE_ADMIN_STATION ?
                                <CustomDropdown
                                    rowId={row.id}
                                    color={color}
                                    disabled={me.role !== ROLE_ADMIN && row.status === 30}
                                    title={row.status_in_text}
                                    options={
                                        [
                                            {value: 20, label: "Activo", action: alert(row.id, 20), color: 'point-yellow'},
                                            {value: 30, label: "Resuelta", action: alert(row.id, 30), color: 'point-green'},
                                            {value: 50, label: "Critica", action: alert(row.id, 50), color: 'point-yellow'},
                                            {value: 40, label: "Solicita apoyo", action: alert(row.id, 40), color: 'point-red'},
                                        ]
                                    }
                                /> :
                                    <Fragment>
                                        <span className={"material-icons " + color}>fiber_manual_record</span>
                                        <span className="">{row.status_in_text}</span>
                                    </Fragment>
                                }
                            </div>
                        )}
                    </React.Fragment>
                );
            },
        },
        {
            dataField: "id",
            text: "Acciones",
            formatter: (cell, row) => {
                const actions_all = standardActions({
                    ver: "/emergency",
                    editar: "/emergency",
                    eliminar,
                })(cell, row);

                const actions_min = standardActions({
                    ver: "/emergency",
                })(cell, row);

                const actions_edit = standardActions({
                    ver: "/emergency",
                    editar: "/emergency",
                })(cell, row);

                const actions_basic = standardActions({
                    ver: "/emergency",
                })(cell, row);

                return me.role === ROLE_ADMIN
                    ? actions_all
                    : me.role === ROLE_ADMIN_STATION ||
                      me.role === ROLE_STATION_BOSS
                    ? row.status === 20
                        ? actions_edit
                        : actions_min
                    : actions_basic;
            },
        },
    ];

    return (
        <div className="py-4">
            <div className="row">
                <div className="mb-4 col-lg-12">
                    <Title
                        showCreate={
                            me.role === ROLE_ADMIN ||
                            me.role === ROLE_ADMIN_STATION ||
                            me.role === ROLE_STATION_BOSS ||
                            me.role === ROLE_CABIN_ATTENDANT
                                ? true
                                : false
                        }
                        title={"Emergencias"}
                        linkToRedirect={"#/emergencies/create"}
                        titleCreate={"Crear emergencia"}
                        subtitle={"Listado general de Emergencias"}
                        downoaldExcel={{title: 'Descargar Excel', onClick: descargarExcel}}
                    />
                    <div className="mb-4 card card-small">
                        <div className="d-flex flex-column flex-md-row">
                            <div className="flex-1 m-3">
                                <Field
                                    name="statusEmergency"
                                    placeholder={"Estado de Emergencia"}
                                    component={SelectField}
                                    customChange={props.filter_by_status}
                                    options={STATUS_EMERGENCY}
                                />
                            </div>
                            <Filters
                                startDate={{
                                    show: true,
                                    start_date: start_date,
                                    setStartDate: setStartDate,
                                    classNameFilter: "flex-1 m-3",
                                }}
                                endDate={{
                                    show: true,
                                    end_date: end_date,
                                    setEndDate: setEndDate,
                                    classNameFilter: "flex-1 m-3",
                                }}
                            />
                        </div>
                        <div className="d-flex flex-column flex-md-row">
                            <Filters
                                station={{
                                    show: true,
                                    station: station,
                                    setStation: setStation,
                                    classNameFilter: "flex-1 m-3",
                                }}
                                region={{
                                    show: true,
                                    region: region,
                                    filterByRegion: filterByRegion,
                                    classNameFilter: "flex-1 m-3",
                                }}
                                emergency_type={{
                                    show: true,
                                    emergency_type: emergency_type_id,
                                    setTypeEmergency: setTypeEmergency,
                                    classNameFilter: "flex-1 m-3",
                                }}
                            />

                            <div className="flex-1 m-3">
                                <CustomSelectFIeld
                                    valueKey="id"
                                    labelKey="plate"
                                    nameApiOptions="vehicle"
                                    placeholder="Vehículo"
                                    value={vehicle}
                                    setValue={setTypeVehicle}
                                />
                            </div>
                        </div>
                        <div className="p-0 px-3 pt-3">
                            <Grid
                                data={data}
                                loading={loader}
                                columns={columns}
                                onPageChange={customListar}
                                noDataMessage={"Sin tipos de emergencia"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default reduxForm({
    form: "ListEmergencies", // a unique identifier for this form
})(List);
