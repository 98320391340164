import React from "react";

const StatisticCard = (props) => {
    return (
        <React.Fragment>
            <div
                className="card my-1 statistic--card statistic--card--display"
                style={{
                    background: props.background
                        ? `url(${props.background}) right center/auto 75% no-repeat`
                        : "",
                    maxHeight: 170,
                    minHeight: 140,
                }}
            >
                <div
                    className={`card-header text-white card--header--bg ${props.class}`}
                ></div>
                <div className="card-body">
                    {props.double ? (
                        <React.Fragment>
                            <span className="card-title text-primary">
                                {props.title}
                            </span>
                            <div className="d-flex">
                                <div className="mr-3">
                                    <h6 className="card-text font-weight-bold mb-0">
                                        {props.statistic}
                                    </h6>
                                    <p className="text-grey">
                                        {props.description}
                                    </p>
                                </div>
                                <div>
                                    <h6 className="card-text font-weight-bold mb-0">
                                        {props.statistic2}
                                    </h6>
                                    <p className="text-grey">
                                        {props.description2}
                                    </p>
                                </div>
                            </div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <h6 className="card-title text-primary">
                                {props.title}
                            </h6>
                            <h4 className="card-text font-weight-bold">
                                <strong>{props.statistic}</strong>
                            </h4>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default StatisticCard;
