import moment from "moment";
import React, { useEffect, useState } from "react";
import { reduxForm } from "redux-form";
import { HUMAN_RESOURCES, ROLE_ADMIN } from "../../../../utility/constants";
import Filters from "../../Utils/Filters/Filters";
import Grid from "../../Utils/Grid";
import { standardActions } from "../../Utils/Grid/StandardActions";
import Title from "../../Utils/Headers/Title";
import MyDocument from "./PDF";

const List = (props) => {
    const {
        customUserList,
        setStation,
        station,
        setUser,
        user_,
        data,
        loader,
        setStartDate,
        start_date,
        setEndDate,
        end_date,
        me,
        DataPDF,
        data_pdf,
        show_pdf,
        setShowPDF,
        downloadExcel,
    } = props;

    const [dropdownOpen, setDropdownOpen] = useState(false);

    useEffect(() => {
        props.customUserList();
    }, []);

    useEffect(() => {
        dropdownOpen && DataPDF();
    }, [dropdownOpen, data]);

    useEffect(() => {
        setShowPDF(dropdownOpen);
        dropdownOpen && DataPDF();
    }, [data]);

    // Minutes to hors (1hrs 0min)
    const converter = (minutes) => {
        const hours = Math.floor(minutes / 60);
        const minutes_ = minutes % 60;
        return `${hours}hrs ${minutes_}min`;
    };

    const columns = [
        {
            dataField: "no_dpi",
            text: "DPI",
        },
        {
            dataField: "first_name",
            text: "Nombre",
            formatter: (cell, row) => `${cell} ${row.last_name}`,
        },
        {
            dataField: "no_registry",
            text: "No. Registro",
        },

        {
            dataField: "station_text",
            text: "Estación Asignada",
        },

        {
            dataField: "job_management",
            text: "Puesto",
            formatter: (cell, row) =>
                row.job_management ? row.job_management : "Sin puesto asignado",
        },

        {
            dataField: "firefighter_type",
            text: "Tipo",
        },

        {
            dataField: "status",
            text: "Estado",
            formatter: (cell, row) => {
                let status = "Sin estado";
                if (row.status === "Activo") {
                    status = "De alta";
                } else if (row.status === "Inactivo")
                    status = `De baja (${row.inactive_reason})`;
                return status;
            },
        },

        {
            dataField: "total_hours_report",
            text: "Horas Totales",
        },

        {
            dataField: "id",
            text: "Acciones",
            formatter: (cell, row) =>
                standardActions({
                    ver: "/report/human_resources_report/user",
                })(cell, row),
        },
    ];

    let station_ = "";
    station &&
        station.map((station) => {
            station_ += station.name + ', '
        });

    // Calculation grand total
    let grandTotal =
        data && data.results && data.results.length > 0
            ? data.results[0].total_hours
            : 0;

    const data_ = {
        start_date: start_date
            ? moment(start_date).format("MM-DD-YYYY")
            : "- - - - -",

        // start_date,
        end_date: end_date
            ? moment(end_date).format("DD-MM-YYYY")
            : "- - - - -",
        station: station_ ? station_ : "Todas las estaciones",
        user_: user_===5 ? 'Activo': user_ === 1 ? 'Suspendido' : user_ === 2 ? 'Vacaciones' : user_ === 3 ? 'Inactivo' : user_ === 4 ? 'Despedido' : '- - - - -',
        grandTotal: converter(grandTotal),
    };

    return (
        <div className="py-4">
            <div className="row">
                <div className="mb-4 col-lg-12">
                    <div className="mb-4 card card-small">
                        <div className="px-3 pt-4">
                            <Title
                                title={"Reporte Recursos Humanos"}
                                subtitle={"Listado general"}
                                total={{
                                    title: "Total de Horas/Minutos",
                                    total: converter(grandTotal),
                                }}
                                downoaldExcel={{
                                    isMulti: true,
                                    title: "Descargar",
                                    state: {
                                        dropdownOpen: dropdownOpen,
                                        setDropdownOpen: setDropdownOpen,
                                    },
                                    list: [
                                        {
                                            title: "Excel",
                                            disabled:
                                                start_date > end_date ||
                                                !me.station,
                                            action: downloadExcel,
                                        },
                                        {
                                            title: "PDF",
                                            disabled:
                                                start_date > end_date ||
                                                !me.station,
                                            document: MyDocument,
                                            data_pdf: data_pdf,
                                            show_pdf: show_pdf,
                                            data: data_,
                                        },
                                    ],
                                }}
                            />
                        </div>
                        <div className="mb-4">
                            <div className="d-flex flex-column flex-md-row">
                                <Filters
                                    startDate={{
                                        show: true,
                                        start_date: start_date,
                                        setStartDate: setStartDate,
                                        classNameFilter: "flex-1 m-3",
                                    }}
                                    endDate={{
                                        show: true,
                                        end_date: end_date,
                                        setEndDate: setEndDate,
                                        classNameFilter: "flex-1 m-3",
                                    }}
                                    station={{
                                        show:
                                            (props.me.role === ROLE_ADMIN || props.me.role === HUMAN_RESOURCES)
                                                ? true
                                                : false,
                                        station:
                                            station,
                                        setStation: setStation,
                                        classNameFilter: "flex-1 m-3",
                                    }}
                                    status_user={{
                                        show: true,
                                        status_user: user_,
                                        setStatusUser: setUser,
                                        classNameFilter: "flex-1 m-3",
                                    }}
                                />
                            </div>
                        </div>
                        <div className="p-0 px-3 pt-3">
                            <Grid
                                data={data}
                                loading={loader}
                                columns={columns}
                                onPageChange={customUserList}
                                noDataMessage={"Sin Usuarios"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default reduxForm({
    form: "ReportHumanResourcesForm",
})(List);
