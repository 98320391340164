/* REACT */
import React, { Component, useEffect } from "react";

/* IMPORT */
import Title from "../Utils/Headers/Title";
import LoadMask from "../Utils/LoadMask/LoadMask";

/* STYLES */
import Filters from "../Utils/Filters/Filters";
import CardsHeader from "./Cards/CardsHeader";
import CardContainer from "./Cards/CardContainer";
const Dashboard = (props) => {
    const {
        dashboardData,
        loader,
        start_date,
        setStartDate,
        end_date,
        setEndDate,
        station,
        setStation,
        emergency_type_id,
        setTypeEmergency,
        getDashboardReport,
        me,
    } = props;

    useEffect(() => {
        setStation(me ? (me.station ? [me.station] : null) : null);

        getDashboardReport();
    }, []);

    return (
        <div className="py-4">
            <Title
                title={"Dashboard"}
                subtitle={"Datos generales"}
                showCreate={false}
            />
            <div className="mb-4">
                <div className="d-flex flex-column flex-md-row">
                    <Filters
                        startDate={{
                            show: true,
                            start_date: start_date,
                            setStartDate: setStartDate,
                            classNameFilter: "flex-1 m-3",
                        }}
                        endDate={{
                            show: true,
                            end_date: end_date,
                            setEndDate: setEndDate,
                            classNameFilter: "flex-1 m-3",
                        }}
                        station={{
                            show: true,
                            station: station
                                ? station
                                : props.me
                                ? props.me.station
                                : null,
                            setStation: setStation,
                            classNameFilter: "flex-1 m-3",
                        }}
                        emergency_type={{
                            show: true,
                            emergency_type: emergency_type_id,
                            setTypeEmergency: setTypeEmergency,
                            classNameFilter: "flex-1 m-3",
                        }}
                    />
                </div>
            </div>
            <LoadMask loading={loader} blur>
                {dashboardData ? (
                    <React.Fragment>
                        <CardsHeader dashboardData={dashboardData} />
                        <CardContainer
                            dashboardData={dashboardData}
                            loader={loader}
                            start_date={start_date}
                            end_date={end_date}
                            permissions={me.role ? (me.role === 8 || me.role === 1) : false}
                        />
                    </React.Fragment>
                ) : (
                    <div className="row">
                        <div className="col-12 px-0 d-flex flex-wrap">
                            <h3> No hay estadisticas que mostrar </h3>
                        </div>
                    </div>
                )}
            </LoadMask>
        </div>
    );
};

export default Dashboard;
