import { api } from "api";
import { handleActions } from "redux-actions";
import { NotificationManager } from "react-notifications";
import moment from "moment";

// ------------------------------------
// Constants
// ------------------------------------
const SET_DATA_BINNACLE = "SET_DATA_BINNACLE";
const SET_DETAIL_BINNACLE = "SET_DETAIL_BINNACLE";
const FILTER_BY_START_DATE_BINNACLE = "FILTER_BY_START_DATE_BINNACLE";
const FILTER_BY_END_DATE_BINNACLE = "FILTER_BY_END_DATE_BINNACLE";
const LOADER_BINNACLE = "LOADER_BINNACLE";
const USER_BINNACLE = "USER_BINNACLE";
const ACTION_BINNACLE = "ACTION_BINNACLE";

// ------------------------------------
// Pure Actions
// ------------------------------------
const setDataBinnacle = (data_binnacle) => ({
    type: SET_DATA_BINNACLE,
    data_binnacle,
});

const setDetailBinnacle = (data_detail) => ({
    type: SET_DETAIL_BINNACLE,
    data_detail,
});

const setFilterByStartDate = (start_date) => ({
    type: FILTER_BY_START_DATE_BINNACLE,
    start_date,
});

const setFilterByEndDate = (end_date) => ({
    type: FILTER_BY_END_DATE_BINNACLE,
    end_date,
});

const setLoaderBinnacle = (loader) => ({
    type: LOADER_BINNACLE,
    loader,
});

const setFilterByUser = (user_filter) => ({
    type: USER_BINNACLE,
    user_filter,
});

const setFilterByAction = (action_binnacle) => ({
    type: ACTION_BINNACLE,
    action_binnacle,
});

const getBinnacle =
    (page = 1) =>
    (dispatch, getStore) => {
        dispatch(setLoaderBinnacle(true));
        const resource = getStore()["binnacle"];
        const params = { page };
        // filter start date
        const start_date = resource.start_date
            ? moment(resource.start_date).format("YYYY-MM-DD")
            : "";
        params.start_date = start_date;
        // filter end date
        const end_date = resource.end_date
            ? moment(resource.end_date).format("YYYY-MM-DD")
            : "";
        params.end_date = end_date;
        
        // filtre user
        const user_filter = resource.user_filter
            ? resource.user_filter.id
            : "";
        params.created_by__id = user_filter;

        // action binnacle
        const action_binnacle = resource.action_binnacle
            ? resource.action_binnacle
            : "";
        params.action_binnacle = action_binnacle;

        api.get("binnacle", params)
            .then((response) => {
                dispatch(setDataBinnacle(response));
            })
            .catch(() => {
                NotificationManager.error(
                    "Error al obtener los datos de la bitácora",
                    "Error",
                    1000
                );
            })
            .finally(() => {
                dispatch(setLoaderBinnacle(false));
            });
    };

const leer = (id) => (dispatch) => {
    dispatch(setLoaderBinnacle(true));
    api.get(`binnacle/${id}`)
        .then((response) => {
            dispatch(setDetailBinnacle(response));
        })
        .catch(() => {
            NotificationManager.error(
                "Error al obtener los datos de la bitácora",
                "Error",
                1000
            );
        })
        .finally(() => {
            dispatch(setLoaderBinnacle(false));
        });
};

const setStartDate = (value) => (dispatch, getStore) => {
    const resource = getStore()["binnacle"];
    dispatch(setFilterByStartDate(value));
    if (resource.end_date) dispatch(getBinnacle());
};

const setEndDate = (value) => (dispatch, getStore) => {
    const resource = getStore()["binnacle"];
    dispatch(setFilterByEndDate(value));
    if (resource.start_date) dispatch(getBinnacle());
};

const setUserBinnacle = (value) => (dispatch) => {
    dispatch(setFilterByUser(value));
    dispatch(getBinnacle());
};

const setActionBinnacle = (value) => (dispatch) => {
    dispatch(setFilterByAction(value));
    dispatch(getBinnacle());
};

export const actions = {
    getBinnacle,
    leer,
    setStartDate,
    setEndDate,
    setUserBinnacle,
    setActionBinnacle,
};

export const reducers = {
    [SET_DATA_BINNACLE]: (state, { data_binnacle }) => ({
        ...state,
        data_binnacle,
    }),
    [SET_DETAIL_BINNACLE]: (state, { data_detail }) => ({
        ...state,
        data_detail,
    }),
    [FILTER_BY_START_DATE_BINNACLE]: (state, { start_date }) => {
        return {
            ...state,
            start_date,
        };
    },
    [FILTER_BY_END_DATE_BINNACLE]: (state, { end_date }) => {
        return {
            ...state,
            end_date,
        };
    },
    [LOADER_BINNACLE]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [USER_BINNACLE]: (state, { user_filter }) => {
        return {
            ...state,
            user_filter,
        };
    },
    [ACTION_BINNACLE]: (state, { action_binnacle }) => {
        return {
            ...state,
            action_binnacle,
        };
    },
};

export const initialState = {
    data_binnacle: "",
    data_detail: {},
    start_date: "",
    end_date: "",
    loader: false,
    user_filter: '',
    action_binnacle: '',
};

export default handleActions(reducers, initialState);
