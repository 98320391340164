import React, { Component } from "react";
import Grid from "../../Utils/Grid";
import { reduxForm } from "redux-form";
import Title from "../../Utils/Headers/Title";

class List extends Component {

    render() {
        const { changePage, data, loader, page } = this.props;

        const columns = [
            {
                dataField: "first_name",
                text: "Nombre",
                formatter: (cell, row) => `${cell} ${row.last_name}`
            },
            {
                dataField: "email",
                text: "Correo",
            },
            {
                dataField: "address",
                text: "Dirección",
            },
            {
                dataField: "phone_number",
                headerClasses: "col-1",
                text: "Teléfono",
            },
        ];

        return (
            <div className="py-4">
                <div className="row">
                    <div className="mb-4 col-lg-12">
                        <Title
                            showCreate={false}
                            title={"Usuarios"}
                            subtitle={"Usuarios de la estación"}
                        />
                        <div className="mb-4 card card-small">
                            <div className="p-0 px-3 pt-3">
                                <Grid
                                    data={data}
                                    page={page}
                                    loading={loader}
                                    columns={columns}
                                    onPageChange={changePage}
                                    onSortChange={()=>{}}
                                    noDataMessage={"Sin usuarios"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default reduxForm({
    form: "ListStationUsers", // a unique identifier for this form
})(List);
