import React from "react";
import Swal from "sweetalert2";
import { Field } from "redux-form";
import { renderNumber } from "../../Utils/renderField";
import { SelectField } from "../../Utils/renderField/renderField";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { requiredField } from "../../../../utility/validation";
import { ROLE_CABIN_ATTENDANT } from "../../../../utility/constants";

const renderVehicles = ({
    fields,
    meta: { touched, error, submitFailed },
    vehicles,
    isView,
    temporal_vehicle,
    setTemporalVehicle,
    selected_vehicles,
    setSelectedVehicles,
    pilot,
    role,
}) => {
    const addVehicle = () => {
        if (!temporal_vehicle) {
            Swal.fire(
                "¡Atención!",
                "Debes seleccionar un vehículo.",
                "warning"
            );
        } else {
            if (
                !selected_vehicles.find(
                    (e) => e.value == temporal_vehicle.value
                )
            ) {
                fields.push({});
                setSelectedVehicles((a) => [...a, temporal_vehicle]);
                setTemporalVehicle(null);
            } else {
                Swal.fire(
                    "¡Atención!",
                    "Ya has agregado este vehículo.",
                    "warning"
                );
            }
        }
    };

    const selectVehicle = (vehicle_id) => {
        setTemporalVehicle(vehicles.find((e) => e.value == vehicle_id));
    };

    const removeVehicle = (index) => {
        let vs = [...selected_vehicles];
        vs.splice(index, 1);
        fields.remove(index);
        setSelectedVehicles(vs);
    };

    return (
        <React.Fragment>
            <div className="col-12 col-md-6 form-group">
                <label htmlFor="vehicles">Vehículo</label>
                <Field
                    name="vehicles"
                    label="vehicles"
                    placeholder="Vehículo"
                    disabled={isView || vehicles.length == 0}
                    options={vehicles}
                    component={SelectField}
                    className="form-control"
                    customOnChange={selectVehicle}
                />
            </div>
            <div className="col-12 col-md-6 form-group d-flex align-items-end">
                <button
                    className="btn btn-primary"
                    type="button"
                    onClick={() => addVehicle()}
                >
                    Agregar Vehículo
                </button>
            </div>
            <div className="col-12 form-group">
                {fields.length > 0 ? (
                    <Table className="table">
                        <Thead>
                            <Tr className="table-primary">
                                <Th scope="col">#</Th>
                                <Th scope="col" style={{ minWidTh: "200px" }}>
                                    Placa - Nombre
                                </Th>
                                <Th scope="col" style={{ minWidTh: "100px" }}>
                                    KM Inicial{" "}
                                    <span className="text-danger">*</span>
                                </Th>
                                <Th scope="col" style={{ minWidTh: "100px" }}>
                                    KM Final{" "}
                                    <span className="text-danger">*</span>
                                </Th>
                                <Th scope="col" style={{ minWidTh: "100px" }}>
                                    Piloto{" "}
                                    <span className="text-danger">*</span>
                                </Th>
                                {!isView ? <Th scope="col">Acciones</Th> : null}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {fields.map((member, index) => (
                                <Tr key={index}>
                                    <Th scope="row">{index + 1}</Th>
                                    <Td>
                                        {selected_vehicles[index]
                                            ? selected_vehicles[index].label
                                            : ""}
                                    </Td>
                                    <Td>
                                        <Field
                                            name={`${member}.km_inicial`}
                                            placeholder="KM inicial"
                                            type="number"
                                            component={renderNumber}
                                            disabled={isView}
                                            label={`${member}.km_inicial`}
                                            validate={role !== ROLE_CABIN_ATTENDANT && requiredField}
                                        />
                                    </Td>
                                    <Td>
                                        <Field
                                            name={`${member}.km_final`}
                                            type="number"
                                            placeholder="KM final"
                                            disabled={isView}
                                            component={renderNumber}
                                            label={`${member}.km_final`}
                                            validate={role !== ROLE_CABIN_ATTENDANT && requiredField}
                                        />
                                    </Td>
                                    <Td>
                                        <Field
                                            name={`${member}.pilot`}
                                            label={`${member}.pilot`}
                                            placeholder="Piloto"
                                            disabled={isView}
                                            options={pilot}
                                            component={SelectField}
                                            className="form-control"
                                            validate={requiredField}
                                        />
                                    </Td>
                                    {!isView ? (
                                        <Td>
                                            <button
                                                type="button"
                                                title="Eliminar"
                                                className="btn btn-danger"
                                                onClick={() =>
                                                    removeVehicle(index)
                                                }
                                            >
                                                <span
                                                    style={{ fontSize: "20px" }}
                                                    className="material-icons"
                                                >
                                                    close
                                                </span>
                                            </button>
                                        </Td>
                                    ) : null}
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                ) : (
                    <p>
                        Seleccione un vehículo y presione el botón 'Agregar
                        Vehículo'.
                    </p>
                )}
            </div>
        </React.Fragment>
    );
};

export default renderVehicles;
