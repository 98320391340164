import React, { useEffect } from "react";
import { Field } from "redux-form";
import { renderField } from "../../Utils/renderField";;
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { requiredField, validateWhiteSpace } from "../../../../utility/validation";

const renderEmergencyType = ({
    fields,
    isView,
}) => {
    useEffect(() => {
        fields.push({});
    }, []);

    return (
        <React.Fragment>
            <div className="col-12 col-md-6 form-group d-flex align-items-end">
                {!isView && (
                    <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => fields.push({})}
                    >
                        Agregar Tipo de Emergencia
                    </button>
                )}
            </div>
            <div
                className="col-12 form-group"
                style={{ minHeight: `${fields.length * 50 + 150}px` }}
            >
                {fields.length > 0 ? (
                    <Table className="table">
                        <Thead>
                            <Tr className="table-primary">
                                <Th scope="col">
                                    Código{" "}
                                    {!isView && (
                                        <span className="text-danger">*</span>
                                    )}
                                </Th>
                                <Th scope="col">
                                    Nombre{" "}
                                    {!isView && (
                                        <span className="text-danger">*</span>
                                    )}
                                </Th>
                                <Th scope="col">
                                    Descripción{" "}
                                    {!isView && (
                                        <span className="text-danger">*</span>
                                    )}
                                </Th>
                                {!isView && <Th scope="col">Acciones</Th>}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {fields.map((member, index) => (
                                <Tr key={index}>
                                    <Td>
                                        <Field
                                            name={`${member}.code`}
                                            placeholder="Código"
                                            type="number"
                                            disabled={isView}
                                            component={renderField}
                                            label={`${member}.code`}
                                            validate={requiredField}
                                        />
                                    </Td>
                                    <Td>
                                        <Field
                                            name={`${member}.name`}
                                            placeholder="Nombre"
                                            type="text"
                                            disabled={isView}
                                            component={renderField}
                                            validate={[requiredField, validateWhiteSpace]}
                                            label={`${member}.name`}
                                        />
                                    </Td>
                                    <Td>
                                        <Field
                                            name={`${member}.description`}
                                            placeholder="Descripcion"
                                            type="text"
                                            disabled={isView}
                                            component={renderField}
                                            validate={[validateWhiteSpace]}
                                            label={`${member}.description`}
                                        />
                                    </Td>
                                    {!isView && (
                                        <Td>
                                            <button
                                                type="button"
                                                title="Eliminar"
                                                className="btn btn-danger"
                                                onClick={() =>
                                                    fields.remove(index)
                                                }
                                            >
                                                <span
                                                    style={{ fontSize: "20px" }}
                                                    className="material-icons"
                                                >
                                                    close
                                                </span>
                                            </button>
                                        </Td>
                                    )}
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                ) : (
                    <p>Presione el botón 'Agregar Tipo de Emergencia'.</p>
                )}
            </div>
        </React.Fragment>
    );
};

export default renderEmergencyType;
