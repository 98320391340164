import React from "react";
import { Card } from "reactstrap";
import StatisticCard from "../../Utils/Cards/StatisticCard";
/* IMAGE */
const alert_icon = require("assets/img/alert-icons-triangle-alert.svg");
const fire_engine_warning = require("assets/img/fire_truck.svg");
const fire_engine_success = require("assets/img/fire-engine-success.svg");
const fire_engine_danger = require("assets/img/fire-engine-danger.svg");
const user_icon = require("assets/img/user-icon.svg");

import "../dashboard.css";

const CardsHeader = ({dashboardData}) => {
    return (
        <div className="main-container">
            <div className="m-2 card-1">
                <StatisticCard
                    title="Emergencias Activas"
                    class="bg-warning"
                    statistic={
                        dashboardData.emergencies_active
                            ? dashboardData.emergencies_active
                            : 0
                    }
                />
            </div>
            <div className="m-2 card-2">
                <StatisticCard
                    title="Emergencias Resueltas"
                    class="bg-green"
                    statistic={
                        dashboardData.emergencies_resolved
                            ? dashboardData.emergencies_resolved
                            : 0
                    }
                />
            </div>
            <div className="m-2 card-3">
                <StatisticCard
                    title="Emergencias Críticas"
                    class="bg-danger"
                    statistic={
                        dashboardData.emergencies_critical
                            ? dashboardData.emergencies_critical
                            : 0
                    }
                />
            </div>

            <div className="m-2 card-4">
                <StatisticCard
                    title="Estaciones Activas"
                    class="bg-green"
                    statistic={
                        dashboardData.station_active
                            ? dashboardData.station_active
                            : 0
                    }
                />
            </div>
            <div className="m-2 card-5">
                <StatisticCard
                    title="Estaciones Inactivas"
                    class="bg-danger"
                    statistic={
                        dashboardData.emergencies_disable
                            ? dashboardData.emergencies_disable
                            : 0
                    }
                />
            </div>
            <div className="m-2 card-6">
                <StatisticCard
                    title="Vehículos disponibles / en emergencia"
                    class="bg-warning"
                    statistic={
                        dashboardData.vehicles_disponibility
                            ? dashboardData.vehicles_disponibility
                            : 0
                    }
                    statistic2={
                        dashboardData.vehicles_emergency
                            ? dashboardData.vehicles_emergency
                            : 0
                    }
                    double={true}
                    description={"Diponibles"}
                    description2={"En emergencia"}
                />
            </div>

            <Card className="m-2 card-7">
                <div className="content-info">
                    <img
                        className="mb-2"
                        src={alert_icon}
                        alt="logo-warning.png"
                    />
                    <h5 className="title font-weight-bold">
                        Emergencias Solicitando Apoyo
                    </h5>
                    <h3 className="font-weight-bold">
                        {dashboardData.emergencies_requesting_support
                            ? dashboardData.emergencies_requesting_support
                            : 0}
                    </h3>
                </div>
            </Card>
            <Card className="m-2 card-8 bg-green">
                <div className="content-info">
                    <img
                        className="mb-2"
                        src={fire_engine_warning}
                        alt="logo-user.png"
                    />
                    <h5 className="title font-weight-bold">
                        Vehículos registrados
                    </h5>
                    <h3 className="font-weight-bold">
                        {dashboardData.total_vehicle
                            ? dashboardData.total_vehicle
                            : 0}
                    </h3>
                </div>
            </Card>
        </div>
    );
};

export default CardsHeader;
