/* REACT */
import React, { Component, useEffect } from "react";

/* IMPORT */
import CardsHeader from "../Dashboard/Cards/CardsHeader";
import CardContainer from "../Dashboard/Cards/CardContainer";
import Filters from "../Utils/Filters/Filters";
import LoadMask from "../Utils/LoadMask/LoadMask";
import Title from "../Utils/Headers/Title";

const DashboardFree = (props) => {
    const {
        dashboardOpenData,
        loader,
        start_date,
        setStartDateOpen,
        end_date,
        setEndDateOpen,
        setStationOpen,
        emergency_type_id,
        setTypeEmergencyOpen,
        getDashboardOpenReport,
    } = props;

    useEffect(() => {
        getDashboardOpenReport();
    }, []);

    return (
        <div className="py-4">
            <div className="mb-4">
                <div className="d-flex flex-column flex-md-row">
                    <Filters
                        startDate={{
                            show: true,
                            start_date: start_date,
                            setStartDate: setStartDateOpen,
                            classNameFilter: "flex-1 m-3",
                        }}
                        endDate={{
                            show: true,
                            end_date: end_date,
                            setEndDate: setEndDateOpen,
                            classNameFilter: "flex-1 m-3",
                        }}
                        station={{
                            show: true,
                            setStation: setStationOpen,
                            nameApiOptions: "dashboard_open/station",
                            classNameFilter: "flex-1 m-3",
                        }}
                        emergency_type={{
                            show: true,
                            emergency_type: emergency_type_id,
                            setTypeEmergency: setTypeEmergencyOpen,
                            nameApiOptions: "dashboard_open/emergency_type",
                            classNameFilter: "flex-1 m-3",
                        }}
                    />
                </div>
            </div>
            <LoadMask loading={loader} blur>
                {dashboardOpenData ? (
                    <React.Fragment>
                        <CardsHeader dashboardData={dashboardOpenData} />
                        <CardContainer
                            dashboardData={dashboardOpenData}
                            loader={loader}
                            start_date={start_date}
                            end_date={end_date}
                            isDashboardOpen={true}
                        />
                    </React.Fragment>
                ) : (
                    <div className="row">
                        <div className="col-12 px-0 d-flex flex-wrap">
                            <h3> No hay estadisticas que mostrar </h3>
                        </div>
                    </div>
                )}
            </LoadMask>
        </div>
    );
};

export default DashboardFree;
