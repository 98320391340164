import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { actions } from "../../../../redux/modules/users/user_open";
import Title from "../../Utils/Headers/Title";
import Form from "./Form";
import { formValueSelector } from "redux-form";

const Container = (props) => {
    const [showInactive, setShowInactive] = useState(false);
    useEffect(() => {
        props.custom_leer(props.match.params.id);
        return () => {
            props.setItem({});
        };
    }, []);

    const { loader, item, value_is_pilot } = props;

    return (
        <div className="card pb-3 mb-5">
            <div
                className={`px-4 pt-4 container-title-create${
                    showInactive ? "-requesting_support" : ""
                }`}
            >
                <Title
                    textWhite={true}
                    title={`Usuario ${showInactive ? 'Inactivo': 'Activo'}`}
                    subtitle={"Detalles generales de usuario"}
                />
            </div>
            <div className="p-0 px-3 pt-3">
                <div className="p-0 px-3 pt-3">
                    {item && item.id ? (
                        <Form
                            item={item}
                            valueIsPilot={value_is_pilot}
                            loading={loader}
                            showInactive={showInactive}
                            setShowInactive={setShowInactive}
                        />
                    ) : (
                        <h1>Usuario no registrado</h1>
                    )}
                </div>
            </div>
        </div>
    );
};

//  Decorador para leer los valores del form
const selector = formValueSelector("userFormOpen");

const mstp = (state) => {
    const value_is_pilot = selector(state, "is_pilot");
    return {
        value_is_pilot: value_is_pilot,
        ...state.user_open,
    };
};

const mdtp = { ...actions };

export default connect(mstp, mdtp)(Container);
