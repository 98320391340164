import React, { useEffect, useState } from "react";
import Grid from "../Utils/Grid";
import Title from "../Utils/Headers/Title";
import ModalUser from "./Modal/ModalUser";
import qr_code_white from "../../../../assets/img/qrblanco.svg";
import moment from "moment";
import Filters from "../Utils/Filters/Filters";

const QRScan = ({
    customUserList,
    data,
    modal_open,
    setOpenModal,
    user_QR,
    setEntry,
    setExit,
    loader,
    qrRead,
    filterUserQR,
    user_filter_qr,
}) => {
    const [showObservation, setShowObservation] = useState(false);
    useEffect(() => {
        customUserList();
    }, []);

    const columns = [
        {
            dataField: "first_name",
            text: "Nombre",
            formatter: (cell, row) => `${cell} ${row.last_name}`,
        },
        {
            dataField: "no_dpi",
            text: "DPI",
        },
        {
            dataField: "firefighter_type",
            text: "Tipo de bombero",
            formatter: (cell, row) =>
                `${
                    row.firefighter_type
                        ? row.firefighter_type
                        : "Sin tipo de bombero"
                }`,
        },
        {
            dataField: "last_entry",
            text: "Último ingreso",
            formatter: (cell, row) => `${
                row.last_entry
                    ? moment(row.last_entry).tz("America/Guatemala").format("DD-MM-YYYY HH:mm:ss")
                    : "Sin registro"
            }`,
        },

        {
            dataField: "last_exit",
            text: "Última salida",
            formatter: (cell, row) => `${
                row.last_exit
                    ? moment(row.last_exit).tz("America/Guatemala").format("DD-MM-YYYY HH:mm:ss")
                    : "Sin registro"
            }`,
        },
        {
            dataField: "is_entry",
            text: "Estado Actual",
            formatter: (cell, row) => {
                let color = "point-orange";
                let text = "Sin registro";

                if (row.is_entry === false) {
                    color = "point-red";
                    text = "Salida";
                } else if (row.is_entry == true) {
                    color = "point-green";
                    text = "Ingreso";
                }

                return (
                    <div className="d-flex">
                        <React.Fragment>
                            <span className={"material-icons " + color}>
                                fiber_manual_record
                            </span>
                            <span className="">{text}</span>
                        </React.Fragment>
                    </div>
                );
            },
        },
        {
            dataField: "id",
            text: "Acciones",
            formatter: (cell, row) => (
                <button
                    disabled={row.is_entry === false}
                    className="btn btn-primary px-1 py-0 m-1"
                    style={{ cursor: "pointer", color: "#c4183c" }}
                    onClick={() => {
                        setShowObservation(true);
                        qrRead(row.id, false);
                    }}
                >
                    <span className="material-icons">
                        <img src={qr_code_white} />
                    </span>
                </button>
            ),
        },
    ];

    return (
        <div className="py-4">
            <ModalUser
                setOpenModal={setOpenModal}
                modal_open={modal_open}
                data={user_QR}
                setEntry={setEntry}
                setExit={setExit}
                loader={loader}
                showObservation={showObservation}
            />
            <div className="row">
                <div className="mb-4 col-lg-12">
                    <div className="mb-4 card card-small">
                        <div className="px-3 pt-4">
                            <Title
                                showCreate={true}
                                title={"Escaneo De QR"}
                                linkToRedirect={"/#/qr_read"}
                                titleCreate={"Escanear QR"}
                                subtitle={"Listado general"}
                            />
                        </div>
                        <div className="d-flex flex-column flex-md-row">
                            <Filters
                                user={{
                                    show: true,
                                    user: user_filter_qr,
                                    setUser: filterUserQR,
                                    classNameFilter: "w-90 m-3 w-md-25",
                                    nameApiOptions: "qr",
                                }}
                            />
                        </div>
                        <div className="p-0 px-3 pt-3">
                            <Grid
                                data={data}
                                loading={loader}
                                columns={columns}
                                onPageChange={customUserList}
                                noDataMessage={"Sin Usuarios"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QRScan;
