import React, { useEffect } from "react";
import Map from "../Utils/Map";
import Title from "../Utils/Headers/Title";
import { actions } from "../../../redux/modules/tracking";
import fire_truck_img from "../../../../assets/img/fire_truck.svg";
import ambulance from "../../../../assets/img/Ambulance-amico.svg";
import { Field, reduxForm } from "redux-form";
import { SelectField } from "../Utils/renderField/renderField";
import { STATUS_EMERGENCY_TRACKING } from "../../../utility/constants";
import Swal from "sweetalert2";

const Tracking = (props) => {
    let interval = () => {};

    const onTracking = () => {
        interval = setInterval(() => {
            props.getVehiclesonTracking();
        }, 30000);
    };

    useEffect(() => {
        props.getVehiclesonTracking();
        onTracking();
        return () => {
            clearInterval(interval);
        };
    }, []);

    const CardTracking = ({ title = "", total = 0, className }) => {
        return (
            <div className="d-flex flex-1 card-1-detail-main mb-2">
                <div className="flex-1">
                    <div className={className}>
                        <img src={fire_truck_img}></img>
                    </div>
                </div>

                <div className="flex-4 card-1-detail-content p-2">
                    <h6 className="card-title text-primary m-0">{title}</h6>
                    <h4 className="font-weight-bold">{total}</h4>
                </div>
            </div>
        );
    };

    let val = false;
    let vehicleOffTracking = "";
    let listVehicles = [];

    props.data.response &&
        props.data.response.results &&
        props.data.response.results.length > 0 &&
        props.data.response.results.map((lat) => {
            if (!lat.position) {
                vehicleOffTracking += lat.plate + ",";
            }
        });

    listVehicles = Array.from(
        new Set(vehicleOffTracking.split(","))
    ).toString();

    const lat_lng_reduce =
        props.data.response &&
        props.data.response.results &&
        props.data.response.results.length > 0
            ? props.data.response.results.map((lat) => {
                  const prom = lat.position ? JSON.parse(lat.position) : 0;
                  if (lat.position) {
                      val = true;
                  }
                  return prom
                      ? prom.coords
                      : {
                            lat: props.me.station ? props.me.station.lat : 0,
                            lng: props.me.station ? props.me.station.lng : 0,
                        };
              })
            : {};

    if (listVehicles) {
        Swal.fire(
            "¡Atención!",
            `No se pudo obtener la posicion de los vehiculos: ${listVehicles}`,
            "warning"
        );
    }

    const validate =
        props.data.response &&
        props.data.response.results &&
        props.data.response.results.length > 0;

    return (
        <div className="py-4">
            <div className="row">
                <div className="mb-4 col-lg-12">
                    <Title title={"Trackeo"} subtitle={"de Vehiculos"} />
                    <div className="mb-4 card card-small p-2 p-md-5">
                        <div className="w-100 d-flex flex-column-responsive flex-wrap mb-4">
                            <CardTracking
                                title={"Activo"}
                                total={
                                    props.data.response
                                        ? props.data.response.active
                                        : 0
                                }
                                className={"title-circle-warging"}
                            />
                            <CardTracking
                                title={"Crítico"}
                                total={
                                    props.data.response
                                        ? props.data.response.critical
                                        : 0
                                }
                                className={"title-circle-orange"}
                            />
                            <CardTracking
                                title={"Solicitar apoyo"}
                                total={
                                    props.data.response
                                        ? props.data.response.requesting_support
                                        : 0
                                }
                                className={"title-circle-red"}
                            />
                            <CardTracking
                                title={"Disponible"}
                                total={
                                    props.data.response
                                        ? props.data.response.disponibility
                                        : 0
                                }
                                className={"title-circle-green"}
                            />
                        </div>
                        <div className="w-100 d-flex flex-column-responsive flex-wrap mb-4">
                            <div className="flex-1">
                                <Field
                                    name="statusEmergency"
                                    placeholder={"Estado de Emergencia"}
                                    component={SelectField}
                                    customChange={props.filter_by_status}
                                    options={STATUS_EMERGENCY_TRACKING}
                                />
                            </div>
                            <div className="flex-3">
                            </div>
                        </div>

                        {validate && props.me.station ? (
                            <div className="w-100" style={{ height: "600px" }}>
                                <Map
                                    mapContainerStyle={{
                                        width: "100%",
                                        height: "100%",
                                    }}
                                    fromLat={
                                        val && lat_lng_reduce.length > 0
                                            ? lat_lng_reduce.reduce(
                                                  (t, { lat: l }) =>
                                                      l > t ? l : t,
                                                  0
                                              )
                                            : props.me
                                            ? props.me.station &&
                                              props.me.station.lat
                                            : 0
                                    }
                                    fromLng={
                                        val && lat_lng_reduce.length > 0
                                            ? lat_lng_reduce.reduce(
                                                  (t, { lng: l }) =>
                                                      l < t ? l : t,
                                                  0
                                              )
                                            : props.me
                                            ? props.me.station &&
                                              props.me.station.lng
                                            : 0
                                    }
                                    toLat={
                                        val && lat_lng_reduce.length > 0
                                            ? lat_lng_reduce.reduce(
                                                  (t, { lat: l }) =>
                                                      l < t ? l : t,
                                                  lat_lng_reduce[0].lat
                                              )
                                            : props.me
                                            ? props.me.station &&
                                              props.me.station.lat
                                            : 0
                                    }
                                    toLng={
                                        val && lat_lng_reduce.length > 0
                                            ? lat_lng_reduce.reduce(
                                                  (t, { lng: l }) =>
                                                      l > t ? l : t,
                                                  lat_lng_reduce[0].lng
                                              )
                                            : props.me
                                            ? props.me.station &&
                                              props.me.station.lng
                                            : 0
                                    }
                                    trackingMap={
                                        validate
                                            ? props.data.response.results.map(
                                                  (vehicle_pos) => {
                                                      const parse_json_vehicle_pos =
                                                          vehicle_pos &&
                                                          vehicle_pos.position
                                                              ? JSON.parse(
                                                                    vehicle_pos.position
                                                                )
                                                              : {};
                                                      return {
                                                          coords: parse_json_vehicle_pos
                                                              ? parse_json_vehicle_pos.coords
                                                              : {},
                                                          emergency_code:
                                                              vehicle_pos.emergency_code,
                                                          plate: vehicle_pos.plate,
                                                          emergency_type:
                                                              vehicle_pos.emergency_type,
                                                          emergency_status:
                                                              vehicle_pos.emergency_status,
                                                          pilot: vehicle_pos.pilot,
                                                          station: vehicle_pos.station,
                                                          lt: vehicle_pos.latitude,
                                                          lng: vehicle_pos.longitude,
                                                      };
                                                  }
                                              )
                                            : []
                                    }
                                />
                            </div>
                        ) : (
                            <div className="w-100 text-center">
                                <h3 className="mx-auto py-5 text-primary">
                                    No hay vehículos en trackeo
                                </h3>
                                <img
                                    src={ambulance}
                                    style={{ maxWidth: 600 }}
                                ></img>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const mstp = (state) => ({
    ...state.tracking,
});

const mdtp = {
    ...actions,
};

export default reduxForm({
    form: "ListTracking", // a unique identifier for this form
})(Tracking);

// export default connect(mstp, mdtp)(Tracking);
