import React from "react";
import AsyncSelect from "react-select/async";
import useAsyncSearch from "../../../../hooks/useAsyncSearch";

const SelectFIeld = ({ nameApiOptions, valueKey, labelKey, labelKey2, placeholder, value, setValue, isMulti = false, disabled = false }) => {
    const { asyncOptions } = useAsyncSearch(nameApiOptions);

    return (
        <AsyncSelect
            isMulti={isMulti}
            defaultOptions
            cacheOptions
            isClearable={true}
            getOptionValue={(option) => option[valueKey]}
            getOptionLabel={(option) =>
                `${option[labelKey]} ${option[labelKey2] || ""}`
            }
            placeholder={placeholder}
            onChange={(e) => {
                setValue(e ? e : null);
            }}
            value={value}
            loadOptions={asyncOptions}
            isDisabled={disabled}
        />
    );
};

export default SelectFIeld;
