import React from "react";
import { renderField } from "../../Utils/renderField/renderField";
import { Field } from "redux-form";
import { SelectField } from "../../Utils/renderField/renderField";

const OrderSearchComponent = (props) => {
    return (
        <div className={props.customClass? props.customClass: 'row mx-auto w-100 mt-3'}>
            <div className={"col-lg-8 col-sm-12 col-md-6 mb-2 px-0 d-flex flex-wrap"}>
                {props.selects
                    ? props.selects.length > 0
                        ? props.selects.map((e, index) => (
                              <div key={index} className="col-lg-3 col-md-5 col-sm-12 mb-2">
                                  <Field
                                      name={e.name}
                                      component={SelectField}
                                      options={e.data}
                                      placeholder={e.title}
                                      onChange={(a) => e.funcion(a)}
                                  />
                              </div>
                          ))
                        : null
                    : null}
            </div>
            <div className={props.customClassSearch? props.customClassSearch: "col-lg-4 col-sm-12 col-md-6 mb-2"}>
                    <div className="input-group">
                        <Field
                            name="search"
                            placeholder={"Buscar..."}
                            component={renderField}
                            padding={true}
                            onChange={(e) => props.searchChange(e.target.value)}
                        />
                        <div className="input-group-append">
                            <span className="material-icons input-group-text text-primary">
                                search
                            </span>
                        </div>
                    </div>
                </div>
        </div>
    );
};

export default OrderSearchComponent;
