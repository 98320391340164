import List from './List';
import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/emergencies/emergencies';
import { actions as emergency_type } from '../../../../redux/modules/emergencyTypes/emergencyTypes';


const ms2p = (state) => {
    return {
        ...state.emergencies,
        emergencyTypes: { ...state.emergencyTypes },
        me: state.login.me,
    };
};

const md2p = { ...actions, EmergencyTypesFilterList: emergency_type.filterList };

export default connect(ms2p, md2p)(List);
