import React, { useEffect, useState } from "react";
import { Field } from "redux-form";
// import { renderField } from "../../Utils/renderField";
// import { renderNumber } from "../../Utils/renderField/renderField";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { renderField, renderNumber } from "../../Utils/renderField";
import { validateWhiteSpace } from "../../../../utility/validation";
import { renderDoc } from "../../Utils/renderField/renderField";
import Swal from "sweetalert2";
import moment from "moment";

const ApprovedCourses = ({
    fields,
    meta: { touched, error, submitFailed },
    isView,
    list,
    updateList,
}) => {
    useEffect(() => {
        fields.push({});
    }, []);

    const [newLabel, setLabel] = useState("");
    const [newValue, setValue] = useState("");
    const [document, setDocument] = useState(null);

    let id = list && list.length !== 0 ? list[list.length - 1].id : 0;

    const addItem = () => {
        if (newLabel && !newValue || newValue && !newLabel || !newLabel && !newValue) {
            return(
                Swal.fire(
                    "¡Atención!",
                    "Debes de completar los campos faltantes para agregar un curso",
                    "warning"
                )
            )
        }
        const newList = [
            ...list,
            {
                id: (id = id + 1),
                approval_date: newValue,
                course_name: newLabel,
                name: `photos${list.length + 1}`,
                file: document,
            },
        ];
        updateList(newList);
    };
    const removeItem = (id) => {
        updateList(list.filter((item) => item.id !== id));
    };

    return (
        <React.Fragment>
            <div
                className="col-12 form-group"
                style={{ minHeight: `${fields.length * 50 + 150}px` }}
            >
                {!isView && (
                    <div className="d-flex flex-wrap">
                        <div className="col-12 col-md-4 form-group">
                            <label> Nombre del curso </label>
                            <Field
                                name="name_course"
                                disabled={isView}
                                component={renderField}
                                className="m-2"
                                type="text"
                                validate={validateWhiteSpace}
                                onChange={(e) =>
                                    setLabel(e.currentTarget.value)
                                }
                            />
                        </div>
                        <div className="col-12 col-md-4 form-group">
                            <label> Fecha de aprobación </label>
                            <Field
                                name="date"
                                disabled={isView}
                                component={renderField}
                                type={"date"}
                                className="form-control"
                                onChange={(e) =>
                                    setValue(e.currentTarget.value)
                                }
                            />
                        </div>
                        <div className="col-12 col-md-4 form-group">
                            <label> Subida de documento </label>
                            <Field
                                name="up_doc"
                                component={renderDoc}
                                setDocument={setDocument}
                            />
                        </div>
                        <div className="col-12 col-md-4 form-group">
                            <button
                                className="btn btn-primary"
                                type="button"
                                onClick={addItem}
                            >
                                Agregar Cursos
                            </button>
                        </div>
                    </div>
                )}
                {list && list.length > 0 ? (
                    <Table className="table">
                        <Thead>
                            <Tr className="table-primary">
                                <Th scope="col">#</Th>
                                <Th scope="col">
                                    Nombre del curso{" "}
                                    <span className="text-danger">*</span>
                                </Th>
                                <Th scope="col" style={{ width: "200px" }}>
                                    Fecha de aprobación{" "}
                                    <span className="text-danger">*</span>
                                </Th>
                                <Th scope="col" style={{ width: "200px" }}>
                                    Subida de documento{" "}
                                    <span className="text-danger">*</span>
                                </Th>
                                {!isView && <Th scope="col">Acciones</Th>}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {list &&
                                list.map((member, index) => (
                                    <Tr key={index}>
                                        <Th scope="row">
                                            {index + 1}
                                        </Th>
                                        <Td>
                                            <span>{member.course_name}</span>
                                        </Td>
                                        <Td>
                                            <span>{member.approval_date ? moment(member.approval_date).format("DD-MM-YYYY") : ''}</span>
                                        </Td>
                                        <Td>
                                            {isView ? (
                                                <a
                                                    href={
                                                        member.file &&
                                                        member.file.name
                                                            ? member.file.name
                                                            : member.file
                                                            ? member.file
                                                            : ""
                                                    }
                                                    target="blank"
                                                >
                                                    {member.file &&
                                                    member.file.name
                                                        ? member.file.name
                                                        : member.file
                                                        ? String(
                                                              member.file
                                                          ).replace(
                                                              "/media/docs/",
                                                              ""
                                                          )
                                                        : ""}
                                                </a>
                                            ) : (
                                                <span>
                                                    {member.file &&
                                                    member.file.name
                                                        ? member.file.name
                                                        : member.file
                                                        ? String(
                                                              member.file
                                                          ).replace(
                                                              "/media/docs/",
                                                              ""
                                                          )
                                                        : ""}
                                                </span>
                                            )}
                                        </Td>
                                        {!isView && (
                                            <Td>
                                                <button
                                                    type="button"
                                                    title="Eliminar"
                                                    className="btn btn-danger"
                                                    onClick={() =>
                                                        removeItem(member.id)
                                                    }
                                                >
                                                    <span
                                                        style={{
                                                            fontSize: "20px",
                                                        }}
                                                        className="material-icons"
                                                    >
                                                        close
                                                    </span>
                                                </button>
                                            </Td>
                                        )}
                                    </Tr>
                                ))}
                        </Tbody>
                    </Table>
                ) : (
                    <p>
                        {" "}
                        {isView
                            ? "Sin cursos registrados"
                            : "Presione el botón 'Agregar Cursos'."}
                    </p>
                )}
            </div>
        </React.Fragment>
    );
};

export default ApprovedCourses;
