import { connect } from "react-redux";
import { actions } from "../../../../redux/modules/station/station";


import Create from "./Create";

const ms2p = (state) => {
    return {
        ...state.station,
        vehicles: { ...state.vehicles },
        users: { ...state.users },
    };
};

const md2p = {
    ...actions,
};

export default connect(ms2p, md2p)(Create);
