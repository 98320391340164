import React from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm, FieldArray, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { renderField } from "../../Utils/renderField";
import { validate, validators } from "validate-redux-form";
import {
    SelectField,
    renderRichTextArea,
    renderFilePicker,
    renderMap,
} from "../../Utils/renderField/renderField";
import renderVehicles from "./Vehicles";
import renderPacients from "./Pacients";
import renderContactPerson from "./ContactPerson";
import {
    required,
    requiredField,
    validateWhiteSpace,
} from "../../../../utility/validation";
import { change, untouch } from "redux-form";
import { ROLE_CABIN_ATTENDANT } from "../../../../utility/constants";
import Swal from "sweetalert2";

const Form = (props) => {
    const {
        handleSubmit,
        isUpdate,
        isView,
        emergency_types,
        notificactions_types,
        stations,
        getSelects,
        firefighters,
        vehicles,
        temporal_vehicle,
        setTemporalVehicle,
        selected_vehicles,
        setSelectedVehicles,
        files,
        setFiles,
        customOnChange,
        removeFiles,
        observations_value,
        mark_lat,
        mark_lng,
        pilot,
        item = {},
        me,
        setIsChangeStatusEmergency,
    } = props;
    console.log("props", item);
    return (
        <form name="loginForm" onSubmit={handleSubmit}>
            <div className="d-flex flex-wrap">
                <div className="col-12 col-md-6">
                    <h4>Datos Generales</h4>
                    <div className="form-group">
                        <label htmlFor="emergency_type">
                            Tipo de emergencia
                        </label>
                        <Field
                            name="emergency_type"
                            label="emergency_type"
                            placeholder="Tipo de emergencia"
                            disabled={isView}
                            options={emergency_types}
                            component={SelectField}
                            className="form-control"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="notice_form">
                            Modo de notificación
                        </label>
                        <Field
                            name="notice_form"
                            label="notice_form"
                            placeholder="Modo de notificación"
                            disabled={isView}
                            options={notificactions_types}
                            component={SelectField}
                            className="form-control"
                        />
                    </div>
                    {me.role !== ROLE_CABIN_ATTENDANT && (
                        <React.Fragment>
                            <div className="form-group">
                                <label htmlFor="attention_date">
                                    Fecha y hora de atención
                                </label>
                                <Field
                                    name="attention_date"
                                    label="attention_date"
                                    component={renderField}
                                    type={"datetime-local"}
                                    className="form-control"
                                />
                            </div>
                            {observations_value && isUpdate && (
                                <div className="form-group">
                                    <label htmlFor="observations">
                                        Observaciones
                                    </label>
                                    <Field
                                        name="observations"
                                        label="observations"
                                        component={renderRichTextArea}
                                        className="form-control"
                                        text_value={observations_value}
                                    />
                                </div>
                            )}

                            {(!observations_value || !isUpdate) && (
                                <div className="form-group">
                                    <label htmlFor="observations">
                                        Observaciones
                                    </label>
                                    <Field
                                        name="observations"
                                        label="observations"
                                        component={renderRichTextArea}
                                        className="form-control"
                                        text_value={observations_value}
                                    />
                                </div>
                            )}
                        </React.Fragment>
                    )}
                </div>
                <div className="col-12 col-md-6">
                    <h4>Dirección</h4>
                    <div className="form-group">
                        <label htmlFor="direction">Dirección</label>
                        <Field
                            type="text"
                            name="address"
                            label="address"
                            disabled={isView}
                            component={renderField}
                            validate={validateWhiteSpace}
                            className="form-control"
                        />
                    </div>
                    {me.role !== ROLE_CABIN_ATTENDANT && (
                        <div className="form-group">
                            <label htmlFor="latlng">Latitud y longitud</label>
                            <Field
                                name="latlng"
                                label="latlng"
                                disabled={isView}
                                component={renderMap}
                                mark_lat={mark_lat}
                                mark_lng={mark_lng}
                                className="form-control"
                                heigth={"100%"}
                                width={"100%"}
                            />
                        </div>
                    )}
                </div>

                <div className="col-12 form-group">
                    <h4>Persona de Contacto</h4>
                </div>
                <FieldArray
                    name="contact_person"
                    component={renderContactPerson}
                    isView={isView}
                />

                <div className="col-12 form-group">
                    <h4>Estaciones y Bomberos</h4>
                </div>
                <div className="col-12 col-md-6 form-group">
                    <label htmlFor="stations">Estaciones</label>
                    <Field
                        name="stations"
                        label="stations"
                        placeholder="Estaciones"
                        disabled={isView}
                        customOnChange={getSelects}
                        isMulti={true}
                        options={stations}
                        component={SelectField}
                        className="form-control"
                    />
                </div>
                <div className="col-12 col-md-6 form-group">
                    <label htmlFor="telephonist">Piloto</label>
                    <Field
                        name="telephonist"
                        label="telephonist"
                        placeholder="Piloto"
                        disabled={!isUpdate && pilot.length == 0}
                        options={pilot}
                        component={SelectField}
                        className="form-control"
                    />
                </div>
                {me.role !== ROLE_CABIN_ATTENDANT && (
                    <div className="col-12 col-md-6 form-group">
                        <label htmlFor="firefighters">
                            Bomberos asistentes
                        </label>
                        <Field
                            name="firefighters"
                            label="firefighters"
                            placeholder="Bomberos asistentes"
                            disabled={isView || firefighters.length == 0}
                            options={firefighters}
                            isMulti={true}
                            component={SelectField}
                            className="form-control"
                        />
                    </div>
                )}
                <div className="col-12 form-group">
                    <h4>Vehículos</h4>
                </div>
                <FieldArray
                    name="members"
                    component={renderVehicles}
                    vehicles={vehicles}
                    isView={isView}
                    temporal_vehicle={temporal_vehicle}
                    setTemporalVehicle={setTemporalVehicle}
                    selected_vehicles={selected_vehicles}
                    setSelectedVehicles={setSelectedVehicles}
                    pilot={pilot}
                    role={me && me.role}
                />
                {me.role !== ROLE_CABIN_ATTENDANT && (
                    <React.Fragment>
                        <div className="col-12 form-group">
                            <h4>Pacientes</h4>
                        </div>
                        <FieldArray
                            name="pacients"
                            component={renderPacients}
                            isView={isView}
                        />

                        <div className="col-12 form-group">
                            <h4>Anexos</h4>
                        </div>
                        {files &&
                            files.map((e, i) => {
                                const type = typeof e;
                                return (
                                    <div
                                        key={i}
                                        className="col-12 col-md-6 form-group d-flex flex-column align-items-end"
                                    >
                                        <button
                                            type="button"
                                            title="Eliminar"
                                            className="btn btn-danger"
                                            onClick={() => removeFiles(i)}
                                        >
                                            <span
                                                style={{ fontSize: "20px" }}
                                                className="material-icons"
                                            >
                                                close
                                            </span>
                                        </button>
                                        {type == "object" ? (
                                            <img
                                                alt="fotografia"
                                                src={
                                                    "../../../../../../.." +
                                                    e.path
                                                }
                                                width="200"
                                                className="rounded mx-auto d-block"
                                            />
                                        ) : (
                                            <img
                                                alt="fotografia"
                                                src={e}
                                                width="200"
                                                className="rounded mx-auto d-block"
                                            />
                                        )}
                                    </div>
                                );
                            })}
                        <div className="col-12 col-md-6 form-group">
                            <label>Fotografia</label>
                            <Field
                                name="photo"
                                label={"Subir imagen"}
                                disabled={isView}
                                customOnChange={customOnChange}
                                no_show={true}
                                component={renderFilePicker}
                                setFile={setFiles}
                            />
                        </div>
                    </React.Fragment>
                )}

                <div className="col-12 d-flex flex-column flex-md-row justify-content-center">
                    <div className="flex-3"></div>
                    <div className="flex-1 d-flex flex-column flex-md-row justify-content-center">
                        <Link
                            to={"/emergencies"}
                            className={
                                isView
                                    ? "btn btn-secondary px-3 py-3 m-2 w-100"
                                    : "btn btn-danger py-3 mt-3 mb-3 w-100"
                            }
                        >
                            {isView ? "Atras" : "Cancelar"}
                        </Link>
                        {!isView && (
                            <button
                                type="submit"
                                className="btn btn-success align-self-center py-3 m-3 w-100"
                                onClick={(event)=>{
                                    if (isUpdate && item && item.status !== "Resuelta"){
                                        event.preventDefault();
                                        Swal.fire({
                                            title: '¿Desea cambiar el estado de la emergencia a "Resuelta" ?',
                                            icon: 'warning',
                                            showCancelButton: true,
                                            confirmButtonColor: '#3085d6',
                                            cancelButtonColor: '#d33',
                                            confirmButtonText: 'Si, cambiar!',
                                            cancelButtonText: 'No'
                                            }).then((result) => {
                                            if (result.isConfirmed) {
                                                setIsChangeStatusEmergency(true);
                                                Swal.fire(
                                                'Cambiado!',
                                                'El estado de la emergencia ha cambiado a resuelta.',
                                                'success'
                                                )
                                            }
                                            // seguir con el submit
                                            handleSubmit();
                                            }
                                        )
                                    }
                                }}
                            >
                                {isUpdate ? "Actualizar" : "Crear"}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </form>
    );
};

const selector = formValueSelector("EmergencyForm"); // select current form
// Decorate form with selected redux form state values
const mapStateToProps = (state) => {
    const firefighterValue = selector(state, "firefighters");
    const stationValue = selector(state, "stations");
    return {
        firefighterValue,
        stationValue,
    };
};

// Decorate form with dispatchable actions
const mapDispatchToProps = {
    change,
};

export default reduxForm({
    form: "EmergencyForm",
    validate: (data) => {
        let validates = null;
        if (data.latlng) {
            validates = validators.exists()("Este campo es requerido");
        }
        return validate(data, {
            address: validators.exists()("Este campo es requerido"),
            emergency_type: validators.exists()("Este campo es requerido"),
            attention_date: validators.exists()("Este campo es requerido"),
            notice_form: validators.exists()("Este campo es requerido"),
            stations: validators.exists()("Este campo es requerido"),
            firefighters: validators.exists()("Este campo es requerido"),
            telephonist: validators.exists()("Este campo es requerido"),
            vehicles: validators.exists()("Este campo es requerido"),
            members: validators.exists()("Este campo es requerido"),
            pacients: validators.exists()("Este campo es requerido"),
            observations: validators.exists()("Este campo es requerido"),
            latlng: validates,
        });
    },
})(connect(mapStateToProps, mapDispatchToProps)(Form));
