import React from "react";
import { reduxForm } from "redux-form";

const Form = (props) => {
    return (
        <React.Fragment>
            <h3>Sección en proceso...</h3>
        </React.Fragment>
    );
};

export default reduxForm({
    form: "ConfigurationForm", // a unique identifier for this form
})(Form);
