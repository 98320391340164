import { Text, View } from "@react-pdf/renderer";
import React from "react";

const Table = ({ data_pdf }) => {
    return (
        <View
            style={{
                position: "relative",
                left: "0",
                top: "0",
                backgroundColor: "#fcfcfc",
                borderRadius: "30",
                minHeight: "620",
                fontSize: "11px",
                fontWeight: "600",
            }}
        >
            <View
                fixed
                style={{
                    backgroundColor: "#2350C7",
                    minHeight: "40",
                    borderTopLeftRadius: "30px",
                    borderTopRightRadius: "30px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "0 15px",
                    position: "relative",
                }}
            >
                {/* Titles */}
                <View
                    style={{
                        flex: 1,
                        padding: "5",
                        display: "flex",
                        alignItems: "center",
                        color: "white",
                        fontWeight: "bold",
                    }}
                >
                    <Text>DPI</Text>
                </View>
                <View
                    style={{
                        flex: 1,
                        padding: "5",
                        display: "flex",
                        alignItems: "center",
                        color: "white",
                    }}
                >
                    <Text>Nombre</Text>
                </View>

                <View
                    style={{
                        flex: 1,
                        padding: "5",
                        display: "flex",
                        alignItems: "center",
                        color: "white",
                    }}
                >
                    <Text style={{ fontWeight: "bold" }}>Estación</Text>
                </View>

                <View
                    style={{
                        flex: 1,
                        padding: "5",
                        display: "flex",
                        alignItems: "center",
                        color: "white",
                        fontWeight: "bold",
                    }}
                >
                    <Text>Estado</Text>
                </View>
                <View
                    style={{
                        flex: 1,
                        padding: "5",
                        display: "flex",
                        alignItems: "center",
                        color: "white",
                        fontWeight: "bold",
                    }}
                >
                    <Text>Horas</Text>
                    <Text>Totales</Text>
                </View>
            </View>
            {data_pdf.map((user, index) => (
                <View
                    wrap={false}
                    style={{
                        minHeight: "30",
                        borderBottom: "1.5px solid #ABAFB2",
                        margin: "0 15px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                    key={index}
                >
                    <View
                        style={{
                            flex: 1,
                            padding: "5",
                            display: "flex",
                            alignItems: "center",
                            color: "#212529",
                        }}
                    >
                        <Text>{user.no_dpi}</Text>
                    </View>
                    <View
                        style={{
                            flex: 1,
                            padding: "5",
                            display: "flex",
                            alignItems: "center",
                            color: "#212529",
                        }}
                    >
                        <Text>{user.first_name}</Text>
                        <Text>{user.last_name}</Text>
                    </View>
                    <View
                        style={{
                            flex: 1,
                            padding: "5",
                            display: "flex",
                            alignItems: "center",
                            color: "#212529",
                        }}
                    >
                        <Text>{user.station_text}</Text>
                    </View>
                    <View
                        style={{
                            flex: 1,
                            padding: "5",
                            display: "flex",
                            alignItems: "center",
                            color: "#212529",
                        }}
                    >
                        <Text>{user.status}</Text>
                    </View>
                    <View
                        style={{
                            flex: 1,
                            padding: "5",
                            display: "flex",
                            alignItems: "center",
                            color: "#212529",
                        }}
                    >
                        <Text>{user.total_hours_day}</Text>
                    </View>
                </View>
            ))}
        </View>
    );
};

export default Table;
