import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { combine, validate, validatorFromFunction, validators } from 'validate-redux-form';
import { email } from '../../../../utility/validation';
import { renderField } from '../../Utils/renderField';
import { renderFieldCheck } from '../../Utils/renderField/renderField';

const RecoverForm = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;
    return (
        <form name="loginForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className="form-group has-feedback">
                <Field
                    name="password"
                    label="Nueva contraseña"
                    component={renderField}
                    type="password"
                    className="form-control"
                    placeholder="Nueva contraseña"
                />
            </div>
            <div className="form-group has-feedback">
                <Field
                    name="confirm_password"
                    label="Confirma la contraseña"
                    component={renderField}
                    type="password"
                    className="form-control"
                    placeholder="Confirma la contraseña"
                />
            </div>
            <div className="buttons-box">
                <button type="submit" className="btn btn-primary btn-block m-1 align-self-center font-weight-bold">Cambiar contraseña</button>
            </div>
        </form>
    );
};

export const matchEmail = (data) => validatorFromFunction(() => email(data) == null ? true : false);

export default reduxForm({
    form: 'forgot', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            password: combine(
                validators.exists()('Este campo es requerido'),
            ),
            confirm_password: combine(
                validators.exists()('Este campo es requerido'),
                validators.equals(data.password)('Los emails no coinciden')
            ),
        });
    },
})(RecoverForm);
