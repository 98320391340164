import moment from "moment";
import React, { useState, useEffect } from "react";

const DICCTIONARY = {
    id: "ID",
    creation_date: "Fecha Creación",
    update_date: "Fecha Actualización",
    latitude: "Latitud",
    longitude: "Longitud",
    lat: "Latitud",
    lng: "Longitud",
    name: "Nombre",
    phone_number: "Número de Teléfono",
    address: "Dirección",
    state_text: "Estado",
    no_station: "Número de Estacion",
    region_type: "Región",
    status: "Estado",
    attention_date: "Fecha de atención",
    resolved_date: "Fecha Resuelta",
    observations: "Observaciones",
    notice_form: "Modo de notificación",
    emergency_type_text: "Tipo de Emergencia",
    telephonist_text: "Piloto",
    first_name: "Nombre",
    last_name: "Apellido",
    email: "Correo",
    no_registry: "No. de Registro",
    no_dpi: "DPI",
    photo: "Foto",
    aproved_subjects: "Cursos Aprobados",
    is_pilot: "Es piloto",
    no_license: "Número de licencia",
    type_license: "Tipo de licencia",
    firefighter_type: "Tipo de Bombero",
    name_role: "Rol",
    user_range_text: "Rango",
    station: "Estación",
    station_text: "Estación",
    code: "Código",
    description: "Descripción",
    members: "Vehículos",
    firefighters_text: "Bomberos asistentes",
    pacients: "Pacientes",
    contact_person: "Personas de contacto",
    observation_replace: "Observaciones",
    stations_text: "Estaciones",
    label: "Placa",
    km_inicial: "KM Inicial",
    km_final: "KM Final",
    active: "Activo",
    age: "Edad",
    genre: "Genero",
    moved_to: "Se trasladó a",
    images: "Fotografías",
    nomenclature: "Nomenclatura",
    model: "Modelo",
    plate: "Placa",
    unit: "Unidad",
    disponibility_text: "Disponibilidad",
    year: "Año",
    note: "Nota",
    vehicle_type_text: "Tipo de vehículo",
    approved_courses: "Cursos Aprobados",
    course_name: "Nombre Curso",
    approval_date: "Fecha de Aprobación",
    file: "Archivo",
    inactive_reason: "Rason Inactivo",
    job_title: "Nombre del puesto",
    date_of_birth: "Fecha de Nacimiento",
    Estación: "Estación",
    "Tipo de contratación": "Tipo de contratación",
    Status: "Estado",
    "Archivo de aceptación": "Archivo de aceptación",
    user_binnacle: "Usuario",
    start_date_unsubscribe: "Fecha Inicio Baja",
    end_date_unsubscribe: "Fecha Fin Baja",
    register_file: "Archivo de registro",
    date_entry: "Fecha ingreso",
    date_exit: "Fecha Salida",
    observation: "Observacion",
    qr_emergency: "QR de la emergencia",
    created_user: "Creado por",
};

const useBinncale = (bitacoraDatos) => {
    // Estado para almacenar el detalle actual del registro
    const [detalleActual, setDetalleActual] = useState({});
    // Estado para almacenar el detalle anterior del registro
    const [detalleAnterior, setDetalleAnterior] = useState({});
    // Estado para almacenar el detalle los datos generales del registro
    const [detalle_registro, setDetalleRegistro] = useState({
        created: "",
        usuario: "",
        accion: "",
    });

    // Varibles para aplicar estilos (bg-gray) al dato del detalle del registro
    const indice = {
        anterior: 0,
        actual: 0,
    };

    // desestructurando los datos
    const {
        current_detail: detalle,
        previous_detail: detalle_anterior,
        creation_date: created,
        created_by_name: usuario,
        action: accion,
    } = bitacoraDatos;

    useEffect(() => {
        // si detalle esta definido
        setDetalleActual({});
        if (detalle) {
            const parse_json = JSON.parse(detalle);
            setDetalleActual(parse_json);
        }

        // si detalle_anterior esta definido
        setDetalleAnterior({});
        if (detalle_anterior) {
            const parse_json = JSON.parse(detalle_anterior);
            setDetalleAnterior(parse_json);
        }

        // si usuario, created y accion estan definidos
        if (usuario && created && accion) {
            setDetalleRegistro({ created, usuario, accion });
        }
    }, [bitacoraDatos]);

    // funcion para retornar los datos del detalle con estilos
    const formatearDatosDetalle = (
        _detalle,
        _detalle_actual,
        es_subhijo = false,
        key_objeto = "",
        key_objeto2 = ""
    ) => {
        return Object.keys(_detalle).map(function (key, index) {
            // if, para no mostrar los datos del id, active, created y modified
            if (
                key !== "id" &&
                key !== "action" &&
                key !== "state" &&
                key !== "created_by_name" &&
                key !== "creation_date" &&
                key !== "update_date" &&
                key !== "username" &&
                key !== "is_superuser" &&
                key !== "is_staff" &&
                key !== "user_range" &&
                key !== "role" &&
                key !== "station" &&
                key !== "emergency_id" &&
                key !== "vehicles" &&
                key !== "images_id" &&
                key !== "stations" &&
                key !== "firefighters" &&
                key !== "vehicle" &&
                key !== "observations" &&
                key !== "emergency_type" &&
                key !== "telephonist" &&
                key !== "vehicle_type" &&
                key !== "disponibility" &&
                key !== "pilot" &&
                key !== "user" &&
                key !== "job_management" &&
                key !== "category" &&
                (es_subhijo ? "active" : key !== "active")

                // (!es_subhijo && key !== "active")
            ) {
                // si el tipo de indice es para detalle anterior o detalle actual
                let indiceValidar = _detalle_actual
                    ? indice.actual
                    : indice.anterior;
                // sumar en 1 el indice anterior, para que los estilos se aplican si es un numero par o inpar
                if (_detalle_actual) {
                    // si el tipo de detalle es actual (true)
                    indice.actual += 1;
                } else {
                    // sino es anterior (false)
                    indice.anterior += 1;
                }

                const validate_recursividad =
                    es_subhijo &&
                    key_objeto &&
                    key_objeto2 &&
                    detalleAnterior[key_objeto] !== undefined &&
                    detalleAnterior[key_objeto][key_objeto2] !== undefined;

                let item_recursividad = false;

                if (
                    validate_recursividad &&
                    detalleActual[key_objeto] !== undefined
                ) {
                    if (detalleAnterior[key_objeto].length > 1) {
                        const size_anterior =
                            detalleAnterior[key_objeto].length;
                        const size_actual = detalleActual[key_objeto].length;
                        if (size_anterior < size_actual) {
                            const object_empty = {};
                            Object.keys(
                                detalleActual[key_objeto][key_objeto2]
                            ).map((value) => {
                                object_empty[value] = "---";
                            });
                            const recorrido = size_actual - size_anterior;

                            for (let i = 0; i < recorrido; i++) {
                                detalleAnterior[key_objeto].push(object_empty);
                            }
                            setDetalleAnterior({
                                ...detalleAnterior,
                                [key_objeto]: detalleAnterior[key_objeto],
                            });
                        }

                        try {
                            item_recursividad =
                                detalleAnterior[key_objeto][key_objeto2][
                                    key
                                ] !==
                                detalleActual[key_objeto][key_objeto2][key];
                        } catch (error) {
                            try {
                                item_recursividad = true;
                                item_recursividad =
                                    detalleAnterior[key_objeto][key_objeto2][
                                        key
                                    ] !==
                                    detalleActual[key_objeto][key_objeto2 - 1][
                                        key
                                    ];
                                const object_empty = {};
                                Object.keys(
                                    detalleAnterior[key_objeto][key_objeto2]
                                ).map((value) => {
                                    object_empty[value] = "---";
                                });
                                detalleActual[key_objeto].push(object_empty);
                            } catch (e) {
                                const object_empty = {};
                                Object.keys(
                                    detalleAnterior[key_objeto][key_objeto2]
                                ).map((value) => {
                                    object_empty[value] = "---";
                                });
                                detalleActual[key_objeto].push(object_empty);
                                item_recursividad = true;
                            }
                        }
                    }
                }

                return (
                    <div
                        className={`bitacora-card ${
                            indiceValidar % 2 === 0 ? "bg-gray" : ""
                        } mx-3 px-3 ${
                            _detalle[key] instanceof Object
                                ? ""
                                : validate_recursividad
                                ? item_recursividad &&
                                  `binnacle-edit ${
                                      !_detalle_actual ? "txt-black" : "txt-red"
                                  }`
                                : detalleAnterior[key] !== detalleActual[key] &&
                                  `binnacle-edit ${
                                      !_detalle_actual ? "txt-black" : "txt-red"
                                  }`
                        }`}
                        key={index}
                    >
                        {/* Title */}
                        <strong>
                            {Number.isInteger(parseInt(key))
                                ? parseInt(key) + 1
                                : DICCTIONARY[key]}
                            :{" "}
                        </strong>
                        {/* content */}
                        {_detalle[key] === true ? (
                            "Si"
                        ) : _detalle[key] === false ? (
                            "No"
                        ) : Array.isArray(_detalle[key]) === false &&
                          Array.isArray(_detalle) === false ? (
                            _detalle[key] === null ? (
                                ""
                            ) : key === "Archivo de aceptación" ||
                              key === "register_file" ? (
                                <a
                                    style={{color: "red"}}
                                    href={"media/" + _detalle[key]}
                                    target="_blank"
                                >
                                    {" "}
                                    {_detalle[key].replace("docs/", "")}{" "}
                                </a>
                            ) : (
                                key == 'date_entry' || key == 'date_exit' ? 
                                    moment(_detalle[key]).tz('America/Guatemala').format("DD-MM-YYYY HH:mm:ss")
                                : _detalle[key]
                            )
                        ) : (
                            ""
                        )}
                        {_detalle[key] instanceof Object &&
                            formatearDatosDetalle(
                                _detalle[key],
                                _detalle_actual,
                                true,
                                es_subhijo ? key_objeto : key,
                                key
                            )}
                    </div>
                );
            }
        });
    };
    return {
        detalleActual,
        detalleAnterior,
        detalle_registro,
        formatearDatosDetalle,
    };
};

export default useBinncale;
