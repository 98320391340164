import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/reports/human_resources';
import List from './List';

const ms2p = (state) => {
    return {
        ...state.report_human_resources,
        me: state.login.me,
    }
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(List);