import moment from 'moment-timezone'
import React, { useEffect } from "react";
import Filters from "../Utils/Filters/Filters";
import { Field, reduxForm } from "redux-form";
import Grid from "../Utils/Grid";
import { standardActions } from "../Utils/Grid/StandardActions";
import Title from "../Utils/Headers/Title";

const Binnacle = ({
    getBinnacle,
    data_binnacle,
    start_date,
    setStartDate,
    end_date,
    setEndDate,
    loader,
    user_filter,
    setUserBinnacle,
    action_binnacle,
    setActionBinnacle,
}) => {
    useEffect(() => {
        getBinnacle();
    }, []);

    // moment(resource.end_date).format("YYYY-MM-DD")
    const columns = [
        {
            dataField: "creation_date",
            text: "Fecha",
            formatter: (cell, row) => (
                <span>
                    {moment(row.creation_date).tz('America/Guatemala').format("DD-MM-YYYY HH:mm:ss")}
                </span>
            ),
        },
        {
            dataField: "created_by_name",
            text: "Usuario",
        },
        {
            dataField: "action",
            text: "Acción",
        },
        {
            dataField: "id",
            text: "Acciones",
            formatter: (cell, row) =>
                standardActions({
                    ver: "/binnacle",
                })(cell, row),
        },
    ];

    return (
        <div className="py-4">
            <div className="row">
                <div className="mb-4 col-lg-12">
                    <div className="mb-4 card card-small">
                        <div className="px-3 pt-4">
                            <Title
                                showCreate={false}
                                title={"Bitácora"}
                                subtitle={"Listado general de bitácora"}
                            />
                        </div>
                        <div className="d-flex flex-column flex-md-row">
                            <Filters
                                startDate={{
                                    show: true,
                                    start_date: start_date,
                                    setStartDate: setStartDate,
                                    classNameFilter: "flex-1 m-3",
                                }}
                                endDate={{
                                    show: true,
                                    end_date: end_date,
                                    setEndDate: setEndDate,
                                    classNameFilter: "flex-1 m-3",
                                }}

                                user={{
                                    show: true,
                                    user: user_filter,
                                    setUser: setUserBinnacle,
                                    classNameFilter: "flex-1 m-3",
                                }}

                                actionBinnacle={{
                                    show: true,
                                    action_binnacle: action_binnacle,
                                    setActionBinnacle: setActionBinnacle,
                                    classNameFilter: "flex-1 m-3",
                                }}
                            />
                        </div>
                        <div className="p-0 px-3 pt-3">
                            {data_binnacle && (
                                <Grid
                                    data={data_binnacle}
                                    loading={loader}
                                    columns={columns}
                                    onPageChange={getBinnacle}
                                    noDataMessage={"Sin Usuarios"}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default reduxForm({
    form: "ListBinnacle", // a unique identifier for this form
})(Binnacle);
