import React, { useEffect, useState } from "react";
import { Field, FieldArray, reduxForm } from "redux-form";
import {
    renderField,
    renderFieldCheck,
    renderNumber,
    SelectField,
    renderFilePicker,
    AsyncSelectField,
} from "../../Utils/renderField/renderField";
import {
    combine,
    validate,
    validatorFromFunction,
    validators,
} from "validate-redux-form";
import {
    roles,
    licenceType,
    USER_RANGES,
    ROLE_ADMIN,
    FIREFIGHTER_TYPE,
    USER_STATUS,
    INACTIVE_STATUS,
    CONTRACT,
} from "../../../../utility/constants";
import { Link } from "react-router-dom";
import { api } from "api";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import ApprovedCourses from "./ApprovedCourses";
import { validateDPI, validateNumber, validateWhiteSpace } from "../../../../utility/validation";

const loadStations = (search) => {
    let params = {};
    if (search) params.search = search;
    return api
        .get("station", params)
        .then((response) => {
            return response.results;
        })
        .catch((error) => {
            console.error(error);
        });
};

const loadJobManagement = (search) => {
    let params = {};
    if (search) params.search = search;
    return api
        .get("job_management", params)
        .then((response) => {
            return response.results;
        })
        .catch((error) => {
            console.error(error);
        });
};

const Form = (props) => {
    const {
        handleSubmit,
        isView,
        isUpdate,
        setAvatar,
        item,
        loading,
        valueIsPilot,
        list,
        updateList,
        isCreate = false,
        showInactive,
        setShowInactive,
        me = {},
        url = "",
    } = props;

    const status_firefighter =
        item && item.status && item.status === "Activo"
            ? { label: "Activo", value: 5 }
            : item.status === "Inactivo"
            ? { label: "Inactivo", value: 1 }
            : {};
    const [status, setStatus] = useState(status_firefighter);

    useEffect(() => {
        setShowInactive(
            item && item.status && item.status === "Activo"
                ? false
                : item.status === "Inactivo"
                ? true
                : false
        );
    }, []);
    const validateChange = (e) => {
        const value =
            e && e === 5
                ? { label: "Activo", value: 5 }
                : e === 1
                ? { label: "Inactivo", value: 1 }
                : {};

        e && e === 5
            ? setShowInactive(false)
            : e === 1
            ? setShowInactive(true)
            : setShowInactive(false);
        setStatus(value);
    };

    return (
        <form onSubmit={handleSubmit}>
            <LoadMask loading={loading} dark blur>
                <h4 className="pl-3">Datos Generales</h4>
                <hr className="line-detail m-3" />
                <div className="d-flex flex-wrap">
                    <div className="d-flex flex-column flex-1 mx-3">
                        <div className="form-group has-feedback">
                            <label> No. de DPI </label>
                            <Field
                                name="no_dpi"
                                disabled={isView}
                                component={renderNumber}
                                className="m-2"
                                numberFormat={"#### ##### ####"}
                                validate={validateDPI}
                            />
                        </div>
                        <div className="form-group has-feedback">
                            <label> Nombres </label>
                            <Field
                                name="first_name"
                                component={renderField}
                                disabled={isView}
                                className="m-2"
                                type="text"
                                validate={validateWhiteSpace}
                            />
                        </div>
                        <div className="form-group has-feedback">
                            <label> Apellidos </label>
                            <Field
                                name="last_name"
                                component={renderField}
                                disabled={isView}
                                className="m-2"
                                type="text"
                                validate={validateWhiteSpace}
                            />
                        </div>

                        <div className="form-group has-feedback">
                            <label>Correo Electronico</label>
                            <Field
                                name="email"
                                component={renderField}
                                disabled={isView}
                                className="m-2"
                                type="email"
                            />
                        </div>

                        <div className="form-group has-feedback">
                            <label>Fecha de Nacimiento</label>
                            <Field
                                name="date_of_birth"
                                label="date_of_birth"
                                component={renderField}
                                disabled={isView}
                                type={"date"}
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-6 form-group">
                        <label>Fotografia</label>
                        <Field
                            photo={item.photo ? item.photo : null}
                            name="photo"
                            label={"Subir imagen"}
                            disabled={isView}
                            setFile={setAvatar}
                            component={renderFilePicker}
                        />
                    </div>
                </div>

                <h4 className="pl-3">Información de contacto</h4>
                <hr className="line-detail m-3" />
                <div className="d-flex flex-wrap">
                    <div className="col-12 col-md-6 form-group">
                        <label>Número de Teléfono</label>
                        <Field
                            name="phone_number"
                            disabled={isView}
                            component={renderNumber}
                            numberFormat={"+(502) ####-####"}
                            className="m-2"
                            validate={validateNumber}
                        />
                    </div>
                    <div className="col-12 col-md-6 form-group">
                        <label>Dirección</label>
                        <Field
                            name="address"
                            disabled={isView}
                            component={renderField}
                            className="m-2"
                            type="text"
                            validate={validateWhiteSpace}
                        />
                    </div>
                    <div className="col-12 col-md-6 form-group">
                        <label> No. de Registro </label>
                        <Field
                            type="number"
                            name="no_registry"
                            disabled={isView}
                            component={renderField}
                            className="m-2"
                        />
                    </div>
                    <div className="col-12 col-md-6 form-group">
                        <label>Estación</label>
                        <Field
                            name="station"
                            component={AsyncSelectField}
                            disabled={isView}
                            loadOptions={loadStations}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.name}
                            className="m-2"
                            type="text"
                        />
                    </div>
                </div>

                <h4 className="pl-3">Información de la cuenta</h4>
                <hr className="line-detail m-3" />
                <div className="d-flex flex-wrap">
                    <div className="col-12 col-md-6 form-group">
                        <label> Rol </label>
                        <Field
                            name="role"
                            component={SelectField}
                            options={roles}
                            disabled={isView}
                            className="m-2"
                        />
                    </div>
                    <div className="col-12 col-md-6 form-group">
                        <label> Rango </label>
                        <Field
                            className="m-2"
                            name="user_range"
                            disabled={isView}
                            options={USER_RANGES}
                            component={SelectField}
                        />
                    </div>
                    <div className="col-12 col-md-6 form-group">
                        <label> Tipo Bombero </label>
                        <Field
                            className="m-2"
                            name="firefighter_type"
                            disabled={isView}
                            options={FIREFIGHTER_TYPE}
                            component={SelectField}
                        />
                    </div>
                    <div className="col-12 col-md-6 form-group mt-4">
                        <Field
                            name="is_pilot"
                            disabled={isView}
                            component={renderFieldCheck}
                            label="Es piloto"
                            className="m-2"
                        />
                    </div>
                    {valueIsPilot === true && (
                        <React.Fragment>
                            <div className="col-12 col-md-6 form-group">
                                <label> No. de licencia </label>
                                <Field
                                    name="no_license"
                                    disabled={isView}
                                    component={renderField}
                                    className="m-2"
                                    type="number"
                                />
                            </div>
                            <div className="col-12 col-md-6 form-group">
                                <label> Tipo de licencia </label>
                                <Field
                                    name="type_license"
                                    disabled={isView}
                                    component={SelectField}
                                    options={licenceType}
                                    className="m-2"
                                />
                            </div>
                        </React.Fragment>
                    )}
                    <div className="col-12 col-md-6 form-group">
                        <label>Puesto</label>
                        <Field
                            name="job_management"
                            component={AsyncSelectField}
                            disabled={isView}
                            loadOptions={loadJobManagement}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.job_title}
                            className="m-2"
                            type="text"
                        />
                    </div>
                    {!isCreate && (
                        <React.Fragment>
                            <div className="col-12 col-md-6 form-group">
                                <label> Estado del Bombero </label>
                                <Field
                                    className="m-2"
                                    name="status"
                                    disabled={true}
                                    options={USER_STATUS}
                                    component={SelectField}
                                    valueSelect={status}
                                    parse={(e) => validateChange(e)}
                                />
                            </div>
                            {showInactive && (
                                <div className="col-12 col-md-6 form-group">
                                    <label> Razón Inactivo </label>
                                    <Field
                                        className="m-2"
                                        name="inactive_reason"
                                        disabled={true}
                                        options={INACTIVE_STATUS}
                                        component={SelectField}
                                    />
                                </div>
                            )}
                        </React.Fragment>
                    )}

                    {isView ? (
                        showInactive ? (
                            <React.Fragment>
                                <div className="col-12 col-md-6 form-group">
                                    <label>Fecha inicio de baja</label>
                                    <Field
                                        name="start_date_unsubscribe"
                                        label="start_date_unsubscribe"
                                        component={renderField}
                                        disabled={isView}
                                        type={"date"}
                                        className="form-control"
                                    />
                                </div>
                                <div className="col-12 col-md-6 form-group">
                                    <label>Fecha final de baja</label>
                                    <Field
                                        name="end_date_unsubscribe"
                                        label="end_date_unsubscribe"
                                        component={renderField}
                                        disabled={isView}
                                        type={"date"}
                                        className="form-control"
                                    />
                                </div>

                                <div className="col-12 col-md-6 form-group">
                                    <label>Archivo de registro</label>
                                    <div>
                                        {item.register_file ? (
                                            <a
                                                href={
                                                    item.register_file &&
                                                    item.register_file
                                                }
                                                target="_blank"
                                            >
                                                {item.register_file.replace(
                                                    "/media/docs/",
                                                    ""
                                                )}
                                            </a>
                                        ) : (
                                            <h5>Sin Archivo de registro</h5>
                                        )}
                                    </div>
                                </div>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <div className="col-12 col-md-6 form-group">
                                    <label> Tipo de contratación </label>
                                    <Field
                                        className="m-2"
                                        name="type_of_contract"
                                        disabled={true}
                                        options={CONTRACT}
                                        component={SelectField}
                                        // valueSelect={status}
                                        parse={(e) => validateChange(e)}
                                    />
                                </div>

                                <div className="col-12 col-md-6 form-group">
                                    <label>Archivo de aceptación</label>
                                    <div>
                                        {item.acceptance_file ? (
                                            <a
                                                href={
                                                    item.acceptance_file &&
                                                    item.acceptance_file
                                                }
                                                target="_blank"
                                            >
                                                {item.acceptance_file.replace(
                                                    "/media/docs/",
                                                    ""
                                                )}
                                            </a>
                                        ) : (
                                            <h5>Sin Archivo de aceptación</h5>
                                        )}
                                    </div>
                                </div>
                            </React.Fragment>
                        )
                    ) : (
                        ""
                    )}
                    <div className="col-12 form-group">
                        <h4>Cursos Aprobados</h4>
                        <hr className="line-detail" />
                    </div>
                    <FieldArray
                        name="other"
                        component={ApprovedCourses}
                        isView={isView}
                        list={list}
                        updateList={updateList}
                    />
                </div>

                {!isView && !isUpdate && (
                    <React.Fragment>
                        <h4 className="pl-3">Accesos</h4>
                        <hr className="line-detail m-3" />
                        <div className="d-flex flex-wrap">
                            <div className="col-12 col-md-6 form-group">
                                <label> Contraseña </label>
                                <Field
                                    name="password"
                                    type="password"
                                    component={renderField}
                                    validate={validateWhiteSpace}
                                />
                            </div>
                            <div className="col-12 col-md-6 form-group">
                                <label> Confirmar contraseña </label>
                                <Field
                                    name="confirm_password"
                                    type="password"
                                    component={renderField}
                                    validate={validateWhiteSpace}
                                />
                            </div>
                        </div>
                    </React.Fragment>
                )}
                {isView && (
                    <div className="col-12 col-md-6 form-group">
                        <label>Codigo QR</label>
                            {item.qr_code ? (
                                <a
                                    href={item.qr_code && item.qr_code}
                                    target="_blank"
                                >
                                    <img
                                        className="qr-mobile"
                                        src={item.qr_code ? item.qr_code : null}
                                        alt={
                                            item.qr_code
                                                ? item.username
                                                : "code_qr.png"
                                        }
                                        disabled={isView}
                                    />
                                </a>
                            ) : (
                                <h5>Sin codigo QR</h5>
                            )}
                    </div>
                )}

                <div className="col-12 d-flex flex-column flex-md-row justify-content-center">
                    <div className="flex-3 d-flex flex-column flex-md-row justify-content-end">
                        {item.status === 1 && me.role === ROLE_ADMIN && (
                            <button
                                type="button"
                                onClick={() =>
                                    props.validateUserCreated(item.id)
                                }
                                className="btn btn-success align-self-center px-3 py-3 m-2 font-weight-bold w-100 w-md-auto"
                            >
                                Validar usuario
                            </button>
                        )}
                    </div>
                    <div className="flex-1 d-flex flex-column flex-md-row justify-content-center">
                        <Link
                            to={
                                url.includes(
                                    "/report/human_resources_report/user/"
                                )
                                    ? "/report/human_resources_report"
                                    : "/users"
                            }
                            className="btn btn-danger px-3 py-3 mb-2 mt-2 w-100"
                        >
                            {isView ? "Atras" : "Cancelar"}
                        </Link>
                        {!isView && (
                            <button
                                type="submit"
                                className="btn btn-success align-self-center px-3 py-3 m-2 font-weight-bold w-100"
                            >
                                Guardar
                            </button>
                        )}
                    </div>
                </div>
            </LoadMask>
        </form>
    );
};

export const matchPassword = (passe, confirm) =>
    validatorFromFunction(() => {
        return passe === confirm;
    });
export default reduxForm({
    form: "userForm",
    validate: (data) => {
        return validate(data, {
            username: validators.exists()("Campo requerido"),
            first_name: validators.exists()("Campo requerido"),
            last_name: validators.exists()("Campo requerido"),
            email: validators.exists()("Campo requerido"),
            phone_number: validators.exists()("Campo requerido"),
            station: validators.exists()("Campo requerido"),
            no_registry: validators.exists()("Campo requerido"),
            no_dpi: validators.exists()("Campo requerido"),
            address: validators.exists()("Campo requerido"),
            no_license: validators.exists()("Campo requerido"),
            type_license: validators.exists()("Campo requerido"),
            role: validators.exists()("Campo requerido"),
            user_range: validators.exists()("Campo requerido"),
            job_management: validators.exists()("Campo requerido"),
            password: validators.exists()("Campo requerido"),
            confirm_password: combine(
                validators.exists()("Campo requerido"),
                matchPassword(data.password, data.confirm_password)()(
                    "Las Contraseña no coinciden"
                )
            ),
        });
    },
})(Form);
