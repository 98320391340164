import Details from '../DetailOpen/EmergenceDetail';
import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/emergencies/emergencies';

const ms2p = (state) => {
    return {
        me: state.login.me,
        ...state.emergencies,
        emergencyTypes: { ...state.emergencyTypes },
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Details);
