import React, {Component, Fragment} from "react";
import Grid from "../../Utils/Grid";
import {reduxForm} from "redux-form";
import OrderSearchComponent from "../../Utils/OrderSearch/orderSearch";
import Title from "../../Utils/Headers/Title";
import {standardActions} from "../../Utils/Grid/StandardActions";
import {ROLE_ADMIN, ROLE_CABIN_ATTENDANT, VEHICLE_STATUS} from "../../../../utility/constants";
import CustomDropdown from "../../Utils/renderField/customDropdown";

class List extends Component {
    componentDidMount() {
        this.props.listarPersonalizado();
        this.props.stationFilterList(true);
        this.props.vehicleTypesFilterList(true);
        this.props.changeValueSelectVehicles();
    }
    state = {
        indexRow : null,
    }
    render() {
        const {
            listarPersonalizado,
            data,
            loader,
            onSortChange,
            searchCustomChange,
            eliminar,
        } = this.props;
        const STATUS = [{"label": "Todos", "value": null}].concat(VEHICLE_STATUS);
        const selects = [
            {
                title: "Estación",
                name: "station",
                data: this.props.station.filter_list,
                funcion: this.props.filterStation,
            },
            {
                title: "Tipo de vehículo",
                name: "vehicle_type",
                data: this.props.vehicles_type.filter_list,
                funcion: this.props.filterVehicleType,
            },
            {
                title: "Disponibilidad",
                name: "disponibility",
                data: STATUS,
                funcion: this.props.filterDisponibility,
            }
        ];

        const columns = [
            {
                dataField: "plate",
                text: "Placa",
            },
            {
                dataField: "model",
                text: "Modelo",
            },
            {
                dataField: "note",
                text: "Nota"
            },
            {
                dataField: "station.name",
                text: "Estación",
            },
            {
                dataField: "station.address",
                text: "Dirección",
            },
            {
                dataField: "disponibility",
                text: "Disponibilidad",
                formatter: (cell, row) => {
                    let color = "point-success";
                    let is_open = false;

                    if (row.disponibility == 10) {
                        color = "point-green";
                    } else if (row.disponibility == 40) {
                        color = "point-orange";
                    } else if (row.disponibility == 50) {
                        color = "point-red";
                    } else if (row.disponibility == 60) {
                        color = "point-yellow";
                    }
                    return (
                        <div className="d-flex">
                            {this.props.me.role === ROLE_CABIN_ATTENDANT || this.props.me.role === ROLE_ADMIN ?
                             <CustomDropdown
                                rowId={row.id}
                                color={color}
                                title={row.disponibility_label}
                                disabled={row.disponibility === 40}
                                options={
                                    [
                                        {value: '10', label: "Disponible", action: this.props.changeStatusVehicle, color: 'point-green'},
                                        {value: '50', label: "No disponible", action: this.props.changeStatusVehicle, color: 'point-red'},
                                        {value: '60', label: "En reparacion", action: this.props.changeStatusVehicle, color: 'point-yellow'},
                                    ]
                                }
                            /> :
                                <Fragment>
                                    <span className={"material-icons " + color}>fiber_manual_record</span>
                                    <span className="">{" "}{row.disponibility_label}{" "}</span>
                                </Fragment>
                            }
                        </div>
                    );
                },
            },
            {
                dataField: "id",
                text: "Acciones",
                formatter: (cell, row) => !!(this.props.me && this.props.me.role === ROLE_ADMIN)
                    ? standardActions({
                        ver: "/vehicles",
                        editar: "/vehicles",
                        eliminar,
                    })(cell, row)
                    : standardActions({
                        ver: "/vehicles",
                    })(cell, row)
            },
        ];

        return (
            <div className="py-4">
                <div className="row">
                    <div className="mb-4 col-lg-12">
                        <Title
                            showCreate={!!(this.props.me && this.props.me.role === ROLE_ADMIN)}
                            title={"Vehículos"}
                            linkToRedirect={"#/vehicles/create"}
                            titleCreate={"Crear vehículo"}
                            subtitle={"Listado general de Vehículos"}
                        />
                        <div className="mb-4 card card-small">
                            <OrderSearchComponent
                                searchChange={searchCustomChange}
                                selects={selects}
                            />
                            <div className="p-0 px-3 pt-3">
                                <Grid
                                    data={data}
                                    loading={loader}
                                    columns={columns}
                                    onPageChange={listarPersonalizado}
                                    onSortChange={onSortChange}
                                    noDataMessage={"Sin vehículos"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default reduxForm({
    form: "ListVehicles", // a unique identifier for this form
})(List);
