import React, { useEffect, useState } from "react";
import { Field, reduxForm } from "redux-form";
import {
    AsyncSelectField,
    renderField,
    renderFilePicker,
    SelectField,
} from "../../../Utils/renderField/renderField";
import "../style.css";
import { api } from "api";
import { validate, validators } from "validate-redux-form";
import { CONTRACT, INACTIVE_STATUS } from "../../../../../utility/constants";
import { Link } from "react-router-dom";

const loadStations = (search) => {
    let params = {};
    if (search) params.search = search;
    return api
        .get("station", params)
        .then((response) => {
            return response.results;
        })
        .catch((error) => {
            console.error(error);
        });
};



const Form = ({
    item,
    handleSubmit,
    setAvatar,
    discharge = false,
    unsuscribe = false,
}) => {
    let status = "Sin estado";
    const [showEndDateUnsubscribe, setShowEndDateUnsubscribe] = useState(false)
    if (item.status === "Activo") {
        status = "De alta";
    } else if (item.status === "Inactivo") status = "De baja";

    return (
        <form onSubmit={handleSubmit}>
            <div className="d-flex flex-md-25 m-4">
                <div className="flex-2">
                    <div className="d-flex flex-column flex-1 mx-3">
                        {discharge && (
                            <React.Fragment>
                                <div className="form-group has-feedback">
                                    <label> Asingar estación: </label>
                                    <Field
                                        name="station"
                                        component={AsyncSelectField}
                                        loadOptions={loadStations}
                                        getOptionValue={(option) => option.id}
                                        getOptionLabel={(option) => option.name}
                                        className="m-2"
                                        type="text"
                                    />
                                </div>
                                <div className="form-group has-feedback">
                                    {
                                        item.firefighter_type !== 'Ad honorem' &&
                                        (
                                            <React.Fragment>
                                                <label> Tipo de contratación: </label>
                                                <Field
                                                    className="m-2"
                                                    name="type_of_contract"
                                                    options={CONTRACT}
                                                    component={SelectField}
                                                />
                                            </React.Fragment>
                                        )
                                    }
                                </div>
                            </React.Fragment>
                        )}

                        {unsuscribe && (
                            <React.Fragment>
                                <div className="form-group has-feedback">
                                    <label> Motivo de baja: </label>
                                    <Field
                                        className="m-2"
                                        name="inactive_reason"
                                        options={INACTIVE_STATUS}
                                        component={SelectField}
                                        onChange={(event, newValue) =>{
                                            (newValue === 4) || (newValue === 3) ? setShowEndDateUnsubscribe(true) : setShowEndDateUnsubscribe(false)
                                        }}
                                    />
                                </div>

                                <div className="form-group has-feedback">
                                    <label> Fecha inicial de baja </label>
                                    <Field
                                        name="start_date_unsubscribe"
                                        component={renderField}
                                        type={"date"}
                                        className="form-control"
                                    />
                                </div>

                                <div className="form-group has-feedback">
                                    <label> Fecha final de baja </label>
                                    <Field
                                        name="end_date_unsubscribe"
                                        component={renderField}
                                        type={"date"}
                                        className="form-control"
                                        disabled={showEndDateUnsubscribe}
                                    />
                                </div>
                            </React.Fragment>
                        )}

                        <div className="col-12 col-md-6 form-group">
                            <label>{discharge ? "Archivo de aceptación" : unsuscribe && "Archivo de registro"}</label>
                            <Field
                                photo={null}
                                name={discharge ? "acceptance_file" : unsuscribe && "register_file"}
                                label={
                                    "Arrastra o haz click para subir un archivo"
                                }
                                setFile={setAvatar}
                                component={renderFilePicker}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex-2 bk-grey section_info">
                    <div>
                        <div className="form-group has-feedback">
                            <label> Usuario </label>
                            <h4>
                                {" "}
                                {item
                                    ? item.first_name + " " + item.last_name
                                    : ""}{" "}
                            </h4>
                        </div>
                        <div className="form-group has-feedback">
                            <label> DPI </label>
                            <h4> {item ? item.no_dpi : ""} </h4>
                        </div>
                        <div className="form-group has-feedback">
                            <label> Estado </label>
                            <h4> {status} </h4>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 d-flex flex-column flex-md-row justify-content-center">
                <div className="flex-3 d-flex flex-column flex-md-row justify-content-end"></div>
                <div className="flex-1 d-flex flex-column flex-md-row justify-content-center">
                    <Link
                        to="/human_resources/unsubscribe_discharge"
                        className="btn btn-danger px-3 py-3 mb-2 mt-2 w-100"
                    >
                        Cancelar
                    </Link>
                    <button
                        type="submit"
                        className="btn btn-success align-self-center px-3 py-3 m-2 font-weight-bold w-100"
                    >
                        Guardar
                    </button>
                </div>
            </div>
        </form>
    );
};

export default reduxForm({
    form: "userForm",
    validate: (data) => {
        return validate(data, {
            station: validators.exists()("Campo requerido"),
            type_of_contract: data.firefighter_type !== 'Ad honorem' && validators.exists()("Campo requerido"),
            inactive_reason: validators.exists()("Campo requerido"),
            start_date_unsubscribe: validators.exists()("Campo requerido"),
        });
    },
})(Form);
