import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {Link, Redirect} from 'react-router-dom';
import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/cuenta/login';
import ForgotForm from './ForgotForm';
import LoadMask from "Utils/LoadMask/LoadMask";
const Logo = require('../../../../../assets/img/logo_bomberos.png');
import './forgot.css';

class ForgotPassword extends Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };

    state = {
        email: undefined,
        username: undefined,
    }

    onSubmit = (values) => {
        this.setState({ email: values.email });
        this.setState({ username: values.username });
        this.props.onSubmitForgot(values);
    }

    sendAgain = () => {
        this.props.onSubmitForgot({ email: this.state.email, username: this.state.username}, true);
    }

    render() {
        const { loader, emailSent } = this.props;
        if (localStorage.getItem('token')) {
            return (<Redirect to="/" />);
        }
        return (
            <div className="custom-background vh-100 p-3 d-flex align-items-center justify-content-center">
                <div className="flex-1 d-flex justify-content-center align-items-center h-100">
                    <div className="card d-flex flex-column p-3 p-md-4 p-lg-5">
                        <div className="flex-1 d-flex justify-content-start align-items-center imgLogo">
                            <img className="img-fluid " src={Logo} alt="logo bomberos"/>
                        </div>
                        <div className="flex-1-5 d-flex mt-3 align-items-center">
                            <div className="w-100">
                                <LoadMask loading={loader} light>
                                    {!emailSent
                                    ? <div className="d-flex flex-wrap">
                                            <div className="w-100">
                                                <h1 className="text-primary font-weight-bold mb-0"> ¿Olvidaste tu contraseña? </h1>
                                                <h6 className="text-secondary mb-4">No te preocupes, es posible recuperarla </h6>
                                                <ForgotForm onSubmit={this.onSubmit} />
                                                <div className="text-center mt-3">
                                                    <span className="mt-4">¡Olvidalo!&nbsp;<Link to="/login" className="font-weight-bold text-secondary">La he recordado</Link></span>
                                                </div>
                                            </div>
                                        </div>
                                    : <div className="d-flex flex-wrap">
                                            <div className="col-12">
                                                <h1 className="text-primary font-weight-bold mb-0"> ¡Te hemos enviado un <br/> correo electrónico! </h1>
                                                <h6 className="text-secondary mb-4">En tu bandeja de entrada encontraras un correo <br/> electronico con las instrucciones para restablecer <br/> la contraseña </h6>
                                                <Link to="/login" className="btn btn-primary btn-block">
                                                    Iniciar Sesion
                                                </Link>
                                                <button type="button" className="btn btn-outline-primary btn-block" onClick={this.sendAgain}>
                                                    Reenviar correo electronico
                                                </button>
                                                <div className="d-flex justify-content-center">
                                                    <span className="pt-2"> ¿No te ha llegado el correo? </span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </LoadMask>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const ms2p = (state) => ({
    ...state.login,
})

const md2p = { ...actions };

export default connect(ms2p, md2p)(ForgotPassword);
