import React, { useState, useEffect } from "react";
import Form from "./Form";
import Title from "../../Utils/Headers/Title";
import {
    NOTIFICATIONS_TYPE,
    ROLE_CABIN_ATTENDANT,
} from "../../../../utility/constants";
import Swal from "sweetalert2";
import LoadMask from "../../Utils/LoadMask/LoadMask";

const Container = (props) => {
    const [isUpdate, setIsUpdate] = useState(false);
    const [isView, setIsView] = useState(false);
    const [temporal_vehicle, setTemporalVehicle] = useState(null);
    const [selected_vehicles, setSelectedVehicles] = useState([]);
    const [files, setFiles] = useState([]);
    const [files_attach, setFilesAttach] = useState([]);
    const [files_update, setFilesUpdate] = useState([]);
    const [mark_lng, setMarkLng] = useState(null);
    const [mark_lat, setMarkLat] = useState(null);
    const [observations, setObservations] = useState(null);
    const { createAttachment, updateAttachment } = props;
    const [isChangeStatusEmergency, setIsChangeStatusEmergency] = useState(false);

    const setArrayFiles = (file) => {
        setFiles((a) => [...a, file]);
    };

    const setAttachFiles = (file) => {
        setFilesAttach((a) => [...a, file]);
    };
    
    const removeFiles = (index) => {
        let fd = [...files];
        let fad = [...files_attach];
        let fud = [...files_update];
        const fudi = fud[index];
        if (fudi) {
            const i = fud.indexOf(fudi);
            fud.splice(i, 1);
            setFilesUpdate(fud);
        }
        fd.splice(index, 1);
        fad.splice(index, 1);
        setFiles(fd);
        setFilesAttach(fad);
    };

    const edit = (data) => {
        let latlngValidate = false;
        if (!data.latlng) {
            if (mark_lat && mark_lng) {
                data.latlng = {
                    lat: mark_lat,
                    lng: mark_lng,
                };
            } else {
                latlngValidate = true;
            }
        }
        if (latlngValidate) {
            Swal.fire(
                "¡Atención!",
                "Ingrese la Latitud y longitud.",
                "warning"
            );
            return;
        }

        if (selected_vehicles.length == 0) {
            Swal.fire(
                "¡Atención!",
                "Debes agregar por lo menos un vehículo.",
                "warning"
            );
            return;
        } else {
            const ids = [];
            props.item.members &&
                props.item.members.map((x) => {
                    ids.push(x.vehicle);
                });

            const vehicleExist = (vehicle) => {
                if (ids.indexOf(vehicle) > -1) {
                    return true;
                } else {
                    return false;
                }
            };
            let validate = false;
            selected_vehicles.map((x) => {
                if (vehicleExist(x.value && x.value)) {
                    validate = true;
                    return;
                }
            });

            if (validate) {
                Swal.fire(
                    "¡Atención!",
                    "No puedes agregar el mismo vehículos 2 veces, verifique los datos y vuelve a intentar.",
                    "warning"
                );
                return;
            }
        }
        for (let i = 0; i < data.members.length; i++) {
            Object.assign(data.members[i], selected_vehicles[i]);
        }
        let final_files_attach = [];
        for (let i = 0; i < files_attach.length; i++) {
            if (!files_attach[i].id) {
                final_files_attach.push({
                    file: files_attach[i],
                    name: "photo" + final_files_attach.length,
                });
            }
        }
        data.images_id = files_update;
        updateAttachment(
            { ...data, photo: null, photo_length: final_files_attach.length, is_change_status_emergency: isChangeStatusEmergency},
            final_files_attach
        );
    };

    const create = (data) => {
        let latlngValidate = false;
        let final_files_attach = [];

        if (!data.latlng) {
            if (mark_lat && mark_lng) {
                data.latlng = {
                    lat: mark_lat,
                    lng: mark_lng,
                };
            } else {
                latlngValidate = true;
            }
        }
        if (latlngValidate) {
            Swal.fire(
                "¡Atención!",
                "Ingrese la Latitud y longitud.",
                "warning"
            );
            return;
        }

        if (selected_vehicles.length == 0) {
            Swal.fire(
                "¡Atención!",
                "Debes agregar por lo menos un vehículo.",
                "warning"
            );
            return;
        }

        for (let i = 0; i < data.members.length; i++) {
            Object.assign(data.members[i], selected_vehicles[i]);
        }
        if (props.me.role !== ROLE_CABIN_ATTENDANT) {
            for (let i = 0; i < files_attach.length; i++) {
                final_files_attach.push({
                    file: files_attach[i],
                    name: "photo" + i,
                });
            }
        }
        createAttachment(
            { ...data, photo: null, photo_length: files_attach.length },
            final_files_attach
        );
    };

    const getSelects = (stations) => {
        props.firefightersList(stations);
        props.vehiclesList(stations);
    };

    useEffect(() => {
        props.emergencyTypesFilterList();
        props.stationFilterList();
        if (
            props.match.path === "/emergency/:id/editar" &&
            props.match.params.id
        ) {
            setIsUpdate(true);
            props.leer(props.match.params.id);
        } else if (
            props.match.path === "/emergency/:id" &&
            props.match.params.id
        ) {
            setIsView(true);
            props.leer(props.match.params.id);
        } else {
            if (props.me.station) {
                props.estacionDefault(props.me.station.id, props.me.role);
            }
            setObservations(null);
            setSelectedVehicles([]);
            props.cleanVehiclesList();
            props.cleanFirefightersList();
        }
    }, [props.me]);

    useEffect(() => {
        if (Object.keys(props.item).length > 0 && isUpdate) {
            let members_copy = [];
            props.item.members &&
                props.item.members.forEach((element) => {
                    let temp = { ...element };
                    delete temp.km_inicial;
                    delete temp.km_final;
                    delete temp.pilot;
                    members_copy.push(temp);
                });
            setObservations(props.item.observations);
            getSelects(props.item.stations);
            setSelectedVehicles(members_copy);
            setFiles(props.item.images);
            setFilesAttach(props.item.images);
            setFilesUpdate(props.item.images_id);
            setMarkLat(parseFloat(props.item.latitude));
            setMarkLng(parseFloat(props.item.longitude));
        } else {
            if (props.me.station) {
                getSelects(props.me.station.id);
                setMarkLat(parseFloat(props.me.station.lat));
                setMarkLng(parseFloat(props.me.station.lng));
            }
        }
    }, [props.item, isUpdate, props.me]);
    return (
        <div className="card pb-3 mb-5">
            <div
                className={
                    isUpdate
                        ? props.item && props.item.status
                            ? `px-4 pt-4 container-title-create${
                                  props.item.status === "Activo"
                                      ? "-active"
                                      : props.item.status === "Critica"
                                      ? "-critical"
                                      : props.item.status === "Solicita apoyo"
                                      ? "-requesting_support"
                                      : props.item.status === "Resuelta"
                                      ? "-resolved"
                                      : ""
                              }`
                            : "px-4 pt-4 container-title-create"
                        : "px-4 pt-4 container-title-create"
                }
            >
                <Title
                    textWhite={true}
                    title={"Emergencia"}
                    subtitle={
                        isView
                            ? "Detalle"
                            : isUpdate
                            ? "Actualizar"
                            : "Crear emergencia"
                    }
                    showCreate={false}
                />
            </div>
            <div className="p-0 px-3 pt-3">
                <LoadMask loading={props.loader} blur>
                    <Form
                        mark_lat={mark_lat}
                        mark_lng={mark_lng}
                        isUpdate={isUpdate}
                        isView={isView}
                        observations_value={observations}
                        emergency_types={props.emergencyTypes.filter_list}
                        notificactions_types={NOTIFICATIONS_TYPE}
                        stations={props.stations.filter_list}
                        getSelects={getSelects}
                        firefighters={props.firefighters.firefighters}
                        pilot={props.firefighters.pilot}
                        vehicles={props.vehicles.vehicles_stations}
                        temporal_vehicle={temporal_vehicle}
                        setTemporalVehicle={setTemporalVehicle}
                        selected_vehicles={selected_vehicles}
                        setSelectedVehicles={setSelectedVehicles}
                        files={files}
                        setFiles={setAttachFiles}
                        customOnChange={setArrayFiles}
                        removeFiles={removeFiles}
                        onSubmit={isUpdate ? (data) => edit(data) : create}
                        setIsChangeStatusEmergency={setIsChangeStatusEmergency}
                        item={props.item}
                        me={props.me}
                    />
                </LoadMask>
            </div>
        </div>
    );
};

export default Container;
