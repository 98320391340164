import { api } from "api";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import { handleActions } from "redux-actions";
import { createReducer } from "../baseReducer/baseReducer";

const baseReducer = createReducer(
    "report_human_resources",
    "reports/listUserHumanResources",
    "ReportHumanResourcesForm"
);

// ------------------------------------
// Constants
// ------------------------------------
const SET_STATION_REPORT_HUMAN_RESOURCES = "SET_STATION_REPORT_HUMAN_RESOURCES";
const SET_USER_REPORT_HUMAN_RESOURCES = "SET_USER_REPORT_HUMAN_RESOURCES";
const SET_START_DATE_REPORT_HUMAN_RESOURCES = "SET_START_DATE_REPORT_HUMAN_RESOURCES";
const SET_END_DATE_REPORT_HUMAN_RESOURCES = "SET_END_DATE_REPORT_HUMAN_RESOURCES";
const SET_DATA_PDF_HUMAN_RESOURCES = "SET_DATA_PDF_HUMAN_RESOURCES";
const SET_SHOW_PDF = "SET_SHOW_PDF";

// ------------------------------------
// Pure Actions
// ------------------------------------
const setOptionsStation = (station) => ({
    type: SET_STATION_REPORT_HUMAN_RESOURCES,
    station,
});
const setOptionsUser = (user_) => ({
    type: SET_USER_REPORT_HUMAN_RESOURCES,
    user_,
});

const setOptionStartDate = (start_date) => ({
    type: SET_START_DATE_REPORT_HUMAN_RESOURCES,
    start_date,
});

const setOptionEndDate = (end_date) => ({
    type: SET_END_DATE_REPORT_HUMAN_RESOURCES,
    end_date,
});

const setDataPDF = (data_pdf) => ({
    type: SET_DATA_PDF_HUMAN_RESOURCES,
    data_pdf,
});

const setOptionShowPDF = (show_pdf) => ({
    type: SET_SHOW_PDF,
    show_pdf,
});

const customUserList =
    (page = 1) =>
    (dispatch, getStore) => {
        const resource = getStore()["report_human_resources"];
        const params = { page };

        // Station
        const station = resource.station
            ? resource.station.map((station) => station.id)
            : "";
        params.station = station;

        // User
        const user_ = resource.user_ ? resource.user_ : "";
        params.user = user_;

        // DATE
        const start_date = resource.start_date
            ? moment(resource.start_date).format("YYYY-MM-DD")
            : "";
        params.start_date = start_date;
        // filter end date
        const end_date = resource.end_date
            ? moment(resource.end_date).format("YYYY-MM-DD")
            : "";
        params.end_date = end_date;

        if (start_date && end_date)
            if (end_date < start_date) {
                dispatch(baseReducer.actions.setLoader(false));
                return NotificationManager.error(
                    "La fecha inicial no puede ser mayor a la fecha final",
                    "ERROR",
                    0
                );
            }

        dispatch(baseReducer.actions.setLoader(true));
        api.get("reports/listUserHumanResources", params)
            .then((response) => {
                dispatch(baseReducer.actions.setData(response));
                dispatch(baseReducer.actions.setPage(page));
            })
            .catch((err) => {
                console.log("Error:", err);
            })
            .finally(() => {
                dispatch(baseReducer.actions.setLoader(false));
            });
    };

const DataPDF =
    () =>
    (dispatch, getStore) => {
        const resource = getStore()["report_human_resources"];
        const params = {};

        // Station
        const station = resource.station
            ? resource.station.map((station) => station.id)
            : "";
        params.station = station;

        // User
        const user_ = resource.user_ ? resource.user_ : "";
        params.user = user_;

        // DATE
        const start_date = resource.start_date
            ? moment(resource.start_date).format("YYYY-MM-DD")
            : "";
        params.start_date = start_date;
        // filter end date
        const end_date = resource.end_date
            ? moment(resource.end_date).format("YYYY-MM-DD")
            : "";
        params.end_date = end_date;

        api.get("reports/dataPDF", params)
            .then((response) => {
                dispatch(setDataPDF(response));
                dispatch(setOptionShowPDF(true));
            })
            .catch((err) => {
                console.log("Error:", err);
            })
            .finally(() => {
            });
    };

const downloadExcel = () => (dispatch, getStore) => {
    const resource = getStore()['report_human_resources'];
    const token = `auth_token=${localStorage.getItem("token")}`
    
    // Station
    const station = resource.station
        ? resource.station.map((station) => station.id)
        : "";

    // User
    const user_ = resource.user_ ? resource.user_ : "";

    // DATE
    const start_date = resource.start_date
        ? moment(resource.start_date).format("YYYY-MM-DD")
        : "";

    // filter end date
    const end_date = resource.end_date
        ? moment(resource.end_date).format("YYYY-MM-DD")
        : "";

    const params = `station=${station}&user=${user_}&start_date=${start_date}&end_date=${end_date}`

    const endpoint = `/api/reports/download_excel?${token}&${params}`;

    window.open(endpoint, '_blank');
};

const setStation = (value) => (dispatch, getStore) => {
    dispatch(setOptionsStation(value));
    dispatch(customUserList());
};

const setUser = (value) => (dispatch, getStore) => {
    dispatch(setOptionsUser(value));
    dispatch(customUserList());
};

const setStartDate = (value) => (dispatch, getStore) => {
    const resource = getStore()["report_human_resources"];
    dispatch(setOptionStartDate(value));
    if (resource.end_date) dispatch(customUserList());
};

const setEndDate = (value) => (dispatch, getStore) => {
    const resource = getStore()["report_human_resources"];
    dispatch(setOptionEndDate(value));
    if (resource.start_date) dispatch(customUserList());
};

const setShowPDF = (value) => (dispatch) => {
    dispatch(setOptionShowPDF(value));
};

export const actions = {
    customUserList,
    setStation,
    setUser,
    setStartDate,
    setEndDate,
    downloadExcel,
    DataPDF,
    setShowPDF,
    ...baseReducer.actions,
};

export const reducers = {
    [SET_STATION_REPORT_HUMAN_RESOURCES]: (state, { station }) => ({
        ...state,
        station,
    }),
    [SET_USER_REPORT_HUMAN_RESOURCES]: (state, { user_ }) => ({
        ...state,
        user_,
    }),
    [SET_START_DATE_REPORT_HUMAN_RESOURCES]: (state, { start_date }) => ({
        ...state,
        start_date,
    }),
    [SET_END_DATE_REPORT_HUMAN_RESOURCES]: (state, { end_date }) => ({
        ...state,
        end_date,
    }),
    [SET_DATA_PDF_HUMAN_RESOURCES]: (state, { data_pdf }) => ({
        ...state,
        data_pdf,
    }),
    [SET_SHOW_PDF]: (state, { show_pdf }) => ({
        ...state,
        show_pdf,
    }),
    ...baseReducer.reducers,
};

export const initialState = {
    station: "",
    user_: "",
    start_date: "",
    end_date: "",
    data_pdf: "",
    show_pdf: false,
    ...baseReducer.initialState,
};

export default handleActions(reducers, initialState);
