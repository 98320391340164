import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/humanResources/job_managment';
import JobManagment from './JobManagment';


const ms2p = (state) => {
    return {
        ...state.jobManagment,
        me: state.login.me,
    }
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(JobManagment);
