import { api } from "api";
import { push } from "react-router-redux";
import { handleActions } from "redux-actions";
import { createReducer } from "../baseReducer/baseReducer";
import { NotificationManager } from "react-notifications";
import Swal from "sweetalert2";
import moment from "moment";

const baseReducer = createReducer("qr_users", "qr", "qrUserForm", "/qr_scan");

// ------------------------------------
// Constants
// ------------------------------------
const SET_OPEN_MODAL = "SET_OPEN_MODAL";
const SET_DATA_USER_QR = "SET_DATA_USER_QR";
const SET_USER_QR = "SET_USER_QR";
// ------------------------------------
// Pure Actions
// ------------------------------------
const setOpenModal = (modal_open) => ({
    type: SET_OPEN_MODAL,
    modal_open,
});
const setUserQR = (user_QR) => ({
    type: SET_DATA_USER_QR,
    user_QR,
});

const setUserFilterQR = (user_filter_qr) => ({
    type: SET_USER_QR,
    user_filter_qr,
});

const customUserList =
    (page = 1) =>
    (dispatch, getStore) => {
        const resource = getStore()["qr_users"];
        const params = { page };

        const user_filter = resource.user_filter_qr
            ? resource.user_filter_qr.id
            : "";
        params.id = user_filter;

        dispatch(baseReducer.actions.setLoader(true));
        api.get("qr", params)
            .then((response) => {
                dispatch(baseReducer.actions.setData(response));
                dispatch(baseReducer.actions.setPage(page));
            })
            .catch((err) => {
                console.log("Error:", err);
            })
            .finally(() => {
                dispatch(baseReducer.actions.setLoader(false));
            });
    };

const qrRead =
    (id, reload = true) =>
    (dispatch) => {
        reload && dispatch(baseReducer.actions.setLoader(true));

        api.get(`qr/${id}/user_information/`)
            .then((response) => {
                console.log(response);
                if (response.status == 1) {
                    Swal.fire("¡Atención!", "Usuario Inactivo.", "warning");
                    return;
                }
                dispatch(setUserQR(response));
                dispatch(setOpenModal(true));
                dispatch(push("/qr_scan"));
            })
            .catch((err) => {
                console.log("Error:", err);
            })
            .finally(() => {
                dispatch(baseReducer.actions.setLoader(false));
            });
    };

const setEntry = (id) => (dispatch) => {
    dispatch(baseReducer.actions.setLoader(true));

    const registro = Date.now();
    const today = moment(registro).format('YYYY-MM-DD HH:mm:ss')

    api.post(`qr/${id}/set_entry/`, {hoy: today})
        .then((response) => {
            dispatch(customUserList());
            NotificationManager.success(
                "Marcar ingreso realizado exitosamente.",
                "Éxito",
                3000
            );
        })
        .catch((err) => {
            NotificationManager.error(
                err.detail ? err.detail : "Error",
                "Error",
                3000
            );
        })
        .finally(() => {
            dispatch(baseReducer.actions.setLoader(false));
            dispatch(setOpenModal(false));
        });
};

const setExit = (id, observation, dateTime) => (dispatch) => {
    dispatch(baseReducer.actions.setLoader(true));

    const registro = Date.now();
    const today = moment(registro).format('YYYY-MM-DD HH:mm:ss')
    const date_entry = dateTime ? moment(dateTime).format('YYYY-MM-DD HH:mm:ss') : ''

    api.post(`qr/${id}/set_exit/`, { observation: observation, hoy: today, date_entry: date_entry })
        .then((response) => {
            dispatch(customUserList());
            NotificationManager.success(
                "Marcar salida realizado exitosamente.",
                "Éxito",
                3000
            );
        })
        .catch((err) => {
            NotificationManager.error(
                err.detail ? err.detail : "Error",
                "Error",
                3000
            );
        })
        .finally(() => {
            dispatch(baseReducer.actions.setLoader(false));
            dispatch(setOpenModal(false));
        });
};

const filterUserQR = (value) => (dispatch) => {
    dispatch(setUserFilterQR(value));
    dispatch(customUserList());
};

export const actions = {
    customUserList,
    qrRead,
    setOpenModal,
    setEntry,
    setExit,
    filterUserQR,
    ...baseReducer.actions,
};

export const reducers = {
    [SET_OPEN_MODAL]: (state, { modal_open }) => {
        return {
            ...state,
            modal_open,
        };
    },
    [SET_DATA_USER_QR]: (state, { user_QR }) => {
        return {
            ...state,
            user_QR,
        };
    },
    [SET_USER_QR]: (state, { user_filter_qr }) => {
        return {
            ...state,
            user_filter_qr,
        };
    },
    ...baseReducer.reducers,
};

export const initialState = {
    modal_open: false,
    user_QR: "",
    user_filter_qr: "",
    ...baseReducer.initialState,
};

export default handleActions(reducers, initialState);
