import React, { useEffect, useState } from "react";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import { useHistory } from "react-router";
import { connect } from "react-redux";
const userIcon = require("assets/img/vehicle_on_tracking.png");
const paths = {
    "/dashboard": "Dashboard",
    "/": "Dashboard",
    "/vehicle-types": "Tipo de vehículos",
    "/stations": "Estaciones",
    "/users": "Usuarios",
    "/permissions": "Permisos",
    "/emergencies": "Emergencias",
    "/vehicles/create": "Vehículos",
    "/vehicles": "Vehículos",
    "/settings": "Configuración",
    "/tracking": "Trackeo",
    "/binnacle": "Bitácora",
    "/human_resources/job_management": "Recursos Humanos / Gestión De Puestos",
    "/human_resources/control_hours": "Recursos Humanos / Control De Horas",
    "/human_resources/unsubscribe_discharge":
        "Recursos Humanos / Dar De Baja - Alta",
    "/report/emergency_report": "Reporteria / Reporte de Emergencia",
    "/qr_scan": "Escaneo de QR",
};
import { vehicletracking } from "../../../../utility/vehicletracking";
import SelectorVehicle from "./SelectorVehicle";
import WebSocketInstance from "../../../../websocket";
import Swal from "sweetalert2";
import { actions } from "../../../../redux/modules/vehiclesEmergency/vehiclesEmergency";
import {
    ROLE_ADMIN,
    ROLE_ADMIN_STATION,
    ROLE_STATION_BOSS,
    ROLE_CABIN_ATTENDANT,
} from "../../../../utility/constants";
import NoSleep from 'nosleep.js';
import "./navbar.css";

const Navbar = (props) => {
    const renderPath = window.location.href.split("/");
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const history = useHistory();
    const { showButtons, navToggle, navMenu, burgerMenu, logOut, user } = props;
    const [pathTitle, setPathTitle] = useState(paths["/" + renderPath[4]]);
    const [openVehicle, setOpenVehicle] = useState(false);
    const [moblie, setMobile] = useState(false);
    const [screen, setScreen] = useState(false)

    // keep screen active
    const noSleep = new NoSleep();

    const sendMessageHandler = (latitud, longitude) => {
        const vehicleObject = {
            from: "Position Current",
            content: vehicletracking.toStringPosition(
                vehicletracking.getVehicleID(),
                latitud,
                longitude
            ),
            vehicleId: vehicletracking.getVehicleID(),
        };
        waitForSocketConnection(() => {
            WebSocketInstance.newUbication(vehicleObject);
        });
        try {
            if (
                WebSocketInstance.state() !== 1 &&
                WebSocketInstance.state() !== 0
            ) {
                WebSocketInstance.connect(vehicletracking.getVehicleID());
            }
        } catch (e) {
            WebSocketInstance.connect(vehicletracking.getVehicleID());
        }
    };

    useEffect(() => {
        if (props.me && props.me.role === 7 && !vehicletracking.getVehicle() && isMoblie()){
            handleChangeSelectorVehicle()
        }
    }, [])

    const isMoblie = () => {
        const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i,
        ];

        return toMatch.some((toMatchItem) => {
            setMobile(navigator.userAgent.match(toMatchItem));
            return navigator.userAgent.match(toMatchItem);
        });
    };

    const waitForSocketConnection = (callback) => {
        setTimeout(function () {
            if (WebSocketInstance.state() === 1) {
                console.log("Enviando Posicion...");
                callback();
                return;
            } else {
                const vehicle = vehicletracking.getVehicleObj();
                props.changeStatusTrackingStop(vehicle.id, vehicle, false);
                Swal.fire({
                    icon: "error",
                    title: "ERROR",
                    text: "No se pudo enviar los datos al servidor, trackeo detenido.",
                });
            }
        }, 1000);
    };

    useEffect(() => {
        return history.listen((location) => {
            setPathTitle(paths[location.pathname]);
        });
    }, [history]);

    useEffect(() => {
        isMoblie();
        vehicletracking.getVehicleID() &&
            props.status_vehicle(vehicletracking.getVehicleID());
    }, []);

    const geo_position_error = () => {
        const vehicle = vehicletracking.getVehicleObj();
        props.changeStatusTrackingStop(vehicle.id, vehicle, false);
        Swal.fire({
            icon: "error",
            title: "ERROR",
            text: "No se puede obtener la localizacion del dispositivo",
        });
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (vehicletracking.getVehicle()) {
                navigator.geolocation.getCurrentPosition(
                    sendPosition,
                    geo_position_error
                );
            }
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    function sendPosition(position) {
        sendMessageHandler(position.coords.latitude, position.coords.longitude);
    }

    const stopTracking = (vehicle) => {
        Swal.fire({
            title: "¿Desea detener el trackeo?",
            text: "¡No podrá revertir esta acción!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "¡Sí, detener trackeo!",
            cancelButtonText: "No, cancelar",
            reverseButtons: true,
            confirmButtonColor: "#0F45C7",
        }).then((result) => {
            if (result.value) {
                if (vehicle) {
                    props.changeStatusTracking(vehicle.id, vehicle, false);
                }
            }
        });
    };

    const handleChangeSelectorVehicle = () => {
        if (vehicletracking.getVehicle()) {
            stopTracking(vehicletracking.getVehicleObj());
        } else {
            setOpenVehicle(!openVehicle);
        }
    };

    const closeModal = () => setOpenVehicle(false);

    return (
        <React.Fragment>
            <nav className={showButtons ? "navbar-menu" : ""}>
                <div className="navbar-menu-main">
                    {/* HOME */}
                    <div className={!burgerMenu ? "navbar-card-burger-2" : "navbar-card-1"}>
                        <ol
                            className="breadcrumb mt-3 mx-3 mb-0"
                            style={{
                                fontSize: "15px",
                                backgroundColor: "transparent",
                            }}
                        >
                            <li className="breadcrumb-item">
                                <div className="d-inline-block item-icon-wrapper">
                                    <a href="/">
                                        <span
                                            className="material-icons"
                                            style={{ color: "#777777" }}
                                        >
                                            home
                                        </span>
                                    </a>
                                </div>
                            </li>
                            <li className="breadcrumb-item">
                                <span className="title-blue">{pathTitle}</span>
                            </li>
                        </ol>
                    </div>

                    {/* LIST VEHICLE TRACKING */}
                    <div className="navbar-card-2">
                        <ol
                            className="breadcrumb header__menu--vehicle mt-3 mx-3 mb-0"
                            style={{
                                fontSize: "15px",
                                backgroundColor: "transparent",
                            }}
                        >
                            {(user.role === ROLE_ADMIN ||
                                user.role === ROLE_ADMIN_STATION ||
                                user.role === ROLE_STATION_BOSS) &&
                                moblie && (
                                <React.Fragment>
                                    <div
                                        className="breadcrumb-item"
                                        onClick={(handleChangeSelectorVehicle)}
                                    >
                                        <span className="title-blue">{`Vehículo - ${
                                            vehicletracking.getVehicle()
                                                ? vehicletracking.getVehicleplate()
                                                : "Sin definir"
                                        }`}</span>

                                        <img
                                            style={{
                                                marginLeft: 4,
                                                width: "24px",
                                                height: "24px",
                                            }}
                                            src={userIcon}
                                            alt=""
                                        />
                                    </div>
                                </React.Fragment>
                            )}
                        </ol>
                    </div>

                    {/* LIST MENU */}
                    {(!burgerMenu || showButtons) && (
                        <div className={!burgerMenu ? "navbar-card-burger-1" : "navbar-card-3"}>
                            <div className="pos-f-t">
                                <div className="navbar navbar-light justify-content-end">
                                    <button
                                        className="navbar-toggler"
                                        type="button"
                                        onClick={
                                            showButtons ? navToggle : navMenu
                                        }
                                    >
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* ACTIONS */}
                    <div className="navbar-card-4">
                        <Dropdown
                            isOpen={dropdownOpen}
                            toggle={() => setDropdownOpen(!dropdownOpen)}
                        >
                            <DropdownToggle
                                color={"transparent"}
                                style={{
                                    border: "2px solid #d4d4d5",
                                    padding: "10px",
                                    paddingBottom: "0px",
                                    borderRadius: "25px",
                                    backgroundColor: "white",
                                }}
                            >
                                <span
                                    className="material-icons actions"
                                    style={{ color: "#777777" }}
                                >
                                    more_vert
                                </span>
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem tag="a" href="#/user-profile">
                                    <div
                                        className="flex row mx-auto align-items-center py-0"
                                    >
                                        <p className="mb-0 mt-2">Ver perfil</p>
                                    </div>
                                </DropdownItem>
                                {(user.role === ROLE_ADMIN ||
                                    user.role === ROLE_ADMIN_STATION ||
                                    user.role === ROLE_STATION_BOSS ||
                                    user.role === ROLE_CABIN_ATTENDANT) && (
                                    <DropdownItem
                                        tag="a"
                                        href="#/emergencies/create"
                                    >
                                        <div
                                            className="flex row mx-auto align-items-center py-0"
                                        >
                                            <p className="mb-0 mt-2">
                                                Crear emergencia
                                            </p>
                                        </div>
                                    </DropdownItem>
                                )}
                                {(user.role === ROLE_ADMIN ||
                                    user.role === ROLE_ADMIN_STATION ||
                                    user.role === ROLE_STATION_BOSS) && (
                                    <DropdownItem tag="a" href="#/qr_scan">
                                        <div
                                            className="flex row mx-auto align-items-center py-0"
                                        >
                                            <p className="mb-0 mt-2">QR</p>
                                        </div>
                                    </DropdownItem>
                                )}

                                {(user.role === ROLE_ADMIN ||
                                    user.role === ROLE_ADMIN_STATION ||
                                    user.role === ROLE_STATION_BOSS) &&
                                    moblie && (
                                        <DropdownItem
                                            onClick={() => {
                                                screen
                                                    ? noSleep.disable()
                                                    : noSleep.enable();
                                                setScreen(!screen);
                                            }}
                                        >
                                            <div className="flex row mx-auto align-items-center py-0">
                                                <p className="mb-0 mt-2">
                                                    {screen
                                                        ? "Desactivar"
                                                        : "Mantener"}{" "}
                                                    pantalla activa
                                                </p>
                                            </div>
                                        </DropdownItem>
                                )}
                                <DropdownItem divider />
                                <DropdownItem tag="a" href="/" onClick={logOut}>
                                    <div
                                        className="flex row mx-auto align-items-center py-0"
                                    >
                                        <p
                                            className="mb-0 mt-2"
                                            style={{ color: "red" }}
                                        >
                                            Cerrar sesión
                                        </p>
                                    </div>
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
            </nav>
            <SelectorVehicle
                isOpen={openVehicle}
                handleChange={handleChangeSelectorVehicle}
                setVehicle={vehicletracking.setVehicleOnTracking}
                closeModal={closeModal}
            ></SelectorVehicle>
        </React.Fragment>
    );
};

const ms2p = (state) => {
    return {
        ...state.vehiclesEmergency,
        me: state.login.me,
    };
};

const md2p = {
    changeStatusTracking: actions.changeStatusTracking,
    changeStatusTrackingStop: actions.changeStatusTrackingStop,
    status_vehicle: actions.status_vehicle,
};

export default connect(ms2p, md2p)(Navbar);
