import moment from "moment";
import React from "react";
import ReactDatePicker from "react-datepicker";
import { Field } from "redux-form";
import {
    ACTION_BINNACLE_IN_LIST,
    INACTIVE_STATUS_FILTER,
    REGION_TYPE_IN_LIST,
} from "../../../../utility/constants";
import CustomSelectFIeld from "../FieldFilters/SelectField";
import { renderField, SelectField } from "../renderField/renderField";

const Filters = ({
    startDate = {},
    endDate = {},
    station = {},
    user={},
    emergency_type_category={},
    emergency_type = {},
    actionBinnacle = {},
    search = {},
    status_user = {},
    region = false,
}) => {
    return (
        <React.Fragment>
            {/* START DATE */}
            {startDate.show &&
                Object.entries(startDate).length !== 0 &&
                (startDate.start_date != null || startDate.start_date != "") &&
                startDate.setStartDate !== undefined &&
                startDate.setStartDate !== null && (
                    <div
                        className={
                            startDate.classNameFilter
                                ? startDate.classNameFilter
                                : "flex-1"
                        }
                    >
                        <ReactDatePicker
                            className="form-control"
                            dateFormat="dd/MM/yyyy"
                            selected={
                                startDate.start_date
                                    ? startDate.start_date
                                    : null
                            }
                            placeholderText="Fecha Inicio"
                            onChange={(e) => startDate.setStartDate(e)}
                            maxDate={new Date(moment(moment(), "YYYY-MM-DD"))}
                        />
                    </div>
                )}

            {/* END DATE */}
            {endDate.show &&
                Object.entries(endDate).length !== 0 &&
                (endDate.end_date != null || endDate.end_date != "") &&
                endDate.setEndDate !== undefined &&
                endDate.setEndDate !== null && (
                    <div
                        className={
                            endDate.classNameFilter
                                ? endDate.classNameFilter
                                : "flex-1"
                        }
                    >
                        <ReactDatePicker
                            className="form-control"
                            dateFormat="dd/MM/yyyy"
                            selected={
                                endDate.end_date ? endDate.end_date : null
                            }
                            placeholderText="Fecha Fin"
                            onChange={(e) => endDate.setEndDate(e)}
                            minDate={
                                new Date(
                                    moment(
                                        startDate.start_date
                                            ? startDate.start_date
                                            : moment(),
                                        "YYYY-MM-DD"
                                    )
                                )
                            }
                        />
                    </div>
                )}

            {/* STATIONS */}
            {station.show &&
                Object.entries(station).length !== 0 &&
                (station.station != null || station.station != "") &&
                station.setStation !== undefined &&
                station.setStation !== null && (
                    <div
                        className={
                            station.classNameFilter
                                ? station.classNameFilter
                                : "flex-1"
                        }
                    >
                        <CustomSelectFIeld
                            valueKey="id"
                            isMulti={true}
                            labelKey="name"
                            nameApiOptions={
                                station.nameApiOptions
                                    ? station.nameApiOptions
                                    : "station"
                            }
                            placeholder="Estaciones"
                            value={station.station}
                            setValue={station.setStation}
                        />
                    </div>
                )}

            {/* REGION */}
            {region && region.show && region.filterByRegion && (
                <div
                    className={
                        station.classNameFilter
                            ? station.classNameFilter
                            : "flex-1"
                    }
                >
                    <Field
                        name="status"
                        placeholder={"Region"}
                        component={SelectField}
                        customChange={region.filterByRegion}
                        valueSelect={
                            region.region
                                ? { label: region.region, value: region.region }
                                : ""
                        }
                        options={REGION_TYPE_IN_LIST}
                    />
                </div>
            )}


            {/* EMERGENCY TYPE */}
            {emergency_type_category.show &&
                Object.entries(emergency_type_category).length !== 0 &&
                (emergency_type_category.emergency_type != null ||
                    emergency_type_category.emergency_type != "") &&
                    emergency_type_category.setTypeEmergency !== undefined &&
                    emergency_type_category.setTypeEmergency !== null && (
                    <div
                        className={
                            emergency_type_category.classNameFilter
                                ? emergency_type_category.classNameFilter
                                : "flex-1"
                        }
                    >
                        <CustomSelectFIeld
                            isMulti={emergency_type_category.multi ? emergency_type_category.multi : false}
                            valueKey="id"
                            labelKey="name"
                            disabled={emergency_type_category.disabled}
                            nameApiOptions={
                                emergency_type_category.nameApiOptions
                                    ? emergency_type_category.nameApiOptions
                                    : "emergency_type"
                            }
                            placeholder="Tipo de emergencia"
                            value={emergency_type_category.emergency_type}
                            setValue={emergency_type_category.setTypeEmergency}
                        />
                    </div>
                )}

            {/* EMERGENCY TYPE */}
            {emergency_type.show &&
                Object.entries(emergency_type).length !== 0 &&
                (emergency_type.emergency_type != null ||
                    emergency_type.emergency_type != "") &&
                emergency_type.setTypeEmergency !== undefined &&
                emergency_type.setTypeEmergency !== null && (
                    <div
                        className={
                            emergency_type.classNameFilter
                                ? emergency_type.classNameFilter
                                : "flex-1"
                        }
                    >
                        <CustomSelectFIeld
                            isMulti={emergency_type.multi ? emergency_type.multi : false}
                            valueKey="id"
                            labelKey="name"
                            disabled={emergency_type.disabled}
                            nameApiOptions={
                                emergency_type.nameApiOptions
                                    ? emergency_type.nameApiOptions
                                    : "emergency_type"
                            }
                            placeholder="Tipo de emergencia"
                            value={emergency_type.emergency_type}
                            setValue={emergency_type.setTypeEmergency}
                        />
                    </div>
                )}

            {/* USER */}
            {user.show &&
                Object.entries(user).length !== 0 &&
                (user.user != null || user.user != "") &&
                user.setUser !== undefined &&
                user.setUser !== null && (
                    <div
                        className={
                            user.classNameFilter
                                ? user.classNameFilter
                                : "flex-1"
                        }
                    >
                        <CustomSelectFIeld
                            valueKey="id"
                            labelKey="first_name"
                            labelKey2="last_name"
                            nameApiOptions={
                                user.nameApiOptions
                                    ? user.nameApiOptions
                                    : "user"
                            }
                            placeholder="Usuario"
                            value={user.user}
                            setValue={user.setUser}
                        />
                    </div>
                )}

            {/* ACTION BINNACLE */}
            {actionBinnacle &&
                actionBinnacle.show &&
                actionBinnacle.setActionBinnacle && (
                    <div
                        className={
                            actionBinnacle.classNameFilter
                                ? actionBinnacle.classNameFilter
                                : "flex-1"
                        }
                    >
                        <Field
                            name="action_binnacle"
                            placeholder={"Accion"}
                            component={SelectField}
                            customChange={actionBinnacle.setActionBinnacle}
                            valueSelect={
                                actionBinnacle.action_binnacle
                                    ? {
                                          label: actionBinnacle.action_binnacle,
                                          value: actionBinnacle.action_binnacle,
                                      }
                                    : ""
                            }
                            options={ACTION_BINNACLE_IN_LIST}
                        />
                    </div>
                )}

            {/* STATUS USER */}
            {status_user && status_user.show && status_user.setStatusUser && (
                <div
                    className={
                        station.classNameFilter
                            ? station.classNameFilter
                            : "flex-1"
                    }
                >
                    <Field
                        name="status_user"
                        placeholder={"Estado"}
                        component={SelectField}
                        customChange={status_user.setStatusUser}
                        options={INACTIVE_STATUS_FILTER}
                    />
                </div>
            )}

            {/* SEARCH */}
            {search && Object.entries(search).length !== 0 && (
                <div
                    className={
                        search.classNameFilter
                            ? search.classNameFilter
                            : "flex-1"
                    }
                >
                    <div className="input-group">
                        <Field
                            name="search"
                            placeholder={"Buscar..."}
                            component={renderField}
                            padding={true}
                            onChange={(e) =>
                                search.searchChange(e.target.value)
                            }
                        />
                        <div className="input-group-append">
                            <span className="material-icons input-group-text text-primary">
                                search
                            </span>
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default Filters;
