import React, { useEffect } from "react";
import { reduxForm } from "redux-form";
import { ROLE_ADMIN, ROLE_ADMIN_STATION, HUMAN_RESOURCES } from "../../../../utility/constants";
import Filters from "../../Utils/Filters/Filters";
import Grid from "../../Utils/Grid";
import { standardActions } from "../../Utils/Grid/StandardActions";
import Title from "../../Utils/Headers/Title";
import moment from "moment";

const UnsuscribeDischarge = (props) => {
    const {
        customUserList,
        setStation,
        station,
        setStatusUser,
        status_user,
        setUser,
        user_,
        data,
        loader,
    } = props;

    useEffect(() => {
        props.customUserList();
    }, []);

    const today = moment().format("YYYY-MM-DD");

    const columns = [
        {
            dataField: "first_name",
            text: "Nombre",
            formatter: (cell, row) => {
                return (
                    <div
                        className={
                            row.end_date_unsubscribe &&
                            new Date(
                                moment(row.end_date_unsubscribe).format(
                                    "YYYY-MM-DD"
                                )
                            ) <= new Date(today)
                                ? "backgroun-red"
                                : ""
                        }
                    >{`${cell} ${row.last_name}`}</div>
                );
            },
        },
        {
            dataField: "no_dpi",
            text: "DPI",
        },
        {
            dataField: "status",
            headerClasses: "col-1",
            text: "Estado",
            formatter: (cell, row) => {
                let status = "Sin estado";
                if (row.status === "Activo") {
                    status = "De alta";
                } else if (row.status === "Inactivo") status = "De baja";
                return status;
            },
        },
        {
            dataField: "station_text",
            text: "Estacion",
        },

        {
            dataField: "id",
            text: "Acciones",
            formatter: (cell, row) =>
                row.status === "Inactivo"
                    ? standardActions({
                          discharge:
                              "/human_resources/unsubscribe_discharge/discharge",
                      })(cell, row)
                    : row.status === "Activo" &&
                      standardActions({
                          unsubscribe:
                              "/human_resources/unsubscribe_discharge/unsubscribe",
                      })(cell, row),
        },
    ];
    return (
        <div className="py-4">
            <div className="row">
                <div className="mb-4 col-lg-12">
                    <div className="mb-4 card card-small">
                        <div className="px-3 pt-4">
                            <Title
                                title={"Dar De Baja - Alta"}
                                subtitle={"Listado general"}
                            />
                        </div>
                        <div className="mb-4">
                            <div className="d-flex flex-column flex-md-row">
                                <Filters
                                    station={{
                                        show:
                                            (props.me.role === ROLE_ADMIN ||  props.me.role === HUMAN_RESOURCES)  ? true : false,
                                        station:
                                            (props.me.role === ROLE_ADMIN || props.me.role === HUMAN_RESOURCES) ? station : props.me.station,
                                        setStation: setStation,
                                        classNameFilter: "flex-1 m-3",
                                    }}
                                    status_user={{
                                        show: true,
                                        status_user: status_user,
                                        setStatusUser: setStatusUser,
                                        classNameFilter: "flex-1 m-3",
                                    }}
                                    user={{
                                        show: true,
                                        user: user_,
                                        setUser: setUser,
                                        classNameFilter: "flex-1 m-3",
                                    }}
                                />
                            </div>
                        </div>
                        <div className="p-0 px-3 pt-3">
                            <Grid
                                data={data}
                                loading={loader}
                                columns={columns}
                                onPageChange={customUserList}
                                noDataMessage={"Sin Usuarios"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default reduxForm({
    form: "UnsuscribeDischargeForm",
})(UnsuscribeDischarge);
