import React, { useEffect } from "react";
import { NotificationManager } from "react-notifications";
import { reduxForm } from "redux-form";
import Filters from "../../Utils/Filters/Filters";
import Grid from "../../Utils/Grid";
import Title from "../../Utils/Headers/Title";

const List = ({
    listarReport,
    data,
    loader,
    listar,
    descargarExcel,
    start_date,
    setStartDate,
    end_date,
    setEndDate,
    emergency_type_id,
    setTypeEmergency,
    station,
    setStation,
    region,
    setRegion,
    me,
}) => {
    useEffect(() => {
        listarReport();
    }, []);

    if (!me.station){
        NotificationManager.error('No tienes una estación asignada', 'Error', 3000);
    }

    const columns = [
        {
            dataField: "name",
            sort: true,
            text: "Estación",
        },
        {
            dataField: "region_type",
            sort: true,
            text: "Región",
        },
        {
            dataField: "emergencies_attended",
            sort: true,
            text: "Total de emergencias atendidas",
            formatter: (cell, row) => (
                <div>{row.emergencies_attended} emergencias</div>
            ),
        },
        {
            dataField: "people_served_attended",
            sort: true,
            text: "Total de pacientes atendidos",
            formatter: (cell, row) => (
                <div>{row.people_served_attended} pacientes</div>
            ),
        },
    ];

    return (
        <div className="py-4">
            <div className="row">
                <div className="mb-4 col-lg-12">
                    <div className="mb-4 card card-small">
                        <div className="px-3 pt-4">
                            <Title
                                showCreate={false}
                                title={"Reporte de Emergencias"}
                                subtitle={"Listado general"}
                                downoaldExcel={{
                                    title: "Descargar Excel",
                                    onClick: descargarExcel,
                                    disabled: !me.station ? true: false,
                                }}
                                total={{
                                    title: "Total de emergencias",
                                    subtitle: "Emergencias",
                                    total:
                                        data.results.length !== 0
                                            ? data.results[0].total_emergency
                                            : 0,
                                }}
                            />
                        </div>
                        <div className="p-0 px-3 pt-3">
                            <div className="d-flex flex-column flex-md-row">
                                <Filters
                                    startDate={{
                                        show: true,
                                        start_date: start_date,
                                        setStartDate: setStartDate,
                                        classNameFilter: "flex-1 m-3",
                                    }}
                                    endDate={{
                                        show: true,
                                        end_date: end_date,
                                        setEndDate: setEndDate,
                                        classNameFilter: "flex-1 m-3",
                                    }}
                                    emergency_type={{
                                        show: true,
                                        emergency_type: emergency_type_id,
                                        nameApiOptions: 'category_emergency_type',
                                        setTypeEmergency: setTypeEmergency,
                                        classNameFilter: "flex-1 m-3",
                                    }}
                                    station={{
                                        show: me.role === 5 ? false : true,
                                        station: station,
                                        setStation: setStation,
                                        classNameFilter: "flex-1 m-3",
                                    }}
                                    region={{
                                        show: true,
                                        region: region,
                                        filterByRegion: setRegion,
                                        classNameFilter: "flex-1 m-3",
                                    }}
                                />
                            </div>
                            <Grid
                                data={data}
                                loading={loader}
                                columns={columns}
                                onPageChange={listarReport}
                                noDataMessage={"Sin Puesto Registrado"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default reduxForm({
    form: "ListEmerListReportEmergenciesgencies", // a unique identifier for this form
})(List);

// export default List;
