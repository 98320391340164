import {api} from "api";
import { NotificationManager } from "react-notifications";
import {handleActions} from 'redux-actions';
import {change, initialize} from "redux-form";
import {createReducer} from "../baseReducer/baseReducer";
// ------------------------------------
// BASE REDUCER
// ------------------------------------
const baseReducer = createReducer(
    "station",
    "station",
    "StationForm",
    "/stations",
);

// ------------------------------------
// Constants
// ------------------------------------

const SET_OPTION_STATUS_STATION = 'SET_OPTION_STATUS_STATION';
const SET_OPTION_STATUS_REGION = 'SET_OPTION_STATUS_REGION';
const SET_PAGE_USER2 = 'SET_PAGE_USER2';
const SET_DATA_USER2 = 'SET_DATA_USER2';
const SET_PAGE_VEHICLE2 = 'SET_PAGE_VEHICLE2';
const SET_DATA_VEHICLE_2 = 'SET_DATA_VEHICLE_2';


// ------------------------------------
// Pure Actions
// ------------------------------------

const setOptionsStatus = status => ({
    type: SET_OPTION_STATUS_STATION,
    status,
});

const setOptionsRegion = region => ({
    type: SET_OPTION_STATUS_REGION,
    region,
});

const setPageVehicle = pageVehicle => ({
    type: SET_PAGE_VEHICLE2,
    pageVehicle,
});

const setPageUser2 = pageUser => ({
    type: SET_PAGE_USER2,
    pageUser,
});

const setUserData = dataUser => ({
    type: SET_DATA_USER2,
    dataUser,
});

const setDataVehicle = dataVehicle => ({
    type: SET_DATA_VEHICLE_2,
    dataVehicle,
});

const customListStation = (page = 1) => (dispatch, getStore) => {
    const resource = getStore()['station'];
    let status_ = resource.status
    let region_ = resource.region
    const params = {page};
    params.ordering = resource.ordering;
    params.search = resource.search;
    params.state = status_
    params.region_type = region_
    dispatch(baseReducer.actions.setLoader(true));
    api.get('station', params).then((response) => {
        dispatch(baseReducer.actions.setData(response));
        dispatch(baseReducer.actions.setPage(page));
    }).catch((err) => {
        console.log('Error:', err)
    }).finally(() => {
        dispatch(baseReducer.actions.setLoader(false));
    });
};

const eliminar_ = id => (dispatch) => {
    dispatch(baseReducer.actions.setLoader(true));
    api.eliminar(`station/${id}`).then(() => {
        dispatch(customListStation());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.success('Error en la transacción', 'Éxito', 3000);
    }).finally(() => {
        dispatch(baseReducer.actions.setLoader(false));
    });
};

const customListUser2 = (page = 1, idStation) => (dispatch) => {
    const params = {page};
    params.station_id = idStation
    dispatch(baseReducer.actions.setLoader(true));
    api.get('user', params).then((response) => {
        console.log('RESPONSE:', response)
        dispatch(setUserData(response));
        dispatch(setPageUser2(page));
    }).catch((err) => {
        console.log('Error:', err)
    }).finally(() => {
        dispatch(baseReducer.actions.setLoader(false));
    });
};

const customListVehicle2 = (page = 1, idStation) => (dispatch) => {
    const params = {page};
    params.station = idStation
    dispatch(baseReducer.actions.setLoader(true));
    api.get('vehicle', params).then((response) => {
        dispatch(setDataVehicle(response));
        dispatch(setPageVehicle(page));
    }).catch((err) => {
        console.log('Error:', err)
    }).finally(() => {
        dispatch(baseReducer.actions.setLoader(false));
    });
};

const filterByStatus = (value) => (dispatch, getStore) => {
    if (value === null) {
        value = ''
    }
    dispatch(setOptionsStatus(value))
    dispatch(customListStation())
}

const filterByRegion = (value) => (dispatch) => {
    if (value === null) {
        value = ''
    }
    dispatch(setOptionsRegion(value))
    dispatch(customListStation())
}

const searchCustomChange = (search) => (dispatch) => {
    dispatch(baseReducer.actions.setSearch(search));
    dispatch(customListStation());
};

const changeValueSelectStationStatus = () => (dispatch, getStore) => {
    const resource = getStore()['station'];
    dispatch(change('ListStation', 'status', resource.status))
}

const changePageVehicle = (pageVehicle, idStation) => (dispatch) => {
    dispatch(customListVehicle2(pageVehicle, idStation));
}

const changePageUser = (pageUser, idStation) => (dispatch) => {
    dispatch(customListUser2(pageUser, idStation));
}

const cleanItem = () => (dispatch) => {
    dispatch(baseReducer.actions.setItem({}));
}

export const actions = {
    filterByStatus,
    filterByRegion,
    changePageUser,
    customListUser2,
    customListStation,
    searchCustomChange,
    customListVehicle2,
    changePageVehicle,
    cleanItem,
    eliminar_,
    ...baseReducer.actions,
    changeValueSelectStationStatus,
};

export const reducers = {
    ...baseReducer.reducers,
    [SET_OPTION_STATUS_STATION]: (state, {status}) => {
        return {
            ...state,
            status,
        };
    },
    [SET_OPTION_STATUS_REGION]: (state, {region}) => {
        return {
            ...state,
            region,
        };
    },
    [SET_PAGE_USER2]: (state, {pageUser}) => {
        return {
            ...state,
            pageUser,
        };
    },
    [SET_DATA_USER2]: (state, {dataUser}) => {
        return {
            ...state,
            dataUser,
        };
    },
    [SET_PAGE_VEHICLE2]: (state, {pageVehicle}) => {
        return {
            ...state,
            pageVehicle,
        };
    },
    [SET_DATA_VEHICLE_2]: (state, {dataVehicle}) => {
        return {
            ...state,
            dataVehicle,
        };
    },

};

export const initialState = {
    status: '',
    region: '',
    pageVehicle: 1,
    pageUser: 1,
    dataUser: {
        results: [],
        count: 0,
    },
    dataVehicle: {
        results: [],
        count: 0,
    },
    ...baseReducer.initialState,
};


export default handleActions(reducers, initialState);
