import React from 'react';
import {
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';

import {Login, Profile, Registro, ForgotPassword, RecoverPassword} from './common/components/LoginRegister';
import Dashboard from './common/components/Dashboard';
import ProtectedRoute from './ProtectedRoute';
import Examples from './common/components/Examples/Basic';
import NotFound from './common/components/layout/NotFound/NotFound';
import { UserList, UserCreate, UserUpdate, UserDetails } from './common/components/users';
import { UserDetailsOpen } from './common/components/UsersOpen';

import '../assets/fonts/fonts.css';

require('../../node_modules/font-awesome/css/font-awesome.css');
require('../../node_modules/bootstrap/dist/css/bootstrap.css');
import 'bootstrap/dist/css/bootstrap.min.css';
import Grids from "./common/components/Examples/Grids";
import Notificaciones from './common/components/Examples/Notificaciones';
import ExampleTabs from './common/components/Examples/Tabs/Tabs';
//CONFIGURE
import { SettingEdit } from './common/components/Settings';
//EMERGENCY TYPE
import { EmergencyTypeList, EmergencyTypeCreate, EmergencyTypeDetails, EmergencyTypeUpdate } from './common/components/EmergencyTypes';
//EMERGENCIES
import { EmergencyList, EmergencyCreate, EmergencyDetails, EmergencyUpdate, EmergencyDetailOpen } from './common/components/Emergencies';
//PERMISSIONS
import { PermissionList } from './common/components/Permissions';
//VEHICLE TYPES
import { VehicleTypesList, VehicleTypesCreate, VehicleTypesDetails, VehicleTypesUpdate } from './common/components/VehicleTypes';
//VEHICLES
import { VehicleList, VehicleCreate, VehicleDetails, VehicleUpdate } from './common/components/Vehicles';
// STATION
import {StationsList, StationDetails, StationUpdate, StationCreate} from './common/components/Stations';
//TRACKING
import Tracking from './common/components/tracking';
//UNAVAILABLE
import Unavailable from './common/components/unavailable';
import DashboardFree from './common/components/DashboardFree';
import Binnacle from './common/components/Binnacle';
import Detail from './common/components/Binnacle/Detail/';
import { JobManagment, JobManagmentCreate, JobManagmentDetails, JobManagmentUpdate, UnsuscribeDischarge, ControlHours, DetailControlHours } from './common/components/HumanResources';
import Discharge from './common/components/HumanResources/UnsuscribeDischarge/Discharge/index';
import Unsuscribe from './common/components/HumanResources/UnsuscribeDischarge/Unsuscribe/index';
import { ReportEmergency, HumanResources, ReportStation, EmergencyDetail } from './common/components/Report';
import QRScan from './common/components/QRScan'
import QrView from './common/components/QRScan/QR'
require('../style/index.css');

module.exports = (
    <div>
        <div className="container__content">
            <Switch>
                <Route exact path="/login" component={Login} />
                <Route exact path="/forgot_password" component={ForgotPassword} />
                <Route exact path="/reset-password/:token" component={RecoverPassword} />
                <Route exact path="/registro" component={Registro} />
                <Route exact path="/dashboard-open" component={DashboardFree} />
                <ProtectedRoute exact path="/" component={Dashboard} />
                <ProtectedRoute exact path="/dashboard" component={Dashboard} />
                {/* <ProtectedRoute exact path="/" component={Dashboard} /> */}
                {/* <ProtectedRoute exact path="/dashboard" component={Dashboard} /> */}
                <ProtectedRoute exact path="/page2" component={Examples} />
                <ProtectedRoute exact path="/user-profile" component={Profile} />
                <ProtectedRoute exact path="/grids" component={Grids} />
                <ProtectedRoute exact path="/notifications" component={Notificaciones} />
                <ProtectedRoute exact path="/tabs" component={ExampleTabs} />
                {/*--------------------------------------------------------*/}
                {/*-------------------  SECTION CONFIGURE  ----------------*/}
                {/*--------------------------------------------------------*/}
                <ProtectedRoute exact path="/settings" component={SettingEdit} />
                {/*--------------------------------------------------------*/}
                {/*-------------------  SECTION EMERGENCY TYPES  ----------*/}
                {/*--------------------------------------------------------*/}
                <ProtectedRoute exact path="/emergency-types" component={EmergencyTypeList} />
                <ProtectedRoute exact path="/emergency-types/create" component={EmergencyTypeCreate} />
                <ProtectedRoute exact path="/emergency-type/:id" component={EmergencyTypeDetails} />
                <ProtectedRoute exact path="/emergency-type/:id/editar" component={EmergencyTypeUpdate} />
                {/*--------------------------------------------------------*/}
                {/*-------------------  SECTION EMERGENCIES  --------------*/}
                {/*--------------------------------------------------------*/}
                <ProtectedRoute exact path="/emergencies" component={EmergencyList} />
                <ProtectedRoute exact path="/emergency/:id" component={EmergencyDetails} />
                <ProtectedRoute exact path="/emergencies/create" component={EmergencyCreate} />
                <ProtectedRoute exact path="/emergency/:id/editar" component={EmergencyUpdate} />
                <Route exact path="/emergency_open/:id" component={EmergencyDetailOpen} />
                {/*--------------------------------------------------------*/}
                {/*-------------------  SECTION PERMISSIONS  --------------*/}
                {/*--------------------------------------------------------*/}
                <ProtectedRoute exact path="/permissions" component={PermissionList} />
                {/*--------------------------------------------------------*/}
                {/*------------------     SECTION USER     ----------------*/}
                {/*--------------------------------------------------------*/}
                <ProtectedRoute exact path="/users" component={UserList} />
                <ProtectedRoute exact path="/users/create" component={UserCreate} />
                <ProtectedRoute exact path="/users/:id/editar" component={UserUpdate} />
                <ProtectedRoute exact path="/users/:id" component={UserDetails} />
                {/*--------------------------------------------------------*/}
                {/*------------------     SECTION USER OPEN     ----------------*/}
                {/*--------------------------------------------------------*/}
                <Route exact path="/users_open/:id" component={UserDetailsOpen} />
                {/*--------------------------------------------------------*/}
                {/*-------------------  SECTION VEHICLE TYPES  ------------*/}
                {/*--------------------------------------------------------*/}
                <ProtectedRoute exact path="/vehicle-types" component={VehicleTypesList} />
                <ProtectedRoute exact path="/vehicle-types/create" component={VehicleTypesCreate} />
                <ProtectedRoute exact path="/vehicle-type/:id" component={VehicleTypesDetails} />
                <ProtectedRoute exact path="/vehicle-type/:id/editar" component={VehicleTypesUpdate} />
                {/*--------------------------------------------------------*/}
                {/*-------------------  SECTION VEHICLES  -----------------*/}
                {/*--------------------------------------------------------*/}
                <ProtectedRoute exact path="/vehicles" component={VehicleList} />
                <ProtectedRoute exact path="/vehicles/create" component={VehicleCreate} />
                <ProtectedRoute exact path="/vehicles/:id" component={VehicleDetails} />
                <ProtectedRoute exact path="/vehicles/:id/editar" component={VehicleUpdate} />
                {/*--------------------------------------------------------*/}
                {/*-------------------  SECTION STATION  ------------------*/}
                {/*--------------------------------------------------------*/}
                <ProtectedRoute exact path="/stations" component={StationsList} />
                <ProtectedRoute exact path="/stations/:id" component={StationDetails} />
                <ProtectedRoute exact path="/stations/Create" component={StationCreate} />
                <ProtectedRoute exact path="/stations/:id/editar" component={StationUpdate} />
                {/*--------------------------------------------------------*/}
                {/*------------------------  TRACKING  --------------------*/}
                {/*--------------------------------------------------------*/}
                <ProtectedRoute exact path="/tracking" component={Tracking}/>
                {/*--------------------------------------------------------*/}
                {/*------------------------  BINNACLE  --------------------*/}
                {/*--------------------------------------------------------*/}
                <ProtectedRoute exact path="/binnacle" component={Binnacle}/>
                <ProtectedRoute exact path="/binnacle/:id" component={Detail} />
                {/*------------------------  HUMAN RESOURCES  --------------------*/}
                {/*------------------------  JOB MANAGMENT  --------------------*/}
                {/*--------------------------------------------------------*/}
                <ProtectedRoute exact path="/human_resources/job_management" component={JobManagment}/>
                <ProtectedRoute exact path="/human_resources/job_management/create" component={JobManagmentCreate} />
                <ProtectedRoute exact path="/human_resources/job_management/:id" component={JobManagmentDetails} />
                <ProtectedRoute exact path="/human_resources/job_management/:id/editar" component={JobManagmentUpdate} />
                {/*------------------------  CONTROL HOURS  --------------------*/}
                {/*--------------------------------------------------------*/}
                <ProtectedRoute exact path="/human_resources/control_hours" component={ControlHours} />
                <ProtectedRoute exact path="/human_resources/control_hours/detail/:id" component={DetailControlHours} />
                {/*------------------------  UNSUSCRIBE DISCHARGE  --------------------*/}
                {/*--------------------------------------------------------*/}
                <ProtectedRoute exact path="/human_resources/unsubscribe_discharge" component={UnsuscribeDischarge} />
                <ProtectedRoute exact path="/human_resources/unsubscribe_discharge/discharge/:id" component={Discharge} />
                <ProtectedRoute exact path="/human_resources/unsubscribe_discharge/unsubscribe/:id" component={Unsuscribe} />
                {/*------------------------  REPORTING  --------------------*/}
                {/*------------------------  HUMAN RESOURCES  --------------------*/}
                {/*--------------------------------------------------------*/}
                <ProtectedRoute exact path="/report/human_resources_report" component={HumanResources} />
                <ProtectedRoute exact path="/report/human_resources_report/user/:id" component={UserDetails} />
                {/*------------------------  EMERGENCY  --------------------*/}
                {/*--------------------------------------------------------*/}
                <ProtectedRoute exact path="/report/emergency_report" component={ReportEmergency} />
                <ProtectedRoute exact path="/report/emergency_detail_report" component={EmergencyDetail} />
                {/*------------------------  JOB MANAGMENT  --------------------*/}
                {/*--------------------------------------------------------*/}
                <ProtectedRoute exact path="/report/station_report" component={ReportStation} />
                {/*------------------------  QR SCAN  --------------------*/}
                {/*--------------------------------------------------------*/}
                <ProtectedRoute exact path="/qr_scan" component={QRScan} />
                <ProtectedRoute exact path="/qr_read" sidebar={false} component={QrView} />

                <Route component={NotFound} />
            </Switch>
        </div>
        <NotificationContainer />
    </div>
);
