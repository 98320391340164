import { actions } from '../../../../redux/modules/binnacle/binnacle';
import { connect } from 'react-redux';
import Detail from './Detail'

const mstp = state => ({
    ...state.binnacle
})

const mdtp = {
    ...actions
}

export default connect(mstp, mdtp)(Detail);
