import { handleActions } from "redux-actions";
import { api } from "api";
import { NotificationManager } from "react-notifications";
import moment from "moment";

// ------------------------------------
// Constants
// ------------------------------------
const constants = {
    LOADER: `REPORTS_LOADER`,
    DASHBOARD_DATA: "DASHBOARD_DATA",
    DASHBOARD_OPEN_DATA: "DASHBOARD_OPEN_DATA",
    FILTER_BY_START_DATE_DASHBOARD: "FILTER_BY_START_DATE_DASHBOARD",
    FILTER_BY_END_DATE_DASHBOARD: "FILTER_BY_END_DATE_DASHBOARD",
    FILTER_BY_STATION_DASHBOARD: "FILTER_BY_STATION_DASHBOARD",
    FILTER_BY_TYPE_EMERGENCY_DASHBOARD: "FILTER_BY_TYPE_EMERGENCY_DASHBOARD",
};

const setFilterByStartDate = (start_date) => ({
    type: constants.FILTER_BY_START_DATE_DASHBOARD,
    start_date,
});

const setFilterByEndDate = (end_date) => ({
    type: constants.FILTER_BY_END_DATE_DASHBOARD,
    end_date,
});

const setFilterByStation = (station) => ({
    type: constants.FILTER_BY_STATION_DASHBOARD,
    station,
});

const setFilterByTypeEmergency = (emergency_type_id) => ({
    type: constants.FILTER_BY_TYPE_EMERGENCY_DASHBOARD,
    emergency_type_id,
});

export const getDashboardReport = () => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const resource = getStore()["reports"];
    const me = getStore()["login"];

    const params = {};
    // filter start date
    const start_date = resource.start_date
        ? moment(resource.start_date).format("YYYY-MM-DD")
        : "";
    params.start_date = start_date;
    // filter end date
    const end_date = resource.end_date
        ? moment(resource.end_date).format("YYYY-MM-DD")
        : "";
    params.end_date = end_date;
    // filter station
    if (!me.me.station && me.me.role !== 1) {
        dispatch(setLoader(false));
        return NotificationManager.error(
            "No tiene ninguna estacion asignada",
            "ERROR",
            0
        );
    }

    const station_id = resource.station
        ? resource.station.map((station) => station.id)
        : "";

    params.station_emergency__station = station_id;
    // filter emergency
    const emergency_type_id = resource.emergency_type_id
        ? resource.emergency_type_id.id
        : "";
    params.emergency_type_id = emergency_type_id;

    if (start_date && end_date)
        if (end_date < start_date) {
            dispatch(setLoader(false));
            return NotificationManager.error(
                "La fecha inicial no puede ser mayor a la fecha final",
                "ERROR",
                0
            );
        }

    api.get("reports/dashboard_report", params)
        .then((response) => {
            dispatch(setDashboardData(response));
        })
        .catch((error) => {
            console.error(error);
            NotificationManager.error(
                "Error al obtener los datos del dashboard",
                "Error",
                1000
            );
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const getDashboardOpenReport = () => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const resource = getStore()["reports"];
    // const me = getStore()["login"];

    const params = {};
    // filter start date
    const start_date = resource.start_date
        ? moment(resource.start_date).format("YYYY-MM-DD")
        : "";
    params.start_date = start_date;
    // filter end date
    const end_date = resource.end_date
        ? moment(resource.end_date).format("YYYY-MM-DD")
        : "";
    params.end_date = end_date;
    // filter station
    // console.log('mememememmemememem',me.me.length);
    // if (Object.entries(me.me).length !== 0) {
    //     return NotificationManager.error(
    //         "Cierre sesion para poder visualisar los datos",
    //         "ERROR",
    //         0
    //     );
    // }

    const station_id = resource.station
        ? resource.station.map((station) => station.id)
        : "";

    params.station_emergency__station = station_id;
    // filter emergency
    const emergency_type_id = resource.emergency_type_id
        ? resource.emergency_type_id.id
        : "";
    params.emergency_type_id = emergency_type_id;

    if (start_date && end_date)
        if (end_date < start_date) {
            dispatch(setLoader(false));
            return NotificationManager.error(
                "La fecha inicial no puede ser mayor a la fecha final",
                "ERROR",
                0
            );
        }

    api.get("dashboard_open/dashboard_report", params)
        .then((response) => {
            dispatch(setDashboardOpenData(response));
        })
        .catch((error) => {
            console.error(error);
            NotificationManager.error(
                "Error al obtener los datos del dashboard",
                "Error",
                1000
            );
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const setStartDate = (value) => (dispatch, getStore) => {
    const resource = getStore()["reports"];
    dispatch(setFilterByStartDate(value));
    if (resource.end_date) dispatch(getDashboardReport());
};

const setEndDate = (value) => (dispatch, getStore) => {
    const resource = getStore()["reports"];
    dispatch(setFilterByEndDate(value));
    if (resource.start_date) dispatch(getDashboardReport());
};

const setStation = (value) => (dispatch, getStore) => {
    dispatch(setFilterByStation(value));
    dispatch(getDashboardReport());
};

const setTypeEmergency = (value) => (dispatch, getStore) => {
    dispatch(setFilterByTypeEmergency(value));
    dispatch(getDashboardReport());
};

// Dasboard open
const setStationOpen = (value) => (dispatch, getStore) => {
    dispatch(setFilterByStation(value));
    dispatch(getDashboardOpenReport());
};
const setStartDateOpen = (value) => (dispatch, getStore) => {
    const resource = getStore()["reports"];
    dispatch(setFilterByStartDate(value));
    if (resource.end_date) dispatch(getDashboardOpenReport());
};

const setEndDateOpen = (value) => (dispatch, getStore) => {
    const resource = getStore()["reports"];
    dispatch(setFilterByEndDate(value));
    if (resource.start_date) dispatch(getDashboardOpenReport());
};

const setTypeEmergencyOpen = (value) => (dispatch, getStore) => {
    dispatch(setFilterByTypeEmergency(value));
    dispatch(getDashboardOpenReport());
};

export const actions = {
    getDashboardOpenReport,
    getDashboardReport,
    setStartDate,
    setEndDate,
    setStation,
    setTypeEmergency,
    setStationOpen,
    setStartDateOpen,
    setEndDateOpen,
    setTypeEmergencyOpen,
};

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = (loader) => ({
    type: constants.LOADER,
    loader,
});

const setDashboardData = (dashboardData) => ({
    type: constants.DASHBOARD_DATA,
    dashboardData,
});

const setDashboardOpenData = (dashboardOpenData) => ({
    type: constants.DASHBOARD_OPEN_DATA,
    dashboardOpenData,
});

// -----------------------------------
// Reducers
// -----------------------------------

const reducers = {
    [constants.LOADER]: (state, { loader }) => ({ ...state, loader }),
    [constants.DASHBOARD_DATA]: (state, { dashboardData }) => ({
        ...state,
        dashboardData,
    }),
    [constants.DASHBOARD_OPEN_DATA]: (state, { dashboardOpenData }) => ({
        ...state,
        dashboardOpenData,
    }),
    [constants.FILTER_BY_START_DATE_DASHBOARD]: (state, { start_date }) => {
        return {
            ...state,
            start_date,
        };
    },
    [constants.FILTER_BY_END_DATE_DASHBOARD]: (state, { end_date }) => {
        return {
            ...state,
            end_date,
        };
    },
    [constants.FILTER_BY_STATION_DASHBOARD]: (state, { station }) => {
        return {
            ...state,
            station,
        };
    },
    [constants.FILTER_BY_TYPE_EMERGENCY_DASHBOARD]: (
        state,
        { emergency_type_id }
    ) => {
        return {
            ...state,
            emergency_type_id,
        };
    },
};

const initialState = {
    loader: false,
    dashboardData: undefined,
    dashboardOpenData: undefined,
    station: "",
    start_date: "",
    end_date: "",
    emergency_type_id: "",
};

export default handleActions(reducers, initialState);
