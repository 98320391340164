import React, { useEffect, useState } from "react";
import Title from "../../../Utils/Headers/Title";
import { actions } from "../../../../../redux/modules/users";
import Form from "../Form/Form";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import LoadMask from "../../../Utils/LoadMask/LoadMask";

const Discharge = (props) => {
    const [acceptanceFile, setAcceptanceFile] = useState(null);
    useEffect(() => {
        props.leer(props.match.params.id);
        return () => {
            props.setItem({});
        };
    }, []);

    const { updateDischarge, loader } = props;

    const update = (data) => {
        if (acceptanceFile === null || acceptanceFile === '') {
            Swal.fire(
                "¡Atención!",
                "Debe de subir un archivo.",
                "warning"
            );
            return;
        }
        data.station = data.station.id;
                updateDischarge(props.match.params.id, {...data, acceptance_file: null}, [{"file": acceptanceFile, "name": "acceptance_file"}]);
    };

    return (
        <div className="py-4">
            <LoadMask loading={loader} blur>
            <div className="row">
                <div className="mb-4 col-lg-12">
                    <div className="mb-4 card card-small">
                        <div className="px-3 pt-4">
                            <Title
                                title={"Dar De Baja - Alta"}
                                subtitle={"Dar de Alta"}
                            />
                        </div>
                        <Form
                            item={props.item}
                            onSubmit={update}
                            setAvatar={setAcceptanceFile}
                            discharge={true}
                        />
                    </div>
                </div>
            </div>
            </LoadMask>
        </div>
    );
};

const mstp = (state) => {
    return {
        me: state.login.me,
        ...state.users,
    };
};

const mdtp = { ...actions };

export default connect(mstp, mdtp)(Discharge);
