import React, { useEffect } from "react";
import { reduxForm } from "redux-form";
import { HUMAN_RESOURCES, ROLE_ADMIN } from "../../../../utility/constants";
import Filters from "../../Utils/Filters/Filters";
import Grid from "../../Utils/Grid";
import { standardActions } from "../../Utils/Grid/StandardActions";
import Title from "../../Utils/Headers/Title";

const List = (props) => {
    const {
        customUserList,
        setStation,
        station,
        setUser,
        user_,
        data,
        loader,
        setStartDate,
        start_date,
        setEndDate,
        end_date,
    } = props;

    useEffect(() => {
        props.customUserList();
    }, []);

    // Minutes to hors (1hrs 0min)
    const converter = (minutes) => {
        const hours = Math.floor(minutes / 60);
        const minutes_ = minutes % 60;
        return `${hours}hrs ${minutes_}min`;
    };

    const columns = [
        {
            dataField: "first_name",
            text: "Nombre",
            formatter: (cell, row) => `${cell} ${row.last_name}`,
        },

        {
            dataField: "firefighter_type",
            text: "Tipo",
        },

        {
            dataField: "status",
            text: "Estado",
            formatter: (cell, row) => {
                let status = "Sin estado";
                if (row.status === "Activo") {
                    status = "De alta";
                } else if (row.status === "Inactivo")
                    status = `De baja (${row.inactive_reason})`;
                return status;
            },
        },

        {
            dataField: "station_text",
            text: "Estacion",
        },

        {
            dataField: "total_hours_day",
            text:
                end_date && start_date
                    ? "Total de horas registradas"
                    : "Total de horas del día",
            formatter: (cell, row) =>
                row.total_hours_day || row.total_hours_day === 0
                    ? converter(row.total_hours_day)
                    : "Sin registro del dia de hoy",
        },

        {
            dataField: "id",
            text: "Acciones",
            formatter: (cell, row) =>
                standardActions({
                    ver: "/human_resources/control_hours/detail",
                })(cell, row),
        },
    ];

    // Calculation grand total
    let grandTotal = data && data.results && data.results.length > 0 ? data.results[0].total_hours : 0 ;

    return (
        <div className="py-4">
            <div className="row">
                <div className="mb-4 col-lg-12">
                    <div className="mb-4 card card-small">
                        <div className="px-3 pt-4">
                            <Title
                                title={"Control de horas"}
                                subtitle={"Listado general"}
                                total={{
                                    title: "Total de Horas/Minutos",
                                    total: converter(grandTotal),
                                }}
                            />
                        </div>
                        <div className="mb-4">
                            <div className="d-flex flex-column flex-md-row">
                                <Filters
                                    startDate={{
                                        show: true,
                                        start_date: start_date,
                                        setStartDate: setStartDate,
                                        classNameFilter: "flex-1 m-3",
                                    }}
                                    endDate={{
                                        show: true,
                                        end_date: end_date,
                                        setEndDate: setEndDate,
                                        classNameFilter: "flex-1 m-3",
                                    }}
                                    station={{
                                        show: (props.me.role === ROLE_ADMIN || props.me.role === HUMAN_RESOURCES) ? true : false,
                                        station: (props.me.role === ROLE_ADMIN || props.me.role === HUMAN_RESOURCES) ? station : props.me.station,
                                        setStation: setStation,
                                        classNameFilter: "flex-1 m-3",
                                    }}
                                    user={{
                                        show: true,
                                        user: user_,
                                        setUser: setUser,
                                        classNameFilter: "flex-1 m-3",
                                    }}
                                />
                            </div>
                        </div>
                        <div className="p-0 px-3 pt-3">
                            <Grid
                                data={data}
                                loading={loader}
                                columns={columns}
                                onPageChange={customUserList}
                                noDataMessage={"Sin Usuarios"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default reduxForm({
    form: "UnsuscribeDischargeForm",
})(List);
