import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { combine, validate, validatorFromFunction, validators } from 'validate-redux-form';
import { email } from '../../../../utility/validation';
import { renderField, renderNumber } from '../../Utils/renderField';


const ForgotForm = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;
    return (
        <form name="loginForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className='form-group has-feedback'>
                <Field
                    type='text'
                    name='username'
                    label='username'
                    component={renderNumber}
                    numberFormat={"#### ##### ####"}
                    className='form-control'
                    placeholder='No. de DPI'
                />
            </div>
            <div className="form-group has-feedback">
                <Field
                    name="email"
                    label="Correo Electronico"
                    component={renderField}
                    type="email"
                    className="form-control"
                    placeholder="Correo electrónico"
                />
            </div>
            <div className="form-group has-feedback">
                <Field
                    name="confirm_email"
                    label="Confirma el correo electrónico"
                    component={renderField}
                    type="email"
                    className="form-control"
                    placeholder="Confirma el correo electrónico"
                />
            </div>
            <div className="buttons-box">
                <button type="submit" className="btn btn-primary btn-block m-1 align-self-center font-weight-bold">Restablecer contraseña</button>
            </div>
        </form>
    );
};

export const matchEmail = (data) => validatorFromFunction(() => email(data) == null ? true : false);

export default reduxForm({
    form: 'forgot', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            email: combine(
                validators.exists()('Este campo es requerido'),
                matchEmail(data.email)()('Email invalido')
            ),
            confirm_email: combine(
                validators.exists()('Este campo es requerido'),
                matchEmail(data.confirm_email)()('Email invalido'),
                validators.equals(data.email)('Los emails no coinciden')
            ),
            username: validators.exists()('Este campo es requerido')
        });
    },
})(ForgotForm);
