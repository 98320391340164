import React, { useState, useEffect } from "react";
import {
    GoogleMap,
    Marker,
    InfoWindow,
    Polyline,
} from "@react-google-maps/api";

import map_disponibility from "../../../../assets/img/map-disponibility.svg";
import map_active from "../../../../assets/img/map-active.svg";
import map_requesting_support from "../../../../assets/img/map-requesting-support.svg";
import map_critical from "../../../../assets/img/map-critical.svg";

import "./estilos.css";

export const ambulancePath =
    "M624 352h-16V243.9c0-12.7-5.1-24.9-14.1-33.9L494 110.1c-9-9-21.2-14.1-33.9-14.1H416V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48v320c0 26.5 21.5 48 48 48h16c0 53 43 96 96 96s96-43 96-96h128c0 53 43 96 96 96s96-43 96-96h48c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zM160 464c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm144-248c0 4.4-3.6 8-8 8h-56v56c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8v-56h-56c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h56v-56c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v56h56c4.4 0 8 3.6 8 8v48zm176 248c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm80-208H416V144h44.1l99.9 99.9V256z";

const Map = (props) => {
    const [map_ref, setMapRef] = useState(null);
    const [lat_position, setLatPosition] = useState(14.913394);
    const [lng_position, setLngPosition] = useState(-91.3692999);
    const [info, setInfo] = useState(null);
    const {
        showLine = true,
        vehicles,
        emergencies,
        trackingMap,
        options = {},
        mapContainerStyle = {},
    } = props;
    const state = {
        coords: undefined,
        directions: undefined,
    };
    const { coords } = state;
    const image = {
        path: ambulancePath,
        fillColor: "#FFF",
        strokeWeight: 2,
        strokeColor: "#000",
        fillOpacity: 1,
        scale: 0.05,
        anchor: new google.maps.Point(280, 250),
    };

    const imageUbication = (status) => {
        const image =
            status == 20
                ? map_active
                : status == 40
                ? map_requesting_support
                : status == 50
                ? map_critical
                : map_disponibility;
        return image;
    };
    useEffect(() => {
        if (props.mark_lat) {
            setLatPosition(props.mark_lat);
            setLngPosition(props.mark_lng);
        }
        if (props.mark_lat && map_ref) {
            onLoad(
                map_ref,
                props.mark_lat - 0.1,
                props.mark_lng - 0.1,
                props.mark_lat + 0.1,
                props.mark_lng + 0.1
            );
        }
    }, [props.mark_lat, map_ref]);

    const onLoad = (
        mapInstance,
        fromLat = props.fromLat,
        fromLng = props.fromLng,
        toLat = props.toLat,
        toLng = props.toLng
    ) => {
        setMapRef(mapInstance);
        const fromPosition = new google.maps.LatLng(fromLat, fromLng);
        const toPosition = new google.maps.LatLng(toLat, toLng);

        const latlngbounds = new google.maps.LatLngBounds();
        latlngbounds.extend(fromPosition);
        latlngbounds.extend(toPosition);

        mapInstance.fitBounds(latlngbounds);
    };

    const onDragEnd = (e) => {
        const lat_lng_json = e.latLng.toJSON();
        setLatPosition(lat_lng_json.lat);
        setLngPosition(lat_lng_json.lng);
        props.onChange(lat_lng_json);
    };

    const Detail = ({ plate, emergency_code, emergency_type, pilot, station, lt, lng }) => {
        console.log('lt', lt, 'lng', lng);
        return (
            <div>
                <span>Vehículo</span>
                <h6>{plate}</h6>
                <span>Codigo de emergencia</span>
                <h6>{emergency_code}</h6>
                <span>Estación</span>
                <h6>{station}</h6>
                <span>Piloto</span>
                <h6>{pilot}</h6>
                <span>Tipo de emergencia</span>
                <h6>{emergency_type}</h6>
                <a href={`https://maps.google.com/?q=${lt},${lng}`} target="_blank">Ver en Google Maps</a>
            </div>
        );
    };

    return (
        <GoogleMap
            mapContainerStyle={{
                width: props.width ? props.width : "400px",
                height: props.height ? props.height : "400px",
                ...mapContainerStyle,
            }}
            onClick={() => setInfo(null)}
            onLoad={onLoad}
            options={{
                fullscreenControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                ...options,
            }}
        >
            {coords && showLine && (
                <Polyline
                    path={coords}
                    options={{
                        geodesic: true,
                        strokeColor: "#4AA3FF",
                        strokeOpacity: 0.9,
                        strokeWeight: 4,
                        clickable: true,
                    }}
                />
            )}

            {trackingMap && trackingMap.length > 0 ? (
                trackingMap.map((vehicle, index) => (
                    <Marker
                        key={index}
                        icon={imageUbication(vehicle.emergency_status)}
                        position={vehicle.coords}
                        label={{ text: vehicle.plate, className: "label_map" }}
                        onClick={() => setInfo(index)}
                    >
                        {info === index && (
                            <InfoWindow onCloseClick={() => setInfo(null)}>
                                <Detail
                                    plate={vehicle.plate}
                                    emergency_code={vehicle.emergency_code}
                                    emergency_type={vehicle.emergency_type}
                                    pilot={vehicle.pilot}
                                    station={vehicle.station}
                                    lt={vehicle.lt}
                                    lng={vehicle.lng}
                                />
                            </InfoWindow>
                        )}
                    </Marker>
                ))
            ) : emergencies && emergencies.length > 0 ? (
                emergencies.map((emergency, index) => (
                    <Marker
                        key={index}
                        position={emergency.coords}
                        label={emergency.label}
                    />
                ))
            ) : vehicles && vehicles.length > 0 ? (
                vehicles.map((vehicle, index) => (
                    <Marker
                        key={index}
                        icon={image}
                        position={vehicle.coords}
                    />
                ))
            ) : (
                <Marker
                    draggable={!props.disabled}
                    onDragEnd={onDragEnd}
                    position={{ lat: lat_position, lng: lng_position }}
                    icon={image}
                />
            )}
        </GoogleMap>
    );
};

export default Map;
