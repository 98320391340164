import {handleActions} from 'redux-actions';
import {createReducer} from "../baseReducer/baseReducer";


// ------------------------------------
// Constants
// ------------------------------------

export const { reducers, initialState, actions } = createReducer(
    "jobManagment",
    "job_management",
    "JobManagmentForm",
    "/human_resources/job_management",
);

export default handleActions(reducers, initialState);
