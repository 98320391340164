import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { actions } from "../../../../redux/modules/users";
import Form from "../Create/Form";
import Title from "../../Utils/Headers/Title";
import { formValueSelector } from "redux-form";

const Update = (props) => {
    const [photo, setAvatar] = useState(null);
    const [list, updateList] = useState([]);
    const [showInactive, setShowInactive] = useState(false);

    useEffect(() => {
        props.leer(props.match.params.id);
        return () => {
            props.setItem({});
        };
    }, []);

    useEffect(() => {
        updateList(props.item.approved_courses);
    }, [props.item]);

    const update = (data) => {
        data.station = data.station.id;
        data.username = data.no_dpi;

        const list_new = list.filter((item) => typeof item.file === "object");

        if (photo == null && list_new === 0) {
            props.editar(props.match.params.id, data);
        } else {
            // props.updateAttachment(props.match.params.id, { ...data, photo: null, list }, [list_new]);
            const file_photo = [{ file: photo, name: "photo" }];
            props.updateAttachment(
                props.match.params.id,
                { ...data, photo: null, list },
                [...file_photo, ...list_new]
            );
        }
    };

    const { item, loader, value_is_pilot } = props;

    return (
        <div className="card pb-3 mb-5">
            <div
                className={`px-4 pt-4 container-title-create${
                    showInactive ? "-requesting_support" : ""
                }`}
            >
                <Title
                    textWhite={true}
                    title={"Usuarios"}
                    subtitle={"Editar usuario"}
                />
            </div>
            <div className="p-0 px-3 pt-3">
                {item.id && (
                    <Form
                        item={item}
                        setAvatar={setAvatar}
                        isUpdate={true}
                        valueIsPilot={value_is_pilot}
                        loading={loader}
                        onSubmit={update}
                        list={list}
                        updateList={updateList}
                        showInactive={showInactive}
                        setShowInactive={setShowInactive}
                    />
                )}
            </div>
        </div>
    );
};

//  Decorador para leer los valores del form
const selector = formValueSelector("userForm");

const mstp = (state) => {
    const value_is_pilot = selector(state, "is_pilot");
    return {
        value_is_pilot: value_is_pilot,
        ...state.users,
    };
};

const mdtp = { ...actions };

export default connect(mstp, mdtp)(Update);
