import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/humanResources/unsubscribe_discharge';
import UnsuscribeDischarge from './UnsuscribeDischarge';

const ms2p = (state) => {
    return {
        ...state.unsubscribe_discharge,
        me: state.login.me,
    }
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(UnsuscribeDischarge);