import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Title from "../../Utils/Headers/Title";
import LoadMask from "../../Utils/LoadMask/LoadMask";

const ModalUser = ({
    setOpenModal,
    modal_open,
    data,
    setEntry,
    setExit,
    loader,
    showObservation,
}) => {
    const [valueObservation, setValueObservation] = useState("");
    const [dateTime, setdateTime] = useState("");
    const [validation, setValidation] = useState(false);

    useEffect(() => {
        setValueObservation("");
    }, [data]);
    return (
        <Modal
            isOpen={modal_open}
            size={"lg"}
            className={"modal__selector"}
            style={{ maxWidth: "75rem", width: "100%" }}
            backdrop="static"
            centered
        >
            <div className={"modal__selector__content m-2"}>
                <ModalHeader>
                    <Title
                        title={
                            data.is_entry ? "Marcar Salida" : "Marcar ingreso"
                        }
                    />
                </ModalHeader>
                <LoadMask loading={loader} blur>
                    <ModalBody>
                        <div className="flex-1 d-flex flex-column flex-md-row justify-content-center">
                            <div className="form-group mr-4 w-100">
                                <label htmlFor="emergency_type">Bombero</label>
                                <input
                                    style={{ color: "black" }}
                                    disabled={true}
                                    placeholder={"Nombre"}
                                    type={"text"}
                                    className={"form-control"}
                                    value={
                                        data
                                            ? data.first_name +
                                              " " +
                                              data.last_name
                                            : ""
                                    }
                                />
                            </div>
                            <div className="form-group w-100">
                                <label htmlFor="emergency_type">DPI</label>
                                <NumberFormat
                                    placeholder={"DPI"}
                                    className={"form-control"}
                                    style={{ colo: "black" }}
                                    decimalScale={0}
                                    format={"#### ##### ####"}
                                    disabled={true}
                                    value={data ? data.no_dpi : ""}
                                    fixedDecimalScale
                                    thousandSeparator
                                />
                            </div>
                        </div>
                        {showObservation && (
                            <React.Fragment>
                                <div className="flex-1 d-flex flex-column flex-md-row justify-content-center">
                                    <div className="form-group w-100">
                                        <label htmlFor="emergency_type">
                                            Fecha y hora de salida(opcional)
                                        </label>
                                        <input
                                            style={{ color: "black" }}
                                            onChange={(e)=>setdateTime(e.target.value)}
                                            type={"datetime-local"}
                                            className={"form-control"}
                                        />
                                    </div>
                                </div>
                                <div className="flex-1 d-flex flex-column flex-md-row justify-content-center">
                                    <div className="form-group w-100">
                                        <label htmlFor="emergency_type">
                                            Observaciones
                                        </label>

                                        <textarea
                                            placeholder={"Observación"}
                                            style={{ resize: "none" }}
                                            rows={3}
                                            onChange={(e) =>
                                                setValueObservation(
                                                    e.target.value
                                                )
                                            }
                                            className={`form-control ${
                                                validation &&
                                                showObservation &&
                                                !valueObservation &&
                                                "is-invalid"
                                            }`}
                                        />
                                        {validation &&
                                            showObservation &&
                                            !valueObservation && (
                                                <div className="require-text">
                                                    Campo requerido *
                                                </div>
                                            )}
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <div className="flex-1 d-flex flex-column flex-md-row justify-content-center">
                            <button
                                className="btn btn-danger px-3 py-3 mb-2 mr-4 mt-2 w-100 w-md-25"
                                onClick={() => setOpenModal(false)}
                            >
                                Cancelar
                            </button>
                            <button
                                className="btn btn-success px-3 py-3 mb-2 mt-2 w-100 w-md-25"
                                onClick={() => {
                                    if (showObservation && !valueObservation) {
                                        setValidation(true);
                                        return;
                                    } else {
                                        setValidation(false);
                                        data.is_entry
                                            ? setExit(data.id, valueObservation, dateTime)
                                            : setEntry(data.id);
                                    }
                                }}
                            >
                                Guardar
                            </button>
                        </div>
                    </ModalFooter>
                </LoadMask>
            </div>
        </Modal>
    );
};

export default ModalUser;
