
/**
 * Verifica que se encuentre un vehiculo 
 * en estado de trackeo.
 * @returns 
 */
function getVehicle() {
    const retrievedObject = localStorage.getItem("vehicleOnTracking");
    if (retrievedObject) {
        return `vehicleOnTracking: ${JSON.parse(retrievedObject).plate}`;
    }
    return false;
}


function getVehicleObj() {
    const retrievedObject = localStorage.getItem("vehicleOnTracking");
    if (retrievedObject) {
        return JSON.parse(retrievedObject);
    }
    return false;
}

function setVehicleOnTracking(vehicle){
    localStorage.setItem('vehicleOnTracking', JSON.stringify(vehicle));
}

function removeVehicleOnTracking(){
    localStorage.removeItem('vehicleOnTracking');
}

function getVehicleID(){
    const retrievedObject = localStorage.getItem("vehicleOnTracking");
    if(retrievedObject)
        return JSON.parse(retrievedObject).id;
    else
        return undefined
}

function getVehicleplate(){
    const retrievedObject = localStorage.getItem("vehicleOnTracking");
    if(retrievedObject)
        return JSON.parse(retrievedObject).plate;
    else
        return undefined
}

function toStringPosition(id,latitud,longitud){
    return `{"coords":{ "lat": ${parseFloat(latitud)}, "lng": ${parseFloat(longitud)} }, "label": ${id} }`
}

function toObjectPosition(str_position){
    if(str_position != "")
        return  JSON.parse(str_position)
    return false
}

export const vehicletracking = { getVehicle,getVehicleObj,toObjectPosition, toStringPosition, getVehicleID,getVehicleplate, setVehicleOnTracking, removeVehicleOnTracking };