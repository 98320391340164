import { PDFDownloadLink } from "@react-pdf/renderer";
import React from "react";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap";

const ButtonDropdownList = ({ list, title, state }) => {
    const toggle = () => state.setDropdownOpen((prevState) => !prevState);

    return (
        <Dropdown
            isOpen={state.dropdownOpen}
            className="btn btn-secondary mr-0 shadow"
            toggle={toggle}
        >
            <DropdownToggle caret>{title} </DropdownToggle>
            {toggle && (
                <DropdownMenu>
                    {list.map((i, index) => {
                        return i.title == "PDF" ? (
                            i.show_pdf ? (
                                <DropdownItem key={index} disabled={i.disabled}>
                                    <PDFDownloadLink
                                        style={{
                                            display: "flex",
                                            width: "100%",
                                            color: "#212529",
                                        }}
                                        document={
                                            <i.document data_pdf={i.data_pdf} data={i.data} />
                                        }
                                    >
                                        {i.title}
                                    </PDFDownloadLink>
                                </DropdownItem>
                            ) : (
                                <DropdownItem key={index} disabled={true}>
                                    {i.title}
                                </DropdownItem>
                            )
                        ) : (
                            <DropdownItem
                                key={index}
                                disabled={i.disabled}
                                onClick={i.action}
                            >
                                {i.title}
                            </DropdownItem>
                        );
                    })}
                </DropdownMenu>
            )}
        </Dropdown>
    );
};

export default ButtonDropdownList;
