import React, { useEffect } from "react";
import Title from "../../Utils/Headers/Title";
import list_img from "../../../../../assets/img/list.svg";
import user_img from "../../../../../assets/img/user-icon.svg";
import clock_img from "../../../../../assets/img/clock.svg";
import moment from "moment";
import { Link } from "react-router-dom";
import useBitacora from "../../../hooks/useBinnacle";
import "./style.css";

const Detail = (props) => {
    useEffect(() => {
        props.leer(props.match.params.id);
    }, []);
    const { data_detail } = props;

    const {
        detalleActual,
        detalleAnterior,
        detalle_registro,
        formatearDatosDetalle,
    } = useBitacora(data_detail);

    const Card = ({ imgSrc, title, subtitle }) => {
        return (
            <div className="d-flex flex-1 card-1-detail-main mb-2">
                <div className="flex-1">
                    <div className="card-1-detail-title">
                        <img src={imgSrc}></img>
                    </div>
                </div>

                <div className="flex-4 card-1-detail-content p-2">
                    <h6 className="text-grey-subtittle m-0">{title}</h6>
                    <h4 className="font-weight-bold">{subtitle}</h4>
                </div>
            </div>
        );
    };

    return (
        <div className="py-4">
            <div className="row">
                <div className="mb-4 col-lg-12">
                    <div className="mb-4 card card-small">
                        <div className="px-3 pt-4">
                            <Title
                                showCreate={false}
                                title={"Bitácora"}
                                subtitle={"Detalle General"}
                            />

                            {/* HEDERS */}
                            <div className="d-flex mb-4 flex-column flex-md-row">
                                <Card
                                    imgSrc={list_img}
                                    title={"Descripción"}
                                    subtitle={
                                        detalle_registro &&
                                        detalle_registro.accion
                                            ? detalle_registro.accion
                                            : ""
                                    }
                                />

                                <Card
                                    imgSrc={user_img}
                                    title={"Responsable"}
                                    subtitle={
                                        detalle_registro &&
                                        detalle_registro.usuario
                                            ? detalle_registro.usuario
                                            : ""
                                    }
                                />

                                <Card
                                    imgSrc={clock_img}
                                    title={"Fecha y hora"}
                                    subtitle={
                                        detalle_registro &&
                                        detalle_registro.created
                                            ? moment(
                                                  detalle_registro.created
                                              ).format("DD/MM/YY HH:mm:ss")
                                            : ""
                                    }
                                />
                            </div>

                            {/* CONTENT */}
                            <div className="d-flex mb-4 flex-column flex-md-row p-4 overflow-auto">
                                <div className="flex-1 pr-md-5">
                                    <h4 className="font-weight-bold">
                                        Estado Anterior
                                    </h4>
                                    <hr className="line-detail" />
                                    <div className="overflow-auto">
                                        <div
                                            style={{
                                                maxWidth: 750,
                                                minWidth: 400,
                                            }}
                                        >
                                            {detalleAnterior &&
                                                formatearDatosDetalle(
                                                    detalleAnterior,
                                                    false
                                                )}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-1">
                                    <h4 className="font-weight-bold">
                                        Estado Actual
                                    </h4>
                                    <hr className="line-detail" />
                                    <div className="overflow-auto">
                                        <div
                                            style={{
                                                maxWidth: 750,
                                                minWidth: 400,
                                            }}
                                        >
                                            {detalleActual &&
                                                formatearDatosDetalle(
                                                    detalleActual,
                                                    true
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 d-flex flex-column flex-md-row justify-content-center">
                                <div className="flex-3"></div>
                                <div className="flex-1 d-flex flex-column flex-md-row justify-content-center">
                                    <Link
                                        to={"/binnacle"}
                                        className={
                                            "btn btn-secondary px-3 py-3 m-2 w-100"
                                        }
                                    >
                                        {"Atras"}
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="p-0 px-3 pt-3"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Detail;
