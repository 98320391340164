import { api } from "api";
import { handleActions } from "redux-actions";
import { createReducer } from "../baseReducer/baseReducer";

const baseReducer = createReducer(
    "unsubscribe_discharge",
    "job_management/listUser",
    "UnsuscribeDischargeForm",
    "/human_resources/unsubscribe_discharge"
);

// ------------------------------------
// Constants
// ------------------------------------
const SET_STATION_HUMAN_RESOURCES = "SET_STATION_HUMAN_RESOURCES";
const SET_STATUS_USER_HUMAN_RESOURCES = "SET_STATUS_USER_HUMAN_RESOURCES";
const SET_USER_HUMAN_RESOURCES = "SET_USER_HUMAN_RESOURCES";
// ------------------------------------
// Pure Actions
// ------------------------------------
const setOptionsStation = (station) => ({
    type: SET_STATION_HUMAN_RESOURCES,
    station,
});
const setOptionsStatusUser = (status_user) => ({
    type: SET_STATUS_USER_HUMAN_RESOURCES,
    status_user,
});
const setOptionsUser = (user_) => ({
    type: SET_USER_HUMAN_RESOURCES,
    user_,
});

const customUserList =
    (page = 1) =>
    (dispatch, getStore) => {
        const resource = getStore()["unsubscribe_discharge"];
        const params = { page };

        // Station
        const station = resource.station
            ? resource.station.map((station) => station.id)
            : "";

        params.station = station;

        // Status User
        const status_user = resource.status_user ? resource.status_user : "";
        params.status_user = status_user;

        // User
        const user_ = resource.user_ ? resource.user_.id : "";
        params.user = user_;

        dispatch(baseReducer.actions.setLoader(true));
        api.get("job_management/listUser", params)
            .then((response) => {
                dispatch(baseReducer.actions.setData(response));
                dispatch(baseReducer.actions.setPage(page));
            })
            .catch((err) => {
                console.log("Error:", err);
            })
            .finally(() => {
                dispatch(baseReducer.actions.setLoader(false));
            });
    };

const setStation = (value) => (dispatch, getStore) => {
    dispatch(setOptionsStation(value));
    dispatch(customUserList());
};

const setStatusUser = (value) => (dispatch, getStore) => {
    dispatch(setOptionsStatusUser(value));
    dispatch(customUserList());
};

const setUser = (value) => (dispatch, getStore) => {
    dispatch(setOptionsUser(value));
    dispatch(customUserList());
};

export const actions = {
    customUserList,
    setStation,
    setStatusUser,
    setUser,
    ...baseReducer.actions,
};

export const reducers = {
    [SET_STATUS_USER_HUMAN_RESOURCES]: (state, { status_user }) => ({
        ...state,
        status_user,
    }),
    [SET_STATION_HUMAN_RESOURCES]: (state, { station }) => ({
        ...state,
        station,
    }),
    [SET_USER_HUMAN_RESOURCES]: (state, { user_ }) => ({
        ...state,
        user_,
    }),
    ...baseReducer.reducers,
};

export const initialState = {
    station: "",
    status_user: "",
    user_: "",
    ...baseReducer.initialState,
};

export default handleActions(reducers, initialState);
