import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/humanResources/control_hours';
import List from './List';

const ms2p = (state) => {
    return {
        ...state.control_hours,
        me: state.login.me,
    }
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(List);