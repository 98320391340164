import React, { useState } from "react";
import { connect } from "react-redux";
import { actions } from "../../../../redux/modules/users";
import Form from "./Form";
import Title from "../../Utils/Headers/Title";
import { formValueSelector } from "redux-form";

const Create = (props) => {
    const [photo, setAvatar] = useState(null);
    const [list, updateList] = useState([]);
    const [showInactive, setShowInactive] = useState(false);

    const selectCreate = (data) => {
        const { crear, createAttachment } = props;
        data.username = data.no_dpi;
        if (photo == null) {
            crear(data);
        } else {
            const file_photo = [{ file: photo, name: "photo" }]
            createAttachment({ ...data, photo: null, list }, [...file_photo, ...list]);
        }
    };



    const { value_is_pilot } = props;

    return (
        <div className="card pb-3 mb-5">
            <div className="px-4 pt-4 container-title-create">
                <Title
                    textWhite={true}
                    title={"Usuarios"}
                    subtitle={"Creación de usuario"}
                />
            </div>
            <div className="p-0 px-3 pt-3 mb-5">
                <Form
                    item={{}}
                    setAvatar={setAvatar}
                    onSubmit={selectCreate}
                    valueIsPilot={value_is_pilot}
                    list={list}
                    updateList={updateList}
                    isCreate={true}
                    showInactive={showInactive}
                    setShowInactive={setShowInactive}
                />
            </div>
        </div>
    );
};

//  Decorador para leer los valores del form
const selector = formValueSelector("userForm");

const mstp = (state) => {
    const value_is_pilot = selector(state, "is_pilot");
    return {
        value_is_pilot: value_is_pilot,
        ...state.users,
    };
};

const mdtp = { ...actions };

export default connect(mstp, mdtp)(Create);
