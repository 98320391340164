import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/users/index';
import { actions as station } from '../../../../redux/modules/station/station';
import List from './List';


const ms2p = (state) => {
    return {
        ...state.users,
        me: state.login.me,
        station: { ...state.station },
    }
};

const md2p = { ...actions, stationFilterList: station.filterList };

export default connect(ms2p, md2p)(List);
