import React from "react";
import { Card } from "reactstrap";
import moment from "moment";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Cell,
} from "recharts";

import Grid from "../../Utils/Grid";
import Map from "../../Utils/Map";

const CardContainer = ({
    dashboardData,
    loader,
    start_date,
    end_date,
    permissions = false,
    isDashboardOpen = false,
}) => {
    const columns = [
        {
            dataField: "id",
            text: "Codigo",
        },
        {
            dataField: "attention_date",
            text: "Fecha",
            formatter: (cell, row) => (
                <div>{moment(row.attention_date).format("MM/DD/YYYY")}</div>
            ),
        },
        {
            dataField: "hour_creation_data",
            text: "Hora",
            formatter: (cell, row) => (
                <div>{moment(row.creation_date).format(" HH:SS ")}</div>
            ),
        },
        {
            dataField: "emergency_type_text",
            text: "Tipo",
            formatter: (cell, row) => <div>{row.emergency_type_text ? row.emergency_type_text : 'Sin tipo'}</div>,
        },
        {
            dataField: "address",
            text: "Dirección",
            formatter: (cell, row) => <div>{row.address}</div>,
        },
        {
            dataField: "station",
            text: "Estación",
            formatter: (cell, row) => (
                <div>
                    {row.stations.map(
                        (station) => station.station__name + ", "
                    )}
                </div>
            ),
        },
        {
            dataField: "status_in_text",
            text: "Estado",
            formatter: (cell, row) => <div>{row.status_in_text}</div>,
        },
    ];
    const COLORS = ["#004ccb", "#00c96f", "#ffb500", "#ff6300", "#6ac400"];

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div
                    className="custom-tooltip p-2"
                    style={{ backgroundColor: "white" }}
                >
                    <p className="intro">Tipo: {payload[0].payload.name}</p>
                    <p className="label">Total: {`${payload[0].value}`}</p>
                </div>
            );
        }

        return null;
    };

    const CustomLegend = () => {
        return (
            <div className="d-flex mt-2">
                {dashboardData.data.map((val, index) => {
                    return (
                        <div className="flex-1" key={index}>
                            <svg width="16" height="16" viewBox="0 0 32 32">
                                <path
                                    fill={COLORS[index % COLORS.length]}
                                    cx="16"
                                    cy="16"
                                    type="circle"
                                    transform="translate(16, 16)"
                                    d="M16,0A16,16,0,1,1,-16,0A16,16,0,1,1,16,0"
                                ></path>
                            </svg>
                            <span className="ml-2">{val ? val.name : 'Sin Datos'}</span>
                        </div>
                    );
                })}
            </div>
        );
    };
    return (
        <div className="dashboard-container mt-5">
            <Card className="p-4 dashboard-card-1">
                <h4 className="text-primary font-weight-bold">
                    Últimas 10 emergencias.
                </h4>
                <div className="overflow-auto">
                    <Grid
                        showPagination={false}
                        data={dashboardData}
                        loading={loader}
                        columns={columns}
                        noDataMessage={"Sin tipos de emergencia"}
                    />
                </div>
            </Card>
            {!isDashboardOpen && permissions && (
                <Card className="m-2 p-2 dashboard-card-5">
                    <h6 className="card-title text-primary">
                        Vehículos Activos
                    </h6>
                    {dashboardData.vehicles && dashboardData.vehicles.length > 0 ? (
                    <React.Fragment>
                        <Map
                            mapContainerStyle={{
                                width: "100%",
                                height: "400px",
                            }}
                            fromLat={dashboardData.vehicles.reduce(
                                (t, { latitude: l }) => (l > t ? l : t),
                                0
                            )}
                            fromLng={dashboardData.vehicles.reduce(
                                (t, { longitude: l }) => (l < t ? l : t),
                                0
                            )}
                            toLat={dashboardData.vehicles.reduce(
                                (t, { latitude: l }) => (l < t ? l : t),
                                dashboardData.vehicles[0].latitude
                            )}
                            toLng={dashboardData.vehicles.reduce(
                                (t, { longitude: l }) => (l > t ? l : t),
                                dashboardData.vehicles[0].longitude
                            )}
                            options={{
                                fullscreenControl: false,
                                streetViewControl: false,
                                mapTypeControl: false,
                            }}
                            
                            trackingMap={dashboardData.vehicles.reduce(
                                (arr, current, index) => {
                                    arr.push({
                                        coords: {
                                            lat: parseFloat(current.latitude),
                                            lng: parseFloat(current.longitude),
                                        },
                                        plate: `${current.plate}`,
                                        emergency_code: `${current.emergency_code}`,
                                        emergency_type: `${current.emergency_type}`,
                                        emergency_status: `${current.emergency_status}`,
                                        pilot: `${current.pilot}`,
                                        station: current.station,
                                        lt: current.latitude,
                                        lng: current.longitude,
                                    });
                                    return arr;
                                },
                                []
                            )}
                        />
                    </React.Fragment>
                ) : (
                    <div className="w-100 d-flex">
                        <h3 className="mx-auto py-5">
                            {" "}
                            No hay vehículos activos
                        </h3>
                    </div>
                )}
                </Card>
            )}

            <Card
                className={
                    permissions && !isDashboardOpen
                        ? "m-2 dashboard-card-2"
                        : "m-2 dashboard-card-2-modify"
                }
            >
                <h6 className="card-title text-primary">Emergencias</h6>
                {dashboardData.results && dashboardData.results.length > 0 ? (
                    <React.Fragment>
                        <Map
                            mapContainerStyle={{
                                width: "100%",
                                height: "400px",
                            }}
                            fromLat={dashboardData.results.reduce(
                                (t, { latitude: l }) => (l > t ? l : t),
                                0
                            )}
                            fromLng={dashboardData.results.reduce(
                                (t, { longitude: l }) => (l < t ? l : t),
                                0
                            )}
                            toLat={dashboardData.results.reduce(
                                (t, { latitude: l }) => (l < t ? l : t),
                                dashboardData.results[0].latitude
                            )}
                            toLng={dashboardData.results.reduce(
                                (t, { longitude: l }) => (l > t ? l : t),
                                dashboardData.results[0].longitude
                            )}
                            options={{
                                fullscreenControl: false,
                                streetViewControl: false,
                                mapTypeControl: false,
                            }}
                            emergencies={dashboardData.results.reduce(
                                (arr, current, index) => {
                                    arr.push({
                                        coords: {
                                            lat: parseFloat(current.latitude),
                                            lng: parseFloat(current.longitude),
                                        },
                                        label: `${current.id}`,
                                    });
                                    return arr;
                                },
                                []
                            )}
                        />
                    </React.Fragment>
                ) : (
                    <div className="w-100 d-flex">
                        <h3 className="mx-auto py-5">
                            {" "}
                            No hay emergencias registradas{" "}
                        </h3>
                    </div>
                )}
            </Card>

            <Card
                className="m-2 dashboard-card-3 p-4 overflow-card-x"
                style={{ maxHeight: 500 }}
            >
                <h4 className="text-primary font-weight-bold">
                    Top 5 tipos de emergencia
                </h4>
                {!isDashboardOpen && (
                    <div>
                        {start_date && end_date ? (
                            <React.Fragment>
                                <span>
                                    {start_date
                                        ? moment(start_date).format(
                                              "DD/MM/YYYY"
                                          )
                                        : "(dd/mm/aa)"}
                                </span>
                                {" al "}
                                <span>
                                    {end_date
                                        ? moment(end_date).format("DD/MM/YYYY")
                                        : "(dd/mm/aa)"}
                                </span>
                            </React.Fragment>
                        ) : (
                            <span className="text-grey-subtittle">
                                (dd/mm/aa) al (dd/mm/aa)
                            </span>
                        )}
                    </div>
                )}
                <ResponsiveContainer>
                    <BarChart
                        width={500}
                        height={300}
                        data={dashboardData.data}
                        margin={{
                            top: 20,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <Legend content={<CustomLegend />} />
                        <YAxis />
                        <Tooltip content={<CustomTooltip />} />
                        <Bar dataKey="total" fill="#8884d8">
                            {dashboardData.data.map((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={COLORS[index % COLORS.length]}
                                />
                            ))}
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </Card>

            <Card className="m-2 dashboard-card-4">
                <div className="p-4">
                    <h5 className="text-primary font-weight-bold">
                        Heridos registrados
                    </h5>

                    <div className="mt-4">
                        <h4 className="card-text font-weight-bold mb-0">
                            {dashboardData.firearm ? dashboardData.firearm : 0}
                        </h4>
                        <p className="text-grey">Por arma de fuego</p>

                        <h4 className="card-text font-weight-bold mb-0">
                            {dashboardData.white_weapon
                                ? dashboardData.white_weapon
                                : 0}
                        </h4>
                        <p className="text-grey">Por arma blanca</p>

                        <h4 className="card-text font-weight-bold mb-0">
                            {dashboardData.wounded ? dashboardData.wounded : 0}
                        </h4>
                        <p className="text-grey">Heridos varios</p>
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default CardContainer;
