import React, { useState } from "react";
import { Field, FieldArray, reduxForm } from "redux-form";
import {
    validate,
    validatorFromFunction,
    validators,
    combine,
} from "validate-redux-form";
import {
    renderField,
    renderFilePicker,
    SelectField,
    renderNumber,
} from "../../Utils/renderField/renderField";
import { roles, USER_RANGES } from "../../../../utility/constants";
import ApprovedCourses from "../../users/Create/ApprovedCourses";

const genders = [
    { label: "Masculino", value: 0 },
    { label: "Femenino", value: 1 },
];

const ProfileForm = (props) => {
    const { handleSubmit, me, setAvatar } = props;

    return (
        <form action="" onSubmit={handleSubmit} className="py-4">
            <div className="mb-4 pt-3 card card-small">
                <h2 className="ml-3">
                    PERFIL{" "}
                    <h6 className="text-primary">
                        {me.first_name} {me.last_name}
                    </h6>{" "}
                </h2>
                <h4 className="pl-3 mb-0">Datos Generales</h4>
                <hr className="line-detail-profile m-3" />
                <div className="d-flex flex-wrap mb-5">
                    <div className="d-flex flex-column flex-1 mx-3">
                        <div className="form-group has-feedback">
                            <label> No. de DPI </label>
                            <Field
                                name="no_dpi"
                                component={renderNumber}
                                className="m-2"
                                numberFormat={"#### ##### ####"}
                            />
                        </div>
                        <div className="form-group has-feedback">
                            <label> Nombres </label>
                            <Field
                                name="first_name"
                                component={renderField}
                                className="m-2"
                                type="text"
                            />
                        </div>
                        <div className="form-group has-feedback">
                            <label> Apellidos </label>
                            <Field
                                name="last_name"
                                component={renderField}
                                className="m-2"
                                type="text"
                            />
                        </div>

                        <div className="form-group has-feedback">
                            <label>Correo Electronico</label>
                            <Field
                                name="email"
                                component={renderField}
                                className="m-2"
                                type="email"
                            />
                        </div>

                        <div className="form-group has-feedback">
                            <label>Fecha de Nacimiento</label>
                            <Field
                                name="date_of_birth"
                                label="date_of_birth"
                                component={renderField}
                                type={"date"}
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-6 form-group">
                        <label>Fotografia</label>
                        <Field
                            photo={me && me.photo ? me.photo : null}
                            name="photo"
                            label={"Subir imagen"}
                            setFile={setAvatar}
                            component={renderFilePicker}
                        />
                    </div>
                </div>

                <h4 className="pl-3 mb-0">Información de contacto</h4>
                <hr className="line-detail-profile m-3" />
                <div className="d-flex flex-wrap mb-5">
                    <div className="col-12 col-md-6 form-group">
                        <label>Número de Teléfono</label>
                        <Field
                            name="phone_number"
                            component={renderNumber}
                            numberFormat={"+(502) ####-####"}
                            className="m-2"
                        />
                    </div>
                    <div className="col-12 col-md-6 form-group">
                        <label>Dirección</label>
                        <Field
                            name="address"
                            component={renderField}
                            className="m-2"
                            type="text"
                        />
                    </div>
                </div>

                <h4 className="pl-3 mb-0">Información de la cuenta</h4>
                <hr className="line-detail-profile m-3" />
                <div className="d-flex flex-wrap mb-5">
                    <div className="col-12 col-md-6 form-group">
                        <label> Rol </label>
                        <Field
                            name="role"
                            component={SelectField}
                            disabled={true}
                            options={roles}
                            className="m-2"
                        />
                    </div>
                    <div className="col-12 col-md-6 form-group">
                        <label> Rango </label>
                        <Field
                            className="m-2"
                            name="user_range"
                            disabled={true}
                            options={USER_RANGES}
                            component={SelectField}
                        />
                    </div>
                </div>

                <div className="col-12 form-group">
                    <h4>Cursos Aprobados</h4>
                    <hr className="line-detail" />
                </div>
                <FieldArray
                    name="other"
                    component={ApprovedCourses}
                    isView={true}
                    list={
                        props.me && props.me.approved_courses
                            ? props.me.approved_courses
                            : []
                    }
                />

                <div className="col-12 col-md-6 form-group">
                    <label>Codigo QR</label>
                    {me.qr_code ? (
                        <a href={me.qr_code && me.qr_code} target="_blank">
                            <img
                                className="qr-mobile"
                                src={ me.qr_code ? me.qr_code : null}
                                alt={
                                    me.qr_code ? me.username : "code_qr.png"
                                }
                                disabled={true}
                            />
                        </a>
                    ) : (
                        <h5>Sin codigo QR</h5>
                    )}
                </div>

                <h4 className="pl-3 mb-0">Accesos</h4>
                <hr className="line-detail-profile ml-3 mr-3" />
                <div className="d-flex flex-wrap mb-5">
                    <div className="col-12 col-md-6 form-group">
                        <label> Contraseña </label>
                        <Field
                            name="password"
                            component={renderField}
                            type="password"
                        />
                    </div>
                    <div className="col-12 col-md-6 form-group">
                        <label> Confirmar contraseña </label>
                        <Field
                            name="confirm_password"
                            component={renderField}
                            type="password"
                        />
                    </div>
                </div>
                <div className="col-12 d-flex flex-column flex-md-row justify-content-center">
                    <div className="flex-3 d-flex flex-column flex-md-row justify-content-end"></div>
                    <div className="flex-1 d-flex flex-column flex-md-row justify-content-center">
                        <button className="btn btn-success px-3 py-3 mb-2 mt-2 w-100">
                            Guardar
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
};

const matchPassword = (passe, confirm) =>
    validatorFromFunction(() => {
        return passe === confirm;
    });

export default reduxForm({
    form: "profile", // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            confirm_password: combine(
                matchPassword(data.password, data.confirm_password)()(
                    "Las Contraseña no coinciden"
                )
            ),
        });
    },
})(ProfileForm);
