import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/vehicles/vehicles';
import { actions as station } from '../../../../redux/modules/station/station';
import { actions as vehicles_type } from '../../../../redux/modules/vehicleTypes/vehicleTypes';
import List from './List';


const ms2p = (state) => {
    return {
        ...state.vehicles,
        station: { ...state.station },
        vehicles_type: { ...state.vehicleTypes },
        me: state.login.me,
    }
};

const md2p = { ...actions,  stationFilterList: station.filterList, vehicleTypesFilterList: vehicles_type.filterList };

export default connect(ms2p, md2p)(List);
