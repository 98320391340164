import React, { useState, useEffect } from "react";
import StationForm from "./StationForm";
import Title from "../../Utils/Headers/Title";
import VehiclesList from "./VehiclesList";
import UserList from "./UserList";
import LoadMask from "../../Utils/LoadMask/LoadMask";

const Create = (props) => {
    const [isUpdate, setIsUpdate] = useState(false);
    const [isView, setIsView] = useState(false);
    const [idStation, setIdStation] = useState('');
    const [mark_lng, setMarkLng] = useState(null);
    const [mark_lat, setMarkLat] = useState(null);
    const { crear, editar } = props;
    const [radio, setRadio] = useState('text');

    useEffect(() => {
        if (
            props.match.path === "/stations/:id/editar" &&
            props.match.params.id
        ) {
            setIsUpdate(true);
            setRadio('map');
            props.leer(props.match.params.id);
        } else if (props.match.url === "/stations/create") {
            console.log("is create");
        } else if (
            props.match.path === "/stations/:id" &&
            props.match.params.id
        ) {
            setIsView(true);
            setIdStation(props.match.params.id);
            props.leer(props.match.params.id);
            props.customListUser2(1, props.match.params.id);
            props.customListVehicle2(1, props.match.params.id);
        }
        return props.cleanItem()
    }, []);

    useEffect(() => {
        if (Object.keys(props.item).length > 0 && (isUpdate || isView)) {
            setMarkLat(parseFloat(props.item.lat));
            setMarkLng(parseFloat(props.item.lng));
        }
    }, [props.item, isUpdate, isView]);

    const customChangePageUser = (pag)=>{
        props.changePageUser(pag, idStation);
    }

    const customChangePageVehicle = (pag)=>{
        props.changePageVehicle(pag, idStation);
    }

    return (
        <div className='card pb-3 mb-5'>
            <div className="px-4 pt-4 container-title-create">
                <Title
                    textWhite={true}
                    title={'Estaciones'}
                    subtitle={
                        isView
                            ? "Detalle"
                            : isUpdate
                            ? "Actualizar"
                            : "Creación de una estación"
                    }
                    showCreate={false}
                />
            </div>
            <div className="py-0 px-3 pt-3">
                <LoadMask loading={props.loader} blur>
                    <StationForm
                        isUpdate={isUpdate}
                        isView={isView}
                        mark_lat={mark_lat}
                        mark_lng={mark_lng}
                        onSubmit={
                            isUpdate
                                ? (data) => editar(props.match.params.id, data)
                                : crear
                        }
                        id={props.match.params.id}
                        radio={radio}
                        setRadio={setRadio}
                    />
                </LoadMask>
            </div>
            {isView ? (
                <React.Fragment>
                    <div className="p-0 px-3 pt-3">
                        <UserList
                            changePage={customChangePageUser}
                            page={props.pageUser}
                            data={props.dataUser}
                            loader={props.loader}
                        />
                    </div>
                    <div className="p-0 px-3 pt-3">
                        <VehiclesList
                            changePage={customChangePageVehicle}
                            page={props.pageVehicle}
                            data={props.dataVehicle}
                            loader={props.loader}
                        />
                    </div>
                </React.Fragment>
            ) : null}
        </div>
    );
};

export default Create;
