import React, { Component } from "react";
import Grid from "../../Utils/Grid";
import { reduxForm } from "redux-form";
import Title from "../../Utils/Headers/Title";

class List extends Component {

    render() {
        const { data, loader, changePage, page } = this.props;

        const columns = [
            {
                dataField: "plate",
                text: "Placa",
            },
            {
                dataField: "note",
                text: "Vehículo",
            },
            {
                dataField: "disponibility",
                text: "Disponibilidad",
                formatter: (cell, row) => {
                    let color = "point-success";
                    if (row.disponibility == 10) {
                        color = "point-green";
                    } else if (row.disponibility == 20) {
                        color = "point-orange";
                    } else if (row.disponibility == 30) {
                        color = "point-orange-2";
                    } else if (row.disponibility == 40) {
                        color = "point-red";
                    }
                    return (
                        <div className="d-flex">
                            <span className={"material-icons " + color}>
                                fiber_manual_record
                            </span>
                            <span className="">
                                {" "}
                                {row.disponibility_label}{" "}
                            </span>
                        </div>
                    );
                },
            },
        ];

        return (
            <div className="py-4">
                <div className="row">
                    <div className="mb-4 col-lg-12">
                        <Title
                            showCreate={false}
                            title={"Vehículos"}
                            subtitle={"Vehículos de la estación"}
                        />
                        <div className="mb-4 card card-small">
                            <div className="p-0 px-3 pt-3">
                                <Grid
                                    data={data}
                                    page={page}
                                    loading={loader}
                                    columns={columns}
                                    onPageChange={changePage}
                                    onSortChange={()=>{}}
                                    noDataMessage={"Sin vehículos"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default reduxForm({
    form: "ListStationVehicles", // a unique identifier for this form
})(List);
