import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {Link, Redirect} from 'react-router-dom';
import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/cuenta/login';
import RecoverForm from './RecoverForm';
import LoadMask from "Utils/LoadMask/LoadMask";
const Logo = require('../../../../../assets/img/logo_bomberos.png');
import './recover.css';

class RecoverPassword extends Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };

    onSubmit = (values) => {
        this.props.onSubmitRecover({ ...values, token: this.props.match.params.token });
    }

    render() {
        const { loader, emailSent } = this.props;
        if (localStorage.getItem('token')) {
            return (<Redirect to="/" />);
        }
        return (
            <div className="custom-background vh-100 p-3 d-flex align-items-center justify-content-center">
                <div className="flex-1 d-flex justify-content-center align-items-center h-100">
                    <div className="card d-flex flex-column p-3 p-md-4 p-lg-5">
                        <div className="flex-1 d-flex justify-content-start align-items-center imgLogo">
                            <img className="img-fluid " src={Logo} alt="logo bomberos"/>
                        </div>
                        <div className="flex-1-5 d-flex mt-3 align-items-center">
                            <div className="w-100">
                                {!emailSent
                                  ? <LoadMask loading={loader} light>
                                        <div className="d-flex flex-wrap">
                                            <div className="w-100">
                                                <h1 className="text-primary font-weight-bold mb-0"> ¿Cambiar contraseña? </h1>
                                                <h6 className="text-secondary mb-4">Ingresa tu nueva contraseña </h6>
                                                <RecoverForm onSubmit={this.onSubmit} />
                                            </div>
                                        </div>
                                    </LoadMask>
                                  : <div className="d-flex flex-wrap">
                                        <div className="col-12">
                                            <h1 className="text-primary font-weight-bold mb-0"> ¡Te hemos enviado un <br/> correo electrónico! </h1>
                                            <h6 className="text-secondary mb-4">En tu bandeja de entrada encontraras un <br/> correo electronico con las instrucciones para restablecer <br/> la contraseña </h6>
                                            <Link to="/login" className="btn btn-primary btn-block">
                                                Iniciar Sesion
                                            </Link>
                                            <button type="button" className="btn btn-outline-primary">
                                                Reenviar correo electronico
                                            </button>
                                            <div className="d-flex justify-content-center">
                                                <span> ¿No te ha llegado el correo? </span>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const ms2p = (state) => ({
    ...state.login,
})

const md2p = { ...actions };

export default connect(ms2p, md2p)(RecoverPassword);
