import React from 'react';
import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/reports/index';
import Dashboard from './Dashboard';


const mstp = state => {
    
    return {
        ...state.reports,
        me: state.login.me,
    };
}

const mdtp = {
    ...actions
}

export default connect(mstp, mdtp)(Dashboard);