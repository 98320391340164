import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/station/station';
import List from './List';


const ms2p = (state) => {
    return {
        ...state.station,
        me: state.login.me,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(List);
