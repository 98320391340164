import { handleActions } from "redux-actions";
import { createReducer } from "../baseReducer/baseReducer";
import { api } from "api";
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
import { initialize as initializeForm, change } from "redux-form";
import moment from "moment";
import Swal from "sweetalert2";

// ------------------------------------
// Constants
// ------------------------------------
const FILTER_BY_STATION_EMERGENCY_REPORT = 
    "FILTER_BY_STATION_EMERGENCY_REPORT";
const FILTER_BY_START_DATE_EMERGENCY_REPORT =
    "FILTER_BY_START_DATE_EMERGENCY_REPORT";
const FILTER_BY_END_DATE_EMERGENCY_REPORT =
    "FILTER_BY_END_DATE_EMERGENCY_REPORT";
const FILTER_BY_TYPE_EMERGENCY_EMERGENCY_REPORT =
    "FILTER_BY_TYPE_EMERGENCY_EMERGENCY_REPORT";
const FILTER_BY_REGION_EMERGENCY_REPORT = 
    "FILTER_BY_REGION_EMERGENCY_REPORT";

const setFilterByStation = (station) => ({
    type: FILTER_BY_STATION_EMERGENCY_REPORT,
    station,
});

const setOptionsRegion = (region) => ({
    type: FILTER_BY_REGION_EMERGENCY_REPORT,
    region,
});

const setFilterByStartDate = (start_date) => ({
    type: FILTER_BY_START_DATE_EMERGENCY_REPORT,
    start_date,
});

const setFilterByEndDate = (end_date) => ({
    type: FILTER_BY_END_DATE_EMERGENCY_REPORT,
    end_date,
});

const setFilterByTypeEmergency = (emergency_type_id) => ({
    type: FILTER_BY_TYPE_EMERGENCY_EMERGENCY_REPORT,
    emergency_type_id,
});

export const baseReducer = createReducer(
    "emergency_report",
    "reports/report_emergency",
    "ListReportEmergencies"
);

const descargarExcel = () => (dispatch, getStore) => {
    const resource = getStore()["emergency_report"];
    const token = `auth_token=${localStorage.getItem("token")}`;
    // filter emergency type
    const emergency_type_id = resource.emergency_type_id
        ? resource.emergency_type_id.id
        : "";

    // filter station
    const station_id = resource.station
        ? resource.station.map((station) => station.id)
        : "";

    // filter start date
    const start_date = resource.start_date
        ? moment(resource.start_date).format("YYYY-MM-DD")
        : "";
    // filter end date
    const end_date = resource.end_date
        ? moment(resource.end_date).format("YYYY-MM-DD")
        : "";

    // Filter by region
    const region_ = resource.region;

    const params = `station=${station_id}&emergency_type_id=${emergency_type_id}&start_date=${start_date}&end_date=${end_date}&region_type=${region_}`

    const endpoint = `/api/reports/descargar_excel?${token}&${params}`;

    window.open(endpoint, "_blank");
};

const listarReport =
    (page = 1) =>
    (dispatch, getStore) => {
        const resource = getStore()["emergency_report"];
        const params = { page };

        // filter emergency type
        const emergency_type_id = resource.emergency_type_id
            ? resource.emergency_type_id.id
            : "";
        params.emergency_type_id = emergency_type_id;

        // filter station
        const station_id = resource.station
            ? resource.station.map((station) => station.id)
            : "";
        params.station = station_id;

        // filter start date
        const start_date = resource.start_date
            ? moment(resource.start_date).format("YYYY-MM-DD")
            : "";
        params.start_date = start_date;
        // filter end date
        const end_date = resource.end_date
            ? moment(resource.end_date).format("YYYY-MM-DD")
            : "";
        params.end_date = end_date;

        if (start_date && end_date)
            if (end_date < start_date)
                return NotificationManager.error(
                    "La fecha inicial no puede ser mayor a la fecha final",
                    "ERROR",
                    0
                );

        // Filter by region
        const region_ = resource.region;
        params.region_type = region_;

        dispatch(baseReducer.actions.setLoader(true));
        api.get("reports/report_emergency", params)
            .then((response) => {
                dispatch(baseReducer.actions.setData(response));
                dispatch(baseReducer.actions.setPage(page));
            })
            .catch(() => {})
            .finally(() => {
                dispatch(baseReducer.actions.setLoader(false));
            });
    };

const setRegion = (value) => (dispatch) => {
    if (value === null) {
        value = "";
    }
    dispatch(setOptionsRegion(value));
    dispatch(listarReport());
};

const setStation = (value) => (dispatch, getStore) => {
    dispatch(setFilterByStation(value));
    dispatch(listarReport());
};

const setTypeEmergency = (value) => (dispatch, getStore) => {
    dispatch(setFilterByTypeEmergency(value));
    dispatch(listarReport());
};

const setStartDate = (value) => (dispatch, getStore) => {
    const resource = getStore()["emergency_report"];
    dispatch(setFilterByStartDate(value));
    if (resource.end_date) dispatch(listarReport());
};

const setEndDate = (value) => (dispatch, getStore) => {
    const resource = getStore()["emergency_report"];
    dispatch(setFilterByEndDate(value));
    if (resource.start_date) dispatch(listarReport());
};

export const actions = {
    listarReport,
    setRegion,
    setStation,
    setTypeEmergency,
    setStartDate,
    setEndDate,
    descargarExcel,
    ...baseReducer.actions,
};

export const reducers = {
    ...baseReducer.reducers,
    [FILTER_BY_STATION_EMERGENCY_REPORT]: (state, { station }) => {
        return {
            ...state,
            station,
        };
    },
    [FILTER_BY_REGION_EMERGENCY_REPORT]: (state, { region }) => {
        return {
            ...state,
            region,
        };
    },
    [FILTER_BY_TYPE_EMERGENCY_EMERGENCY_REPORT]: (
        state,
        { emergency_type_id }
    ) => {
        return {
            ...state,
            emergency_type_id,
        };
    },
    [FILTER_BY_START_DATE_EMERGENCY_REPORT]: (state, { start_date }) => {
        return {
            ...state,
            start_date,
        };
    },
    [FILTER_BY_END_DATE_EMERGENCY_REPORT]: (state, { end_date }) => {
        return {
            ...state,
            end_date,
        };
    },
};

export const initialState = {
    ...baseReducer.initialState,
    region: "",
    station: "",
    start_date: "",
    end_date: "",
    emergency_type_id: "",
};

export default handleActions(reducers, initialState);
