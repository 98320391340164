import React, { Component } from "react";
import { reduxForm } from "redux-form";
import Title from "../../Utils/Headers/Title";

class List extends Component {
    
    render() {

        return (
            <div className="py-4">
                <div className="row">
                    <div className="mb-4 col-lg-12">
                        <Title
                            showCreate={true}
                            title={"Permisos"}
                            linkToRedirect={"#/permissions/create"}
                            titleCreate={"Crear permiso"}
                            subtitle={"Listado general de Permisos"}
                        />
                        <div className="mb-4 card card-small">
                           <h3 className="ml-3 mt-3">Sección en proceso...</h3>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default reduxForm({
    form: "ListPermissions", // a unique identifier for this form
})(List);
